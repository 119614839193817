import React, { useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {
  compose,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { FormSelect } from '@app/layout/form/FormSelect';
import { FormTextarea } from '@app/layout/form/FormTextarea';
import { StoreContext } from '@app/store/StoreContext';

interface ContactModalProps {
  isOpen: boolean;
  onClose(): void;
}

export function ContactModal(props: ContactModalProps) {
  const { isOpen, onClose } = props;
  const { sendFeedback } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        topic: requiredValidator(values.topic),
        message: compose([
          requiredValidator(values.message),
          minLength(values.message, 2),
        ]),
      }),
    initialValues: {
      topic: '',
      message: '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await sendFeedback(value);

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();

        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const clearForm = () => {
    formik.resetForm();
    onClose();
  };

  const topics = [
    {
      title: 'Report bug / problem',
      value: 'bug',
    },
    {
      title: 'Suggest a feature',
      value: 'feature',
    },
    {
      title: 'Other feedback',
      value: 'feedback',
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contact us</ModalHeader>
        <ModalCloseButton />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <Box p="8px 0">
                <Grid width="100%" gridColumnGap="15px" gap="20px">
                  <GridItem>
                    <FormSelect
                      label="Topic*"
                      name="topic"
                      placeholder="Select topic"
                      items={topics}
                    />
                  </GridItem>
                  <GridItem>
                    <FormTextarea
                      label="Message*"
                      name="message"
                      placeholder="Message..."
                    />
                  </GridItem>
                </Grid>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button size="sm" mr="12px" onClick={clearForm}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                isLoading={isLoading}
              >
                Send
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
}
