import {
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface EditableTitleProps {
  initialValue: string;
  onSubmit?: (value: string) => void;
}

export function EditableTitle({ initialValue, onSubmit }: EditableTitleProps) {
  const [titleState, setTitleState] = useState(initialValue);

  useEffect(() => setTitleState(initialValue), [initialValue]);

  return (
    <Box fontSize={28} fontWeight={600}>
      <Editable value={titleState} onChange={setTitleState} onSubmit={onSubmit}>
        <EditablePreview />
        <EditableTextarea />
      </Editable>
    </Box>
  );
}
