import React, { useCallback, useContext, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { StoreContext } from '@app/store/StoreContext';
import { PageHeader } from '@app/layout/PageHeader';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { ProviderModal } from '@app/features/providers/modals/ProviderModal';
import { DeactivateProviderModal } from '@app/features/providers/modals/DeactivateProviderModal';
import { InviteClientModal } from '@app/features/clients/modals/InviteClientModal';
import { ClientsBlock } from '@app/features/clients/ClientsBlock';
import { ProviderSupervisor } from '@app/features/providers/details/ProviderSupervisor';
import { ProviderSubordinate } from '@app/features/providers/details/ProviderSubordinate';
import { userRole } from '@app/utils/userRole';
import { providerFullName } from '@app/utils/userFullName';

export function ProviderDetails() {
  const params = useParams();
  const {
    getUser,
    getProviderById,
    getClientsByProviderId,
    getHighRiskClientsByProviderId,
  } = useContext(StoreContext);

  const provider = getProviderById(params.id as string);

  if (!provider) {
    return <Navigate to="/providers" replace />;
  }

  const providerModal = useDisclosure();
  const deactivateProviderModal = useDisclosure();
  const inviteClientModal = useDisclosure();

  const editProvider = useCallback(() => {
    providerModal.onOpen();
  }, []);

  const deactivateProvider = useCallback(() => {
    deactivateProviderModal.onOpen();
  }, []);

  const inviteClient = useCallback(() => {
    inviteClientModal.onOpen();
  }, []);

  const currentUser = getUser();
  const clients = getClientsByProviderId(provider.id);
  const highRiskClients = getHighRiskClientsByProviderId(provider.id);
  const { email, phone } = provider.user;

  const isInvited = useMemo(
    () => provider.status === 'INVITED',
    [provider.status],
  );

  const userNameFormatted = useMemo(
    () => providerFullName(provider),
    [provider],
  );

  const userRoleFormatted = useMemo(
    () => userRole(provider.user.role),
    [provider.user.role],
  );

  const clientsBlockTitle = useMemo(() => {
    if (currentUser?.id === provider.id) {
      return 'Your clients';
    }

    return provider.firstName + '’s clients';
  }, [provider.id, provider.firstName]);

  return (
    <>
      <VStack gap="35px" alignItems="start" width="100%" p="35px 50px">
        <VStack gap="25px" alignItems="start" width="100%">
          <HStack gap="0" width="100%" alignItems="start">
            <PageHeader
              title={userNameFormatted}
              breadcrumbs={[
                {
                  url: '/providers',
                  title: 'My team',
                },
                {
                  url: '/providers/' + provider.id,
                  title: userNameFormatted,
                },
              ]}
            />

            {currentUser?.id === provider.id && (
              <Button
                size="md"
                variant="primary"
                flexShrink={0}
                leftIcon={<AddIcon />}
                onClick={inviteClient}
              >
                Invite new client
              </Button>
            )}

            {currentUser?.id !== provider.id && (
              <Menu isLazy>
                <MenuButton
                  as={Button}
                  size="sm"
                  variant="primary"
                  flexShrink={0}
                  rightIcon={<ChevronDownIcon />}
                >
                  Actions
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={editProvider}>Edit info</MenuItem>

                  {isInvited && (
                    <MenuItem onClick={editProvider}>Resend invite</MenuItem>
                  )}

                  <MenuItem onClick={inviteClient}>
                    Invite a new client
                  </MenuItem>

                  <MenuItem color="red.500" onClick={deactivateProvider}>
                    Delete {provider.firstName}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>

          <TabPanelContent>
            <Table>
              <TableContainer>
                <TableHeader>
                  <TableHeaderCell width="25%" label="Name" />
                  <TableHeaderCell width="25%" label="Role" />
                  <TableHeaderCell width="25%" label="High-risk clients" />
                  <TableHeaderCell width="25%" label="Contact info" />
                </TableHeader>

                <TableBody>
                  <TableRow alignItems="start">
                    <TableCell width="25%">
                      <Box p="6px 0">
                        <Heading size="sm">{userNameFormatted}</Heading>
                      </Box>
                    </TableCell>
                    <TableCell width="25%">
                      <VStack gap="0" alignItems="start" width="100%">
                        <Box p="16px 0">
                          <Text variant="medium" size="sm">
                            {userRoleFormatted}
                          </Text>
                        </Box>

                        {provider.subordinates.length > 0 && (
                          <Box p="16px 0">
                            <Text variant="medium" size="sm">
                              Supervises{' '}
                              {provider.subordinates.map((item, index) => (
                                <ProviderSubordinate
                                  key={index}
                                  id={item.id}
                                  isLast={
                                    index === provider.subordinates.length - 1
                                  }
                                />
                              ))}
                            </Text>
                          </Box>
                        )}

                        {provider.supervisors.length > 0 &&
                          provider.supervisors.map((item, index) => (
                            <ProviderSupervisor key={index} id={item.id} />
                          ))}
                      </VStack>
                    </TableCell>
                    <TableCell width="25%">
                      <HStack>
                        <Text
                          variant="medium"
                          size="sm"
                          color={
                            highRiskClients.length > 0 ? 'red.500' : 'gray.900'
                          }
                        >
                          {highRiskClients.length || '-'}
                        </Text>
                        {highRiskClients.length > 0 && (
                          <Icon as={FiAlertCircle} color="red.500" />
                        )}
                      </HStack>
                    </TableCell>
                    <TableCell width="25%">
                      <VStack gap="0" alignItems="start" width="100%">
                        <VStack
                          alignItems="start"
                          justifyContent="center"
                          minHeight="52px"
                        >
                          <Text
                            variant="medium"
                            size="sm"
                            wordBreak="break-word"
                          >
                            {email}
                          </Text>
                        </VStack>
                        <VStack
                          alignItems="start"
                          justifyContent="center"
                          minHeight="52px"
                        >
                          <Text
                            variant="medium"
                            size="sm"
                            wordBreak="break-word"
                          >
                            {phone}
                          </Text>
                        </VStack>
                      </VStack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>
            </Table>
          </TabPanelContent>
        </VStack>

        <ClientsBlock
          title={clientsBlockTitle}
          clients={clients}
          provider={provider}
        />
      </VStack>

      <ProviderModal
        provider={provider}
        isOpen={providerModal.isOpen}
        onClose={providerModal.onClose}
      />

      <DeactivateProviderModal
        provider={provider}
        isOpen={deactivateProviderModal.isOpen}
        onClose={deactivateProviderModal.onClose}
      />

      <InviteClientModal
        provider={provider}
        isOpen={inviteClientModal.isOpen}
        onClose={inviteClientModal.onClose}
      />
    </>
  );
}
