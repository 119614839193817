import React, { useState } from 'react';
import { Calendar, CalendarPeriod } from '@app/layout/calendar/Calendar';
import {
  Button,
  Divider,
  HStack,
  MenuItem,
  MenuList,
  VStack,
} from '@chakra-ui/react';

interface DatepickerDropdownOptionsProps {
  value?: CalendarPeriod | undefined;
  onChange: (newValue?: CalendarPeriod | undefined) => void;
  onClose: () => void;
}

export function DatepickerDropdownOptions(
  props: DatepickerDropdownOptionsProps,
) {
  const { value, onClose, onChange } = props;
  const [selectedItem, setSelectedItem] = useState(value ? 'custom' : 'all');
  const [period, setPeriod] = useState<CalendarPeriod | undefined>(
    value || undefined,
  );

  const applyValue = (newValue?: CalendarPeriod | undefined) => {
    onChange(newValue);
    onClose();
  };

  return (
    <MenuList>
      <HStack
        alignItems="start"
        gap="10px"
        height={selectedItem === 'custom' ? '312px' : 'auto'}
      >
        <VStack width={selectedItem === 'custom' ? '140px' : '100%'}>
          <MenuItem
            className={selectedItem === 'all' ? 'active' : ''}
            onClick={() => applyValue()}
          >
            All time
          </MenuItem>

          <MenuItem
            className={selectedItem === 'custom' ? 'active' : ''}
            onClick={() => setSelectedItem('custom')}
          >
            Custom
          </MenuItem>
        </VStack>

        {selectedItem === 'custom' && (
          <>
            <Divider orientation="vertical" />

            <VStack padding="6px" alignItems="start">
              <Calendar
                value={period}
                onChange={(changes) => setPeriod(changes)}
              />

              <HStack width="100%" justifyContent="end">
                <Button
                  size="xs"
                  fontSize="14px"
                  lineHeight="20px"
                  height="32px"
                  padding="0 12px"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>

                <Button
                  size="xs"
                  fontSize="14px"
                  lineHeight="20px"
                  height="32px"
                  padding="0 12px"
                  variant="primary"
                  onClick={() => applyValue(period)}
                >
                  Apply
                </Button>
              </HStack>
            </VStack>
          </>
        )}
      </HStack>
    </MenuList>
  );
}
