import { TSurveyOptions } from '@app/store/schema';

const surveyNames: Record<string, string> = {
  WORSE: 'Worse',
  SAME: 'Same',
  BETTER: 'Better',
};

const surveyStatuses: Record<string, string> = {
  WORSE: 'red',
  SAME: 'orange',
  BETTER: 'green',
};

export const surveyById = (id: string) => {
  return Object.keys(TSurveyOptions)[
    Object.values(TSurveyOptions).indexOf(id as TSurveyOptions)
  ];
};

export const surveyName = (id: string) => surveyNames[surveyById(id)];

export const surveyColor = (id: string) => surveyStatuses[surveyById(id)];
