import React from 'react';
import { useField } from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FormError } from '@app/layout/form/FormError';

export interface FormMultiSelectListItem {
  title: string;
  description?: string;
  value: string | number;
}

export function FormMultiSelectPlaceholder(props: { placeholder: string }) {
  const { placeholder } = props;

  return <Text color="gray.500">{placeholder}</Text>;
}

export function FormMultiSelectValue(props: {
  value: (string | number)[];
  items: FormMultiSelectListItem[];
}) {
  const { value, items } = props;

  const label = items
    .filter((item) => value.indexOf(item.value) !== -1)
    .map((item) => item.title)
    .join(', ');

  return <Text isTruncated>{label}</Text>;
}

export const FormMultiSelect = forwardRef((props, ref) => {
  const { label, name, placeholder, items, variant, ...rest } = props;

  const styles = useStyleConfig('FormMultiSelect', { variant });
  const [field, meta, helpers] = useField({
    name,
  });

  const isInvalid = meta.touched && !!meta.error;
  const isValue = (value: string | number) => field.value.indexOf(value) !== -1;
  const setValue = (value: string | number) => {
    let newValue = [...field.value];

    if (isValue(value)) {
      newValue = newValue.filter((item) => item !== value);
    } else {
      newValue = newValue.concat([value]);
    }

    return helpers.setValue(newValue);
  };

  const onLabelClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <FormControl ref={ref} __css={styles} {...rest} isInvalid={isInvalid}>
      {label && <FormLabel onClick={onLabelClick}>{label}</FormLabel>}
      <Menu closeOnSelect={false} isLazy>
        <MenuButton
          as={Button}
          textAlign="left"
          width="100%"
          type="button"
          variant="formSelect"
          size="sm"
          rightIcon={
            <ChevronDownIcon color="gray.800" width="20px" height="20px" />
          }
          paddingRight="10px"
          className={isInvalid ? 'invalid' : ''}
        >
          {field.value.length > 0 && (
            <FormMultiSelectValue value={field.value} items={items} />
          )}
          {!field.value.length && (
            <FormMultiSelectPlaceholder placeholder={placeholder} />
          )}
        </MenuButton>
        <MenuList rootProps={{ width: '100%', minWidth: '0 !important' }}>
          {items &&
            items.map((item: FormMultiSelectListItem, index: number) => (
              <MenuItem
                key={index}
                className={field.value === item.value ? 'active' : ''}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return setValue(item.value);
                }}
              >
                <Checkbox
                  size="lg"
                  variant="formSelect"
                  colorScheme="purple"
                  isChecked={isValue(item.value)}
                >
                  <VStack alignItems="start" gap={0}>
                    <Text>{item.title}</Text>
                    {item.description && (
                      <Text variant="medium" size="xs" color="gray.500">
                        {item.description}
                      </Text>
                    )}
                  </VStack>
                </Checkbox>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>

      {isInvalid && <FormError error={meta.error!} />}
    </FormControl>
  );
});

FormMultiSelect.displayName = 'FormMultiSelect';
