import React, { useContext, useState } from 'react';
import {
  Navigate,
  NavLink as ReactRouterLink,
  useNavigate,
} from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Box, Button, Link, Text, VStack, Heading } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { AuthForm } from './AuthForm';
import { emailValidator, requiredValidator } from '@app/utils/validators';
import { FormInput } from '@app/layout/form/FormInput';
import { FormPassword } from '@app/layout/form/FormPassword';

export function Login() {
  const navigate = useNavigate();
  const { getUser, signIn } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      if (!!emailValidator(email) || !!requiredValidator(password)) {
        return;
      }

      setIsLoading(true);

      const result = await signIn(email.trim(), password.trim());

      if (result.errors) {
        setIsLoading(false);
        setErrors(result.errors);
      } else {
        navigate('/');
      }
    },
  });

  if (getUser()) {
    return <Navigate to="/" replace />;
  }

  return (
    <AuthForm>
      <VStack spacing="30px" alignItems="start">
        <Heading size="lg" as="h1">
          Log in to your practice portal
        </Heading>

        <FormikProvider value={formik}>
          <Box as="form" onSubmit={formik.handleSubmit} width="100%">
            <VStack spacing="30px" alignItems="start">
              <VStack spacing="20px" alignItems="start" width="100%">
                <Box width="100%">
                  {errors.length > 0 && (
                    <Text
                      color="red.500"
                      paddingLeft="17px"
                      marginBottom="10px"
                      marginTop="-10px"
                    >
                      {errors.map((error) => (error as any).message).join()}
                    </Text>
                  )}

                  <FormInput
                    name="email"
                    placeholder="Email*"
                    size="lg"
                    autoFocus={true}
                  />
                </Box>

                <FormPassword
                  name="password"
                  placeholder="Password*"
                  size="lg"
                />

                <Text variant="medium">
                  <Link as={ReactRouterLink} to="/reset-password">
                    Forgot your password?
                  </Link>
                </Text>
              </VStack>

              <VStack spacing="25px" alignItems="start" width="100%">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  width="full"
                  isLoading={isLoading}
                >
                  Log in
                </Button>

                <Box>
                  <Text variant="medium">
                    By logging in, you are agreeing to our{' '}
                    <Link
                      href="https://www.silenttime.com/terms-of-service-provider"
                      target="_blank"
                    >
                      Terms Of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                      href="https://www.silenttime.com/privacy-policy-provider"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                  </Text>
                </Box>
              </VStack>
            </VStack>
          </Box>
        </FormikProvider>

        {/*<Box>*/}
        {/*  <Text variant="medium">*/}
        {/*    Don’t have an account? <Link>Sign up</Link>*/}
        {/*  </Text>*/}
        {/*</Box>*/}
      </VStack>
    </AuthForm>
  );
}
