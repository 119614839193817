import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { Datum, Point, ResponsiveLine } from '@nivo/line';
import { useDisclosure } from '@chakra-ui/react';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import { Comparator } from '@app/utils/comparator';
import { CustomPoints } from '@app/features/clients/details/widgets/components/CustomPoints';
import { chartTheme } from '@app/features/clients/details/widgets/components/chartTheme';
import { MoodPointTooltip } from '@app/features/clients/details/widgets/mood/MoodPointTooltip';
import { MoodResultsModal } from '@app/features/clients/details/widgets/mood/MoodResultsModal';

interface MoodChartWidgetProps {
  trackers: string[];
}

export function MoodChart(props: MoodChartWidgetProps) {
  const { trackers } = props;
  const { store } = useContext(WidgetsContext);
  const [currentPoints, setCurrentPoints] = useState<Point[] | undefined>(
    undefined,
  );

  const trackerResultsModal = useDisclosure();

  const groupedByTrackerItem = new Map<string, Datum[]>();

  (store.trackerResults || []).forEach((item) => {
    if (trackers.indexOf(item.trackerItem.id) === -1) {
      return;
    }

    const results = groupedByTrackerItem.get(item.trackerItem.id) || [];

    results.push({
      id: item.id,
      x: item.createdAt,
      y: item.value,
      item,
    });

    groupedByTrackerItem.set(item.trackerItem.id, results);
  });

  const showPointDetails = (points: Point[]) => {
    setCurrentPoints(points);
    trackerResultsModal.onOpen();
  };

  const chartData = Array.from(groupedByTrackerItem.entries()).map(
    ([id, data]) => {
      const trackerItem = (store.trackerItems || []).find(
        (item) => item.id === id,
      );

      return {
        id,
        color: trackerItem?.color,
        data: data.sort((a, b) =>
          Comparator.orderDates(true, a.createdAt, b.createdAt),
        ),
      };
    },
  );

  return (
    <>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 25, right: 16, bottom: 47, left: 36 }}
        xScale={{ format: '%Y-%m-%dT%H:%M:%S.%L%Z', type: 'time' }}
        axisLeft={{
          legendPosition: 'start',
          legendOffset: 0,
          tickSize: 14,
        }}
        axisBottom={{
          tickSize: 14,
          legendOffset: 0,
          format: (tick) => dayjs(tick).format('D MMM'),
        }}
        curve="linear"
        enablePointLabel={true}
        pointSize={8}
        pointColor={{ from: 'color' }}
        gridXValues={[]}
        gridYValues={5}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'slices',
          (props) => (
            <CustomPoints
              layer={props}
              onClick={(points) => showPointDetails(points)}
              tooltip={({ points }) => <MoodPointTooltip points={points} />}
            />
          ),
          'legends',
        ]}
        colors={(item) => item.color}
        useMesh={true}
        enableSlices="x"
        sliceTooltip={({ slice }) => <MoodPointTooltip points={slice.points} />}
        isInteractive={true}
        theme={chartTheme}
      />

      <MoodResultsModal
        points={currentPoints}
        isOpen={trackerResultsModal.isOpen}
        onClose={trackerResultsModal.onClose}
      />
    </>
  );
}
