import React, { useState } from 'react';
import dayjs from 'dayjs';
import { HStack } from '@chakra-ui/react';
import { CalendarMonth } from '@app/layout/calendar/CalendarMonth';
import {
  getCurrentMonth,
  getMonth,
  getNextMonth,
  getPreviousMonth,
} from '@app/utils/date';

export interface CalendarPeriod {
  from: string | dayjs.Dayjs;
  to: string | dayjs.Dayjs;
}

interface CalendarProps {
  value?: CalendarPeriod;
  onChange?: (value: CalendarPeriod) => void;
}

export function Calendar(props: CalendarProps) {
  const { value, onChange } = props;

  const [period, setPeriod] = useState(
    value
      ? {
          from: getPreviousMonth(getMonth(value.to)),
          to: getMonth(value.to),
        }
      : {
          from: getPreviousMonth(dayjs()),
          to: getCurrentMonth(),
        },
  );

  const setPrevious = () => {
    const from = getPreviousMonth(period.from);
    const to = getPreviousMonth(period.to);

    setPeriod({
      from,
      to,
    });
  };

  const setNext = () => {
    const from = getNextMonth(period.from);
    const to = getNextMonth(period.to);

    setPeriod({
      from,
      to,
    });
  };

  return (
    <HStack
      gap="24px"
      alignItems="start"
      backgroundColor="white"
      userSelect="none"
    >
      <CalendarMonth
        value={value}
        month={period.from}
        onPrevious={setPrevious}
        onChange={onChange}
      />
      <CalendarMonth
        value={value}
        month={period.to}
        onNext={setNext}
        onChange={onChange}
      />
    </HStack>
  );
}
