import React, { useCallback, useContext, useMemo } from 'react';
import {
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { StoreContext } from '@app/store/StoreContext';
import { PageHeader } from '@app/layout/PageHeader';
import { ProvidersActiveList } from '@app/features/providers/ProvidersActiveList';
import { ProvidersActivePlaceholder } from '@app/features/providers/ProvidersActivePlaceholder';
import { ProvidersPendingList } from '@app/features/providers/ProvidersPendingList';
import { ProvidersPendingPlaceholder } from '@app/features/providers/ProvidersPendingPlaceholder';
import { ProviderModal } from '@app/features/providers/modals/ProviderModal';

export function Providers() {
  const { getProviders } = useContext(StoreContext);

  const providerModal = useDisclosure();

  const inviteProvider = useCallback(() => {
    providerModal.onOpen();
  }, []);

  const providers = getProviders();

  const activeList = useMemo(() => {
    return providers.filter((provider) => provider.status === 'ACTIVE');
  }, [providers]);

  const invitedList = useMemo(() => {
    return providers.filter((provider) => provider.status === 'INVITED');
  }, [providers]);

  return (
    <>
      <VStack gap="40px" alignItems="start" width="100%" p="35px 50px">
        <HStack gap="0" width="100%" alignItems="start">
          <PageHeader
            title="My team"
            breadcrumbs={[
              {
                url: '/providers',
                title: 'My team',
              },
            ]}
          />

          <Button
            size="md"
            variant="primary"
            flexShrink={0}
            leftIcon={<AddIcon />}
            onClick={inviteProvider}
          >
            Invite team member
          </Button>
        </HStack>

        <Tabs variant="pill">
          <TabList>
            <Tab aria-label={'Active (' + activeList.length + ')'}>
              Active ({activeList.length})
            </Tab>
            <Tab aria-label={'Pending (' + invitedList.length + ')'}>
              Pending ({invitedList.length})
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {activeList.length > 0 && (
                <ProvidersActiveList providers={activeList} />
              )}
              {!activeList.length && <ProvidersActivePlaceholder />}
            </TabPanel>
            <TabPanel>
              {invitedList.length > 0 && (
                <ProvidersPendingList providers={invitedList} />
              )}
              {!invitedList.length && <ProvidersPendingPlaceholder />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>

      <ProviderModal
        isOpen={providerModal.isOpen}
        onClose={providerModal.onClose}
      />
    </>
  );
}
