import React, { useEffect } from 'react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Box } from '@chakra-ui/react';

interface DropFilesProps {
  children: React.ReactNode;
  validate: (files: File[]) => boolean;
  onDrop: (files: File[]) => void;
  onOver: (value: boolean) => void;
}

export function DropFiles(props: DropFilesProps) {
  const { children, onOver } = props;

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (props.onDrop) {
          props.onDrop(item.files);
        }
      },
      canDrop(item: any) {
        if (!props.validate) {
          return true;
        }

        return props.validate(item.files);
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [props],
  );

  useEffect(() => {
    onOver(isOver);
  }, [isOver]);

  return <Box ref={drop}>{children}</Box>;
}
