import React from 'react';
import dayjs from 'dayjs';
import { Datum, Point } from '@nivo/line';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { useSort } from '@app/layout/sort/useSort';
import { Comparator } from '@app/utils/comparator';
import {
  IExtendedTrackerResultInput,
  ITrackerItem,
  ITrackerTrigger,
} from '@app/store/schema';

interface MoodLine {
  createdAt: string;
  value: number;
  trackerItem: ITrackerItem;
  trackerTrigger: ITrackerTrigger;
}

interface MoodResultsModalProps {
  points?: Point[];
  isOpen: boolean;
  onClose(): void;
}

export function MoodResultsModal(props: MoodResultsModalProps) {
  const { points, isOpen, onClose } = props;
  const { sortValue, applySort } = useSort({
    column: 'createdAt',
    order: 'asc',
  });

  if (!points) {
    return <></>;
  }

  const lines: MoodLine[] = [];

  points.forEach((point: Point) => {
    const item = (point.data as Datum).item as IExtendedTrackerResultInput;

    item.trackerTriggers.forEach((trackerTrigger) => {
      const { trackerItem } = item;

      lines.push({
        createdAt: item.createdAt,
        value: item.value,
        trackerItem: trackerItem,
        trackerTrigger,
      });
    });
  });

  const sortedTable = lines.sort((a: MoodLine, b: MoodLine) => {
    const isAsc = sortValue.order === 'asc';

    if (sortValue.column === 'name') {
      return Comparator.orderWithDirection(
        isAsc,
        a.trackerItem.label.toLowerCase(),
        b.trackerItem.label.toLowerCase(),
      );
    }

    if (sortValue.column === 'factors') {
      return Comparator.orderWithDirection(
        isAsc,
        a.trackerTrigger.text.toLowerCase(),
        b.trackerTrigger.text.toLowerCase(),
      );
    }

    if (sortValue.column === 'severity') {
      return Comparator.orderWithDirection(isAsc, a.value, b.value);
    }

    if (sortValue.column === 'createdAt') {
      return Comparator.orderDates(isAsc, a.createdAt, b.createdAt);
    }

    return 0;
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxHeight="80%" overflow="hidden" maxWidth="1041px">
        <ModalHeader>Mood</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom="20px" overflow="auto">
          <Table
            width="calc(100% + 48px)"
            maxWidth="calc(100% + 48px)"
            marginLeft="-24px"
          >
            <TableContainer minWidth="870px">
              <TableHeader pb="25px">
                <TableHeaderCell
                  label="Mood"
                  width="20%"
                  sortColumn="name"
                  sortValue={sortValue}
                  onSort={applySort}
                  flexShrink={0}
                  variant="simple"
                />
                <TableHeaderCell
                  label="Factors"
                  width="20%"
                  sortColumn="factors"
                  sortValue={sortValue}
                  onSort={applySort}
                  flexShrink={0}
                  variant="simple"
                />
                <TableHeaderCell
                  label="Severity"
                  width="10%"
                  sortColumn="severity"
                  sortValue={sortValue}
                  onSort={applySort}
                  flexShrink={0}
                  variant="simple"
                />
                <TableHeaderCell
                  label="Date submitted"
                  width="100%"
                  sortColumn="createdAt"
                  sortValue={sortValue}
                  onSort={applySort}
                  variant="simple"
                />
              </TableHeader>
              <TableBody gap="25px">
                {sortedTable.map((line, index) => (
                  <TableRow key={index}>
                    <TableCell width="20%" flexShrink={0} variant="simple">
                      <Text variant="medium">{line.trackerItem.label}</Text>
                    </TableCell>
                    <TableCell width="20%" flexShrink={0} variant="simple">
                      <Text variant="medium">{line.trackerTrigger.text}</Text>
                    </TableCell>
                    <TableCell width="10%" flexShrink={0} variant="simple">
                      <Text variant="medium">
                        {line.value}/{line.trackerItem.max}
                      </Text>
                    </TableCell>
                    <TableCell width="100%" variant="simple">
                      <Text variant="medium">
                        {dayjs(line.createdAt).format('MMM D, YYYY, h:mm A')}
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
