import React, { createContext, useState } from 'react';
import {
  IAssessment,
  IClient,
  IClientAssessmentResults,
  IExercise,
  IExtendedTrackerResultInput,
  ITracker,
  ITrackerItem,
  ITrackerResult,
} from '@app/store/schema';
import { graphQLClient } from '@app/store/client';
import { ClientAssessmentsQuery } from '@app/store/queries/widgets/clientAssessments';
import { ClientTrackersQuery } from '@app/store/queries/widgets/clientTrackers';
import { AssessmentResultsQuery } from '@app/store/queries/widgets/assessmentResults';
import { ExercisesQuery } from '@app/store/queries/widgets/exercises';
import { TrackerQueryQuery } from '@app/store/queries/widgets/trackerResult';
import { mapToArray } from '@app/store/StoreContext';
import { CalendarPeriod } from '@app/layout/calendar/Calendar';

interface Store {
  initiated: boolean;
  loading: boolean;
  client?: IClient;
  period?: CalendarPeriod;
  assessments?: IAssessment[];
  trackers?: ITracker[];
  trackerItems?: ITrackerItem[];
  assessmentResults?: Map<string, IClientAssessmentResults>;
  trackerResults?: IExtendedTrackerResultInput[];
  exercises?: IExercise[];
}

interface AssessmentsData {
  clientAssessments: IAssessment[];
}

interface TrackersData {
  clientTrackers: ITracker[];
}

interface TrackerResultData {
  trackerResults: ITrackerResult[];
}

interface AssessmentResultData {
  assessmentResults: IClientAssessmentResults;
}

interface ExercisesData {
  exercises: IExercise[];
}

interface WidgetsProviderProps {
  children: React.ReactNode;
}

interface WidgetsContextValue {
  store: Store;
  loadData: (client: IClient) => void;
  loadDataByPeriod: (period?: CalendarPeriod) => void;
  setPeriod: (period?: CalendarPeriod) => void;
}

export const WidgetsContext = createContext<WidgetsContextValue>({
  store: {
    initiated: false,
    loading: true,
  },
  loadData: () => {},
  loadDataByPeriod: () => {},
  setPeriod: () => {},
});

export const WidgetsProvider = ({ children }: WidgetsProviderProps) => {
  const [store, setStore] = useState<Store>({
    initiated: false,
    loading: true,
  });

  const { client, assessments, trackers } = store;

  const loadData = async (client: IClient) => {
    const assessments = await loadAssessments(client);
    const trackers = await loadTrackers(client);
    const trackerItems = getTrackerItems(trackers);

    setStore({
      initiated: true,
      loading: true,
      client,
      assessments,
      trackers,
      trackerItems,
    });
  };

  const loadAssessments = async (client: IClient) => {
    const { data } = await graphQLClient.rawRequest<AssessmentsData>(
      ClientAssessmentsQuery,
      {
        clientId: client.id,
      },
    );

    return data.clientAssessments || [];
  };

  const loadTrackers = async (client: IClient) => {
    const { data } = await graphQLClient.rawRequest<TrackersData>(
      ClientTrackersQuery,
      {
        clientId: client.id,
      },
    );

    return data.clientTrackers || [];
  };

  const getTrackerItems = (items: ITracker[]) => {
    const colors = [
      '#9F7AEA', // 'purple.400',
      '#D6BCFA', // 'purple.200',
      '#44337A', // 'purple.800',
      '#4299E1', // 'blue.400',
      '#3182CE', // 'blue.500',
      '#2A4365', // 'blue.800',
      '#F687B3', // 'pink.300',
      '#ED64A6', // 'pink.400',
      '#702459', // 'pink.800',
      '#00B5D8', // 'cyan.500',
      '#0987A0', // 'cyan.700',
      '#065666', // 'cyan.900',
    ];

    const results = new Map<string, ITrackerItem>();
    let index = 0;

    items.forEach((item) => {
      item.items.forEach((trackerItem) => {
        results.set(trackerItem.id, {
          ...trackerItem,
          color: colors[index],
        });

        index = index === colors.length - 1 ? 0 : index + 1;
      });
    });

    return mapToArray(results);
  };

  const loadDataByPeriod = async (period?: CalendarPeriod) => {
    setStore({
      ...store,
      loading: true,
    });

    const assessmentResults = await loadAssessmentResults(period);
    const trackerResults = await loadTrackerResults(period);
    const exercises = await loadExercises(period);

    setStore({
      ...store,
      loading: false,
      assessmentResults,
      trackerResults,
      exercises,
      period,
    });
  };

  const loadAssessmentResults = async (period?: CalendarPeriod) => {
    const results = new Map<string, IClientAssessmentResults>();

    await Promise.all(
      (assessments || []).map(async (assessment) => {
        const data = await loadAssessmentResult(assessment.id, period);

        results.set(assessment.id, data);
      }),
    );

    return results;
  };

  const loadAssessmentResult = async (
    assessmentId: string,
    period?: CalendarPeriod,
  ) => {
    const { data } = await graphQLClient.rawRequest<AssessmentResultData>(
      AssessmentResultsQuery,
      {
        clientId: client?.id,
        assessmentId,
        fromDate: period?.from || null,
        toDate: period?.to || null,
      },
    );

    return data.assessmentResults || [];
  };

  const loadTrackerResults = async (period?: CalendarPeriod) => {
    const results = new Map<string, ITrackerResult[]>();

    await Promise.all(
      (trackers || []).map(async (tracker) => {
        const data = await loadTrackerResult(period);

        results.set(tracker.id, data);
      }),
    );

    return mapToArray(results).reduce((result, items) => {
      items.forEach((item: ITrackerResult) => {
        item.trackerResultInputs.forEach((trackerResultInput) => {
          result.push({
            ...trackerResultInput,
            createdAt: item.createdAt,
          });
        });
      });

      return result;
    }, [] as any[]);
  };

  const loadTrackerResult = async (period?: CalendarPeriod) => {
    // @fixme: use flowId from tracker
    const { data } = await graphQLClient.rawRequest<TrackerResultData>(
      TrackerQueryQuery,
      {
        clientId: client?.id,
        flowId: 'f3895902-09a8-4798-9a07-70cb3532931a',
        fromDate: period?.from || null,
        toDate: period?.to || null,
      },
    );

    return data.trackerResults || [];
  };

  const loadExercises = async (period?: CalendarPeriod) => {
    const { data } = await graphQLClient.rawRequest<ExercisesData>(
      ExercisesQuery,
      {
        clientId: client?.id,
        fromDate: period?.from || null,
        toDate: period?.to || null,
      },
    );

    return data.exercises || [];
  };

  const setPeriod = (value?: CalendarPeriod) => {
    setStore({
      ...store,
      period: value,
    });
  };

  const value = {
    store,
    loadData,
    loadDataByPeriod,
    setPeriod,
  };

  return (
    <WidgetsContext.Provider value={value}>{children}</WidgetsContext.Provider>
  );
};
