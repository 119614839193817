import React, { useContext, useMemo } from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { Box, Link } from '@chakra-ui/react';
import { providerFullName } from '@app/utils/userFullName';
import { StoreContext } from '@app/store/StoreContext';

interface ProviderSubordinateProps {
  id: string;
  isLast: boolean;
}

export function ProviderSubordinateContent(props: ProviderSubordinateProps) {
  const { id, isLast } = props;
  const { getProviderById } = useContext(StoreContext);

  const subordinate = useMemo(() => getProviderById(id), [id]);

  const userNameFormatted = useMemo(
    () => providerFullName(subordinate!),
    [subordinate],
  );

  return (
    <Box as="span">
      <Link
        as={ReactRouterLink}
        to={'/providers/' + subordinate?.id}
        variant="textUnderline"
      >
        {userNameFormatted}
      </Link>
      {!isLast ? ', ' : ''}
    </Box>
  );
}

export const ProviderSubordinate = React.memo(ProviderSubordinateContent);
