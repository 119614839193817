import { gql } from 'graphql-request';

export const SignInMutation = gql`
  mutation ($email: String!, $password: String!) {
    signIn(signInInput: { email: $email, password: $password }) {
      provider {
        id
        firstName
        lastName
        status
        credentials
        user {
          id
          email
          phone
          role
          activeAt
        }
      }
    }
  }
`;

export const SignOutMutation = gql`
  mutation {
    signOut
  }
`;
