import validator from 'validator';
import parsePhoneNumber from 'libphonenumber-js';

export type ValidatorInput = string | number | boolean;
export type ValidatorError = string | null;

export interface ValidatorFn {
  (value: ValidatorInput, options?: any): ValidatorError;
}

export const getErrors = (
  values: { [key: string]: ValidatorInput },
  validators: { [key: string]: ValidatorError },
) => {
  const errors: { [key: string]: ValidatorError } = {};

  Object.keys(values).forEach((key) => {
    if (!!validators[key]) {
      errors[key] = validators[key];
    }
  });

  return errors;
};

export const compose = (errors: ValidatorError[]) => {
  return errors.find((error) => error !== null) || null;
};

export const requiredValidator: ValidatorFn = (value) => {
  if (validator.isEmpty(value.toString().trim())) {
    return 'This field is required';
  }

  return null;
};

export const emailValidator: ValidatorFn = (value) => {
  if (!validator.isEmail(value.toString().trim())) {
    return 'This doesn’t look like an email address';
  }

  return null;
};

export const phoneValidator: ValidatorFn = (value) => {
  const phoneNumber = parsePhoneNumber(value.toString(), 'US');

  if (!phoneNumber || !phoneNumber.isValid() || phoneNumber.country !== 'US') {
    return 'This doesn’t look like a phone number';
  }

  return null;
};

export const minLength: ValidatorFn = (value, minValue: number) => {
  if (!validator.isLength(value.toString().trim(), { min: minValue })) {
    return minValue + ' min';
  }

  return null;
};
