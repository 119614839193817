import React from 'react';
import { BoxProps, VStack } from '@chakra-ui/react';

export function TableBody(props: BoxProps) {
  return (
    <VStack gap={0} width="100%" {...props} role="rowgroup" alignItems="start">
      {props.children}
    </VStack>
  );
}
