import React from 'react';
import dayjs from 'dayjs';
import { Datum, Point } from '@nivo/line';
import { MdCircle } from 'react-icons/md';
import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ScoreMovement } from '@app/layout/widget/ScoreMovement';
import { IExtendedTrackerResultInput } from '@app/store/schema';

interface MoodPointTooltipProps {
  points: readonly Point[];
}

export const MoodPointTooltip = (props: MoodPointTooltipProps) => {
  const { points } = props;

  return (
    <Card width="398px">
      <CardBody padding="10px">
        <VStack gap={0} alignItems="start">
          {points &&
            points.map((point, index) => {
              const item = (point.data as Datum)
                .item as IExtendedTrackerResultInput;
              const { scoreMovement, trackerItem, createdAt } = item;

              return (
                <Box width="100%" key={point.id}>
                  <Flex
                    alignItems="center"
                    width="100%"
                    padding="6px 12px"
                    gap="10px"
                  >
                    <Icon
                      as={MdCircle}
                      color={point.color}
                      boxSize="24px"
                      width="24px"
                      height="24px"
                    />

                    <Text size="sm">
                      <Text variant="semibold" as="span">
                        {trackerItem.label}
                        {` • `}
                      </Text>
                      <Text as="span">Severity{` `}</Text>
                      <Text variant="semibold" as="span">
                        ({item.value} of {trackerItem.max})
                      </Text>
                    </Text>
                  </Flex>
                  <Flex alignItems="start" width="100%">
                    {scoreMovement &&
                      scoreMovement.length > 0 &&
                      scoreMovement.map((item, index) => (
                        <ScoreMovement
                          scoreMovement={item}
                          key={index}
                          padding="6px 12px"
                          width="auto"
                          size="xs"
                        />
                      ))}
                  </Flex>
                  <Text padding="6px 12px" size="sm">
                    Triggers:{' '}
                    <Text variant="semibold" as="span">
                      {item.trackerTriggers
                        .map((trackerTrigger) => trackerTrigger.text)
                        .join(', ')}
                    </Text>
                  </Text>

                  <Divider m="5px 0" />

                  {index === points.length - 1 && (
                    <Text
                      variant="medium"
                      size="sm"
                      color="gray.500"
                      padding="6px 12px"
                    >
                      {dayjs(createdAt).format('MMM D, YYYY, h:mm A')}
                    </Text>
                  )}
                </Box>
              );
            })}
        </VStack>
      </CardBody>
    </Card>
  );
};
