import React, { useState } from 'react';
import { CustomLayerProps, DatumValue, Point } from '@nivo/line';
import { useTooltip } from '@nivo/tooltip';

interface CustomPointProps {
  point: Point;
  index: number;
  layer: CustomLayerProps;
  onClick?: (points: Point[]) => void;
  tooltip?: React.FunctionComponent<{ points: Point[] }>;
}

interface Slice {
  id: DatumValue;
  height: number;
  width: number;
  x0: number;
  x: number;
  y0: number;
  y: number;
  points: Point[];
}

export function CustomPoint(props: CustomPointProps) {
  const { point, index, tooltip, layer, onClick } = props;
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const [isHover, setHover] = useState(false);

  const { data, x, y } = point;
  const { currentSlice, slices } = layer as any;
  const sliceByPoint = slices.find((slice: Slice) => slice.x === point.x);
  const isCurrentSlicePoint = currentSlice && currentSlice.x === sliceByPoint.x;

  const showTooltip = (event: React.MouseEvent) => {
    if (!tooltip) {
      return;
    }

    showTooltipFromEvent(
      tooltip({
        points: sliceByPoint.points,
      })! as React.ReactElement,
      event,
      index === 0 ? 'right' : 'left',
    );
  };

  return (
    <>
      <g
        style={{ cursor: 'pointer' }}
        transform={'translate(' + x + ', ' + y + ')'}
        onClick={() => (onClick ? onClick(sliceByPoint.points) : null)}
        onMouseEnter={(event) => {
          setHover(true);
          showTooltip(event);
        }}
        onMouseMove={(event) => showTooltip(event)}
        onMouseLeave={() => {
          setHover(false);
          hideTooltip();
        }}
      >
        {isHover && (
          <line
            x1={0}
            x2={0}
            y1={-y}
            y2={layer.innerHeight - y}
            style={{
              strokeWidth: 1,
              stroke: '#000000',
              strokeDasharray: '6 6',
              strokeOpacity: '0.75',
            }}
          ></line>
        )}
        <circle
          r={isHover || isCurrentSlicePoint ? 7 : 4}
          style={{
            fill: point.serieColor,
            stroke: 'transparent',
            strokeWidth: 0,
          }}
        ></circle>
        <rect
          width="22"
          height="16"
          x="-11"
          y="-24"
          rx="2"
          style={{
            fill: point.serieColor,
          }}
        />
        <text
          y="-12"
          textAnchor="middle"
          style={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            fill: '#FFFFFF',
            outlineWidth: 0,
            outlineColor: 'transparent',
          }}
        >
          {data.y.toString()}
        </text>
      </g>
    </>
  );
}
