import dayjs from 'dayjs';

export class Comparator {
  static order(a: string | number, b: string | number, fallback?: any): number {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }

    return fallback ? fallback() : 0;
  }

  static orderWithDirection(
    isAsc: boolean,
    a: string | number,
    b: string | number,
  ): number {
    return (isAsc ? 1 : -1) * Comparator.order(a, b);
  }

  static orderDates(
    isAsc: boolean,
    a: string | dayjs.Dayjs | null,
    b: string | dayjs.Dayjs | null,
  ): number {
    if (a && b) {
      return (isAsc ? 1 : -1) * (dayjs(a).isAfter(dayjs(b)) ? 1 : -1);
    }

    if (!a && b) {
      return isAsc ? -1 : 1;
    }

    if (a && !b) {
      return isAsc ? 1 : -1;
    }

    return 0;
  }
}
