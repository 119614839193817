export const chartTheme = {
  axis: {
    ticks: {
      line: {
        stroke: '#FFFFFF',
      },
      text: {
        fill: '#718096',
        fontSize: '12px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '16px',
      },
    },
  },
  grid: {
    line: {
      stroke: '#000000',
      opacity: 0.1,
    },
  },
};
