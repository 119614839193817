import React from 'react';
import {
  defineStyleConfig,
  forwardRef,
  HStack,
  useStyleConfig,
} from '@chakra-ui/react';

export const tableHeaderTheme = defineStyleConfig({
  baseStyle: {
    width: '100%',
  },
});

export const TableHeader = forwardRef((props, ref) => {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('TableHeader', { variant });

  return (
    <HStack gap={0} ref={ref} __css={styles} {...rest} role="row">
      {props.children}
    </HStack>
  );
});

TableHeader.displayName = 'TableHeader';
