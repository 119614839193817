import React, { useContext, useMemo } from 'react';
import { StoreContext } from '@app/store/StoreContext';
import { IProvider } from '@app/store/schema';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { ProvidersActiveListItem } from '@app/features/providers/ProvidersActiveListItem';
import { useSort } from '@app/layout/sort/useSort';
import { providerFullName } from '@app/utils/userFullName';
import { Comparator } from '@app/utils/comparator';

export interface ProvidersActiveListProps {
  providers: IProvider[];
}

type IProviderOptional = IProvider & { highRiskClients: number };

export function ProvidersActiveListContent(props: ProvidersActiveListProps) {
  const { providers } = props;
  const { getHighRiskClientsByProviderId } = useContext(StoreContext);

  const { sortValue, applySort } = useSort({
    column: 'highRiskClients',
    order: 'desc',
  });

  const extendedProviders = useMemo(() => {
    return providers.map((provider) => {
      const highRiskClients = getHighRiskClientsByProviderId(provider.id);

      return {
        ...provider,
        highRiskClients: highRiskClients.length,
      };
    });
  }, [providers]);

  const sortedTable = useMemo(() => {
    return extendedProviders.sort(
      (a: IProviderOptional, b: IProviderOptional) => {
        const isAsc = sortValue.order === 'asc';

        if (sortValue.column === 'name') {
          return Comparator.orderWithDirection(
            isAsc,
            providerFullName(a).toLowerCase(),
            providerFullName(b).toLowerCase(),
          );
        }

        if (sortValue.column === 'role') {
          return Comparator.orderWithDirection(isAsc, a.user.role, b.user.role);
        }

        if (sortValue.column === 'highRiskClients') {
          return Comparator.orderWithDirection(
            isAsc,
            a.highRiskClients,
            b.highRiskClients,
          );
        }

        return 0;
      },
    );
  }, [extendedProviders, sortValue]);

  return (
    <TabPanelContent>
      <Table>
        <TableContainer minWidth="870px">
          <TableHeader>
            <TableHeaderCell
              label="Team member"
              width="100%"
              sortColumn="name"
              sortValue={sortValue}
              onSort={applySort}
            />
            <TableHeaderCell
              label="Role"
              width="20%"
              sortColumn="role"
              sortValue={sortValue}
              onSort={applySort}
              flexShrink={0}
            />
            <TableHeaderCell
              label="High-risk clients"
              width="20%"
              sortColumn="highRiskClients"
              sortValue={sortValue}
              onSort={applySort}
              flexShrink={0}
            />
            <TableHeaderCell width="24px" flexShrink={0} />
          </TableHeader>

          <TableBody>
            {sortedTable &&
              sortedTable.map((provider) => (
                <ProvidersActiveListItem
                  key={provider.id}
                  provider={provider}
                  highRiskClients={provider.highRiskClients}
                />
              ))}
          </TableBody>
        </TableContainer>
      </Table>
    </TabPanelContent>
  );
}

export const ProvidersActiveList = React.memo(ProvidersActiveListContent);
