import { gql } from '../../../../__generated__/gql';

export const ScreenFragment = gql(/* GraphQL */ `
  fragment ScreenFragment on Screen {
    __typename
    id
    # -- screen data
    screenType
    version
    order
    #  -- flow data
    flowId
    flowType
    # -- data
    title
    subtitle
    inputs {
      ...InputInterfaceFragment
    }
    cta {
      ...InputFragment
      ...ButtonFragment
    }
    navigationBar {
      leftItem {
        ...InputFragment
        ...ButtonFragment
      }
      header
      rightItem {
        ...InputFragment
        ...ButtonFragment
      }
    }

    # ... on HomeScreen {
    #   sections {
    #     __typename
    #     title
    #     cards {
    #       __typename
    #       id
    #       title
    #       subtitle
    #       badge {
    #         ...BadgeFragment
    #       }
    #       asset {
    #         ...AssetFragment
    #       }
    #       button {
    #         ...InputFragment
    #         ...ButtonFragment
    #       }
    #     }
    #   }
    # }

    # ... on JournalingScreen {
    #   __typename
    # }

    # ... on CongratulationsScreen {
    #   asset {
    #     ...AssetFragment
    #   }
    # }

    # ... on SurveyScreen {
    #   __typename
    # }

    # ... on RangeTrackerScreen {
    #   rangeSections {
    #     title
    #     subtitle
    #     range {
    #       ...InputFragment
    #       ...RangeFragment
    #     }
    #   }
    # }
  }
`);
