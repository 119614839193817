import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export function Table(props: BoxProps) {
  return (
    <Box
      overflowY="hidden"
      overflowX="auto"
      maxWidth="100%"
      width="100%"
      role="table"
      {...props}
    >
      {props.children}
    </Box>
  );
}
