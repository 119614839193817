import { IClient, IProvider } from '@app/store/schema';

export const userFullName = (user: IClient | IProvider): string => {
  if (!user) {
    return '';
  }

  return `${user?.firstName} ${user?.lastName}`;
};

export const providerFullName = (provider: IProvider): string => {
  let name = userFullName(provider);

  if (provider && provider?.credentials && provider?.credentials.length > 0) {
    name = `${name}, ${provider?.credentials}`;
  }

  return name;
};
