import React from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  forwardRef,
  Textarea,
  useStyleConfig,
} from '@chakra-ui/react';
import { FormError } from '@app/layout/form/FormError';

export const FormTextarea = forwardRef((props, ref) => {
  const {
    label,
    name,
    placeholder,
    autoFocus,
    disabled,
    variant,
    size,
    ...rest
  } = props;

  const styles = useStyleConfig('FormTextarea', { variant });
  const [field, meta] = useField({
    name,
  });
  const isInvalid = meta.touched && !!meta.error;

  return (
    <FormControl
      id={name}
      ref={ref}
      __css={styles}
      {...rest}
      isInvalid={isInvalid}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea
        id={name}
        name={name}
        placeholder={placeholder || ''}
        onChange={field.onChange}
        value={field.value}
        autoFocus={autoFocus}
        size={size}
        disabled={disabled}
      />

      {isInvalid && <FormError error={meta.error!} />}
    </FormControl>
  );
});

FormTextarea.displayName = 'FormTextarea';
