import React from 'react';
import {
  defineStyleConfig,
  forwardRef,
  useStyleConfig,
  VStack,
} from '@chakra-ui/react';

export const widgetTheme = defineStyleConfig({
  baseStyle: {
    background: 'white',
    borderRadius: '12px',
    padding: '20px 24px',
  },
});

export const Widget = forwardRef((props, ref) => {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('Widget', { variant });

  return (
    <VStack alignItems="start" gap="25px" ref={ref} __css={styles} {...rest}>
      {props.children}
    </VStack>
  );
});

Widget.displayName = 'Widget';
