import React from 'react';
import { Heading, VStack } from '@chakra-ui/react';
import { Breadcrumbs } from '@app/layout/breadcrumbs/Breadcrumbs';

interface Breadcrumb {
  url: string;
  title: string;
}

interface PageHeaderProps {
  title: string;
  breadcrumbs?: Breadcrumb[];
}

export function PageHeader(props: PageHeaderProps) {
  const { title, breadcrumbs } = props;

  return (
    <VStack gap="10px" alignItems="start" width="100%">
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

      <Heading size="lg" as="h1">
        {title}
      </Heading>
    </VStack>
  );
}
