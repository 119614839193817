import React, { useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Button,
  Grid,
  GridItem,
  Heading,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { FormInput } from '@app/layout/form/FormInput';
import {
  compose,
  emailValidator,
  getErrors,
  minLength,
  phoneValidator,
  requiredValidator,
} from '@app/utils/validators';

export function SettingsPersonal() {
  const { getUser, updateProvider } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const user = getUser()!;
  const toast = useToast();

  const formik = useFormik({
    validate: (values: any) =>
      getErrors(values, {
        firstName: compose([
          requiredValidator(values.firstName),
          minLength(values.firstName, 2),
        ]),
        lastName: compose([
          requiredValidator(values.lastName),
          minLength(values.lastName, 2),
        ]),
        credentials: requiredValidator(values.credentials),
        email: emailValidator(values.email),
        phone: phoneValidator(values.phone),
        role: requiredValidator(values.role),
      }),
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      credentials: user?.credentials || '',
      email: user?.user.email || '',
      phone: user?.user.phone || '',
      role: user?.user.role || '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await updateProvider(user.id, {
        firstName: value.firstName,
        lastName: value.lastName,
        credentials: value.credentials,
        email: value.email,
        phone: value.phone,
        role: value.role,
      });

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <VStack gap="25px" alignItems="start" p="0 30px">
          <Heading size="md">Personal info</Heading>

          <Grid width="100%" gridColumnGap="15px" gap="25px">
            <GridItem>
              <FormInput
                label="Name"
                name="firstName"
                placeholder="First name"
                autoFocus={true}
              />
            </GridItem>
            <GridItem>
              <FormInput
                label="&nbsp;"
                name="lastName"
                placeholder="Last name"
              />
            </GridItem>
            <GridItem colSpan={2}>
              <FormInput
                label="Credentials"
                name="credentials"
                placeholder="Credentials"
              />
            </GridItem>
            <GridItem>
              <FormInput
                label="Contact info*"
                name="email"
                placeholder="Email"
              />
            </GridItem>
            <GridItem>
              <FormInput
                label="&nbsp;"
                name="phone"
                placeholder="Phone number"
              />
            </GridItem>
          </Grid>

          <Button
            type="submit"
            variant="primary"
            size="md"
            isLoading={isLoading}
          >
            Save changes
          </Button>
        </VStack>
      </form>
    </FormikProvider>
  );
}
