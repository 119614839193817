import React, { useContext, useState } from 'react';
import { Navigate, NavLink as ReactRouterLink } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, Text, VStack, Heading, Box, Link } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { AuthForm } from './AuthForm';
import { emailValidator, getErrors } from '@app/utils/validators';
import { FormInput } from '@app/layout/form/FormInput';

export function ResetPassword() {
  const { getUser, initiateResetPassword } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isSent, setIsSent] = useState(false);

  const resend = async (email: string) => {
    setIsLoading(true);

    const result = await initiateResetPassword({
      email,
    });

    setIsLoading(false);

    if (result.errors) {
      setErrors(result.errors as any);
    } else {
      setIsSent(true);
    }
  };

  const formik = useFormik({
    validate: (values: any) =>
      getErrors(values, {
        email: emailValidator(values.email),
      }),
    initialValues: {
      email: '',
    },
    onSubmit: async (value) => {
      await resend(value.email);
    },
  });

  if (getUser()) {
    return <Navigate to="/" replace />;
  }

  return (
    <AuthForm>
      <FormikProvider value={formik}>
        <Box as="form" onSubmit={formik.handleSubmit} width="100%">
          <VStack spacing="30px" alignItems="start">
            <VStack spacing="30px" alignItems="start">
              {!isSent && (
                <>
                  <VStack spacing="20px" alignItems="start">
                    <Heading size="lg" as="h1">
                      Reset your password
                    </Heading>

                    <Text variant="medium">
                      Enter your email address and if it is associated with your
                      account we will send a link to reset your password.
                    </Text>

                    {errors.length > 0 && (
                      <Text
                        color="red.500"
                        paddingLeft="17px"
                        marginBottom="10px"
                        marginTop="-10px"
                      >
                        {errors.map((error) => (error as any).message).join()}
                      </Text>
                    )}

                    <FormInput
                      name="email"
                      placeholder="Email"
                      autoFocus={true}
                      size="lg"
                    />
                  </VStack>

                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    width="full"
                    isLoading={isLoading}
                  >
                    Send a link
                  </Button>
                </>
              )}

              {isSent && (
                <>
                  <VStack spacing="20px" alignItems="start">
                    <Heading size="lg" as="h1">
                      Check your email
                    </Heading>

                    <Text>
                      If your account for {formik.values.email} exists, you will
                      receive an email from us with a link to reset your
                      password. If you don’t receive an email, please check your
                      spam folder.
                    </Text>
                  </VStack>

                  <Button
                    variant="primary"
                    size="lg"
                    width="full"
                    isLoading={isLoading}
                    onClick={async () => resend(formik.values.email)}
                  >
                    Resend a link
                  </Button>
                </>
              )}

              <Box>
                <Text variant="medium">
                  Back to{' '}
                  <Link as={ReactRouterLink} to="/login">
                    log in
                  </Link>
                </Text>
              </Box>
            </VStack>
          </VStack>
        </Box>
      </FormikProvider>
    </AuthForm>
  );
}
