import React, { useCallback } from 'react';
import { Button, Heading, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { InviteClientModal } from '@app/features/clients/modals/InviteClientModal';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { IProvider } from '@app/store/schema';

export interface ClientsActivePlaceholderProps {
  provider?: IProvider;
}

export function ClientsActivePlaceholderContent(
  props: ClientsActivePlaceholderProps,
) {
  const { provider } = props;
  const inviteClientModal = useDisclosure();

  const inviteClient = useCallback(() => {
    inviteClientModal.onOpen();
  }, []);

  return (
    <>
      <TabPanelContent>
        <VStack gap="12px" width="100%" p="13px 25px">
          <Heading size="sm" color="gray.500" as="h3">
            No active clients
          </Heading>
          <Text color="gray.500">
            Clients who accepted an invite will show up here.
          </Text>

          <Button
            size="sm"
            colorScheme="purple"
            variant="outline"
            leftIcon={<AddIcon />}
            onClick={inviteClient}
          >
            Invite new client
          </Button>
        </VStack>
      </TabPanelContent>

      <InviteClientModal
        provider={provider}
        isOpen={inviteClientModal.isOpen}
        onClose={inviteClientModal.onClose}
      />
    </>
  );
}

export const ClientsActivePlaceholder = React.memo(
  ClientsActivePlaceholderContent,
);
