import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiChevronRight } from 'react-icons/hi';
import { Button, Icon, Text } from '@chakra-ui/react';
import { IProvider } from '@app/store/schema';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { userRole } from '@app/utils/userRole';
import { providerFullName } from '@app/utils/userFullName';
import { formatDatetime } from '@app/utils/date';

export interface ProvidersPendingListItemProps {
  provider: IProvider;
  onInvite: (provider: IProvider) => void;
}

export function ProvidersPendingListItemContent(
  props: ProvidersPendingListItemProps,
) {
  const { provider, onInvite } = props;
  const navigate = useNavigate();

  const userNameFormatted = useMemo(
    () => providerFullName(provider!),
    [provider],
  );

  const userRoleFormatted = useMemo(
    () => userRole(provider.user.role),
    [provider.user.role],
  );

  const invitedAtFormatted = useMemo(
    () => formatDatetime(provider.invitedAt),
    [provider.invitedAt],
  );

  const resendInvite = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onInvite(provider);
    },
    [],
  );

  const navigateToDetails = useCallback(() => {
    navigate('/providers/' + provider.id);
  }, []);

  return (
    <TableRow className="clickable" onClick={navigateToDetails}>
      <TableCell width="100%">
        <Text variant="medium" size="sm">
          {userNameFormatted}
        </Text>
      </TableCell>
      <TableCell width="20%" flexShrink={0}>
        <Text variant="medium" size="sm">
          {userRoleFormatted}
        </Text>
      </TableCell>
      <TableCell width="20%" flexShrink={0}>
        <Text variant="medium" size="sm">
          {invitedAtFormatted}
        </Text>
      </TableCell>
      <TableCell width="98px" flexShrink={0}>
        <Button
          size="xs"
          colorScheme="purple"
          variant="outline"
          onClick={resendInvite}
        >
          Resend invite
        </Button>
      </TableCell>
      <TableCell width="24px" flexShrink={0} justifyContent="end">
        <Icon as={HiChevronRight} width="24px" height="24px" color="gray.400" />
      </TableCell>
    </TableRow>
  );
}

export const ProvidersPendingListItem = React.memo(
  ProvidersPendingListItemContent,
);
