import { GraphQLClient } from 'graphql-request';

export const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_URL!, {
  errorPolicy: 'all',
  credentials: 'include',
  mode: 'cors',
  headers: () => {
    return {
      'Content-Type': 'application/json',
    };
  },
});
