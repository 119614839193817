import React, { useContext } from 'react';
import { IoClose } from 'react-icons/io5';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { TableCell } from '@app/layout/table/TableCell';
import { TableRow } from '@app/layout/table/TableRow';
import {
  IClient,
  ISchedule,
  TFlowType,
  TScheduleType,
} from '@app/store/schema';
import { StoreContext } from '@app/store/StoreContext';
import { flowTypeName } from '@app/utils/flowTypeName';
import { flowName } from '@app/utils/flowName';
import { FrequencyDropdown } from '@app/layout/dropdown/FrequencyDropdown';

interface ClientScheduleItemProps {
  client: IClient;
  schedule: ISchedule;
}

export function ClientScheduleItem(props: ClientScheduleItemProps) {
  const { client, schedule } = props;
  const { getFlowById, updateClientSchedule, removeClientSchedule } =
    useContext(StoreContext);

  const flow = getFlowById(schedule.flowId);

  const update = async (value: TScheduleType, rrule: string | null) => {
    await updateClientSchedule(client.id, flow!.id, schedule.id, value, rrule);
  };

  const remove = async () => {
    await removeClientSchedule(client.id, schedule.id);
  };

  return (
    <TableRow>
      <TableCell width="60%" pt="16px" pb="16px">
        <VStack gap="2px" width="100%" alignItems="start">
          <Text variant="medium" size="lg">
            {flowName(flow)}
          </Text>

          {flow?.description && (
            <Text variant="medium" size="sm" color="gray.500">
              {flow?.description}
            </Text>
          )}

          {flow?.type !== 'ASSESSMENT' && (
            <Text variant="medium" size="sm" color="gray.600">
              {flowTypeName(flow?.type as TFlowType)}
            </Text>
          )}
        </VStack>
      </TableCell>
      <TableCell width="40%" minWidth="304px" pt="16px" pb="16px">
        <HStack gap="20px" width="100%" justifyContent="space-between">
          <FrequencyDropdown
            value={schedule.type}
            rrule={schedule.rrule}
            onChange={update}
            minWidth="160px"
            maxWidth="260px"
          />

          <Icon
            as={IoClose}
            width="24px"
            height="24px"
            color="gray.400"
            cursor="pointer"
            onClick={remove}
          />
        </HStack>
      </TableCell>
    </TableRow>
  );
}
