import React, { createContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Variables } from 'graphql-request';
import { graphQLClient } from '@app/store/client';
import { ProviderQuery } from '@app/store/queries/provider/provider';
import { OrganizationQuery } from '@app/store/queries/organization';
import { InviteClientMutation } from '@app/store/mutations/client/inviteClient';
import { UpdateClientMutation } from '@app/store/mutations/client/updateClient';
import { DischargeClientMutation } from '@app/store/mutations/client/dischargeClient';
import { ReactivateClientMutation } from '@app/store/mutations/client/reactivateClient';
import { InviteProviderMutation } from '@app/store/mutations/provider/inviteProvider';
import { ResendProviderInviteMutation } from '@app/store/mutations/provider/resendProviderInvite';
import { UpdateProviderMutation } from '@app/store/mutations/provider/updateProvider';
import { DeactivateProviderMutation } from '@app/store/mutations/provider/deactivateProvider';
import { AcceptInvitationMutation } from '@app/store/mutations/provider/acceptInvitation';
import { InitiateResetPasswordMutation } from '@app/store/mutations/provider/initiateReset';
import { ResetPasswordMutation } from '@app/store/mutations/provider/resetPassword';
import { AllFlowsQuery } from '@app/store/queries/flow/allFlows';
import { ClientSchedulesQuery } from '@app/store/queries/flow/clientSchedules';
import { SignInMutation, SignOutMutation } from '@app/store/mutations/auth';
import { AssignFlowScheduleMutation } from '@app/store/mutations/flow/assignFlowSchedule';
import { RemoveFlowScheduleMutation } from '@app/store/mutations/flow/removeFlowSchedule';
import { UpdateOrganizationMutation } from '@app/store/mutations/organization/updateOrganization';
import { FeedbackMutation } from '@app/store/mutations/help/feedback';
import {
  IAuthUser,
  IOrganization,
  IProvider,
  IClient,
  IFlow,
  ISchedule,
  TScheduleType,
} from '@app/store/schema';
import {
  Response,
  SignInData,
  ProviderData,
  OrganizationData,
  FlowsData,
  SchedulesData,
  AssignFlowScheduleData,
  RemoveFlowScheduleData,
  ClientResponse,
  InviteClientData,
  UpdateClientData,
  UpdateClientRequest,
  InviteClientRequest,
  DischargeClientData,
  ProviderResponse,
  ProviderRequest,
  DeactivateProviderRequest,
  InviteProviderData,
  UpdateProviderData,
  DeactivateProviderData,
  AcceptInvitationRequest,
  AcceptInvitationData,
  ReactivateClientData,
  InitiateResetPasswordRequest,
  ResetPasswordRequest,
  OrganizationRequest,
  OrganizationResponse,
  FeedbackRequest,
  FeedbackData,
  GetFlowPreviewData,
} from '@app/store/schema-response';
import { GetFlowPreviewMutation } from './mutations/flow/getFlowPreview';

interface UserStore {
  loading: boolean;
  user: IAuthUser | null;
}

interface OrganizationStore {
  loading: boolean;
  organization: IOrganization;
  providers: Map<string, IProvider>;
  clients: Map<string, IClient>;
  flows?: Map<string, IFlow>;
  schedules?: Map<string, ISchedule[]>;
}

interface Store {
  userStore: UserStore;
  organizationStore?: OrganizationStore;
}

interface StoreProviderProps {
  children: React.ReactNode;
}

interface StoreContextValue {
  isInitiated: boolean;
  store: Store;
  loadUser: () => any;
  loadOrganization: () => any;
  updateOrganization: (
    params: OrganizationRequest,
  ) => Promise<OrganizationResponse>;
  updateOrganizationLogo: (data: string) => any;
  removeOrganizationLogo: () => any;
  acceptInvitation: (params: AcceptInvitationRequest) => Promise<Response>;
  initiateResetPassword: (
    params: InitiateResetPasswordRequest,
  ) => Promise<Response>;
  resetPassword: (params: ResetPasswordRequest) => Promise<Response>;
  inviteProvider: (params: ProviderRequest) => Promise<ProviderResponse>;
  updateProvider: (
    providerId: string,
    params: ProviderRequest,
  ) => Promise<ProviderResponse>;
  deactivateProvider: (
    providerId: string,
    params: DeactivateProviderRequest,
  ) => Promise<Response>;
  inviteClient: (params: InviteClientRequest) => Promise<ClientResponse>;
  updateClient: (
    clientId: string,
    params: UpdateClientRequest,
  ) => Promise<ClientResponse>;
  dischargeClient: (clientId: string) => Promise<ClientResponse>;
  reactivateClient: (clientId: string) => Promise<ClientResponse>;
  loadClientSchedules: (id: string) => void;
  updateClientSchedule: (
    id: string,
    flowId: string,
    scheduleId: string,
    frequency: TScheduleType,
    rrule: string | null,
  ) => Promise<Response>;
  removeClientSchedule: (id: string, scheduleId: string) => Promise<Response>;
  bulkUpdateClientSchedule: (
    id: string,
    toAdd: any[],
    toRemove: string[],
  ) => Promise<Response>;
  signIn: (email: string, password: string) => any;
  signOut: () => any;
  sendFeedback: (data: any) => Promise<Response>;
  getFlowPreview: (flowId: string) => Promise<string>;

  isUserLoading: () => boolean;
  getUser: () => IAuthUser | null;
  isAdmin: (user: IProvider) => boolean;
  isSupervisor: (user: IProvider) => boolean;
  isClinician: (user: IProvider) => boolean;
  isAdminOrSupervisor: (user: IProvider) => boolean;
  isOrganizationLoading: () => boolean;
  getOrganization: () => IOrganization | undefined;
  getProviders: () => IProvider[];
  getProviderById: (id: string) => IProvider | undefined;
  getMyClients: () => IClient[];
  getOtherClients: () => IClient[];
  getClientById: (id: string) => IClient | undefined;
  getClientsByProviderId: (id: string) => IClient[];
  getHighRiskClientsByProviderId: (id: string) => IClient[];
  getFlows: () => IFlow[];
  getFlowById: (id: string) => IFlow | undefined;
  getClientSchedules: (id: string) => ISchedule[];
}

const getDefaultStore = (): Store => ({
  userStore: {
    loading: true,
    user: null,
  },
});

export const StoreContext = createContext<StoreContextValue>({
  isInitiated: false,
  store: getDefaultStore(),
  loadUser: () => {},
  loadOrganization: () => {},
  updateOrganization: () => Promise.resolve({}),
  updateOrganizationLogo: () => {},
  removeOrganizationLogo: () => {},
  acceptInvitation: () => Promise.resolve({}),
  initiateResetPassword: () => Promise.resolve({}),
  resetPassword: () => Promise.resolve({}),
  inviteProvider: () => Promise.resolve({}),
  updateProvider: () => Promise.resolve({}),
  deactivateProvider: () => Promise.resolve({}),
  inviteClient: () => Promise.resolve({}),
  updateClient: () => Promise.resolve({}),
  dischargeClient: () => Promise.resolve({}),
  reactivateClient: () => Promise.resolve({}),
  loadClientSchedules: () => null,
  updateClientSchedule: () => Promise.resolve({}),
  removeClientSchedule: () => Promise.resolve({}),
  bulkUpdateClientSchedule: () => Promise.resolve({}),
  signIn: () => {},
  signOut: () => {},
  sendFeedback: () => Promise.resolve({}),
  getFlowPreview: () => Promise.resolve(''),

  isUserLoading: () => true,
  getUser: () => null,
  isAdmin: () => false,
  isSupervisor: () => false,
  isClinician: () => false,
  isAdminOrSupervisor: () => false,
  isOrganizationLoading: () => true,
  getOrganization: () => undefined,
  getProviders: () => [],
  getProviderById: () => undefined,
  getMyClients: () => [],
  getOtherClients: () => [],
  getClientById: () => undefined,
  getClientsByProviderId: () => [],
  getHighRiskClientsByProviderId: () => [],
  getFlows: () => [],
  getFlowById: () => undefined,
  getClientSchedules: () => [],
});

export const mapToArray = (map: Map<string, any>) =>
  Array.from(map.entries()).map(([, item]) => item);

export const arrayToMap = (array: Array<any>) =>
  new Map(array.map((item) => [item.id, item]));

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const isInitiated = false;
  const [store, setStore] = useState(getDefaultStore());

  const resetStore = () => {
    const defaultStore = getDefaultStore();

    defaultStore.userStore.loading = false;

    setStore(defaultStore);
  };

  const sendRequest = async <T,>(query: string, variables?: Variables) => {
    try {
      const { data, errors } = await graphQLClient.rawRequest<T>(
        query,
        variables,
      );
      if (errors) {
        const isUnauthenticated = errors.find(
          (error) => error.extensions.code === 'UNAUTHENTICATED',
        );

        if (!!isUnauthenticated) {
          resetStore();
        }
      }

      return {
        data,
        errors,
      };
    } catch (error) {
      resetStore();

      return {
        data: {} as T,
        errors: [error],
      };
    }
  };

  const loadUser = async () => {
    const { data } = await sendRequest<ProviderData>(ProviderQuery);

    const user = data.provider;

    Sentry.setUser({
      id: user ? user.user.id : undefined,
    });

    setStore({
      ...store,
      userStore: {
        loading: false,
        user,
      },
    });
  };

  const signIn = async (email: string, password: string) => {
    const { data, errors } = await sendRequest<SignInData>(SignInMutation, {
      email,
      password,
    });

    if (data.signIn) {
      const user = data.signIn.provider;

      Sentry.setUser({
        id: user.user.id,
      });

      setStore({
        ...store,
        userStore: {
          loading: false,
          user,
        },
      });
    }

    return { data, errors };
  };

  const signOut = async () => {
    await sendRequest<SignInData>(SignOutMutation);

    setStore({
      userStore: {
        loading: false,
        user: null,
      },
    });
  };

  const sendFeedback = async (params: FeedbackRequest) => {
    const { errors } = await sendRequest<FeedbackData>(FeedbackMutation, {
      data: params,
    });

    return { errors };
  };

  const loadOrganization = async () => {
    const { data } = await sendRequest<OrganizationData>(OrganizationQuery);

    if (data && data.organization) {
      const { organization } = data;

      setStore({
        ...store,
        organizationStore: {
          loading: false,
          organization,
          providers: arrayToMap(
            organization.providers.filter(
              (client) => client.status !== 'DEACTIVATED',
            ),
          ),
          clients: arrayToMap(organization.clients),
        },
      });
    }
  };

  const updateOrganization = async (params: OrganizationRequest) => {
    const { data, errors } = await sendRequest<OrganizationData>(
      UpdateOrganizationMutation,
      {
        ...params,
      },
    );

    if (data && data.organization) {
      const { organizationStore } = store;
      const { organization } = organizationStore!;

      setStore({
        ...store,
        organizationStore: {
          ...organizationStore!,
          organization: {
            ...organization,
            ...data.organization,
          },
        },
      });

      return {
        organization: data.organization,
      };
    }

    return {
      errors,
    };
  };

  const updateOrganizationLogo = async (data: string) => {
    const { organizationStore } = store;
    const { organization } = organizationStore!;

    setStore({
      ...store,
      organizationStore: {
        ...organizationStore!,
        organization: {
          ...organization,
          logo: data,
        },
      },
    });
  };

  const removeOrganizationLogo = async () => {
    const { organizationStore } = store;
    const { organization } = organizationStore!;

    setStore({
      ...store,
      organizationStore: {
        ...organizationStore!,
        organization: {
          ...organization,
          logo: null,
        },
      },
    });
  };

  const acceptInvitation = async (params: AcceptInvitationRequest) => {
    const { errors } = await sendRequest<AcceptInvitationData>(
      AcceptInvitationMutation,
      {
        ...params,
      },
    );

    return {
      errors,
    };
  };

  const initiateResetPassword = async (
    params: InitiateResetPasswordRequest,
  ) => {
    const { errors } = await sendRequest(InitiateResetPasswordMutation, {
      ...params,
    });

    return {
      errors,
    };
  };

  const resetPassword = async (params: ResetPasswordRequest) => {
    const { errors } = await sendRequest(ResetPasswordMutation, {
      ...params,
    });

    return {
      errors,
    };
  };

  const inviteProvider = async (params: ProviderRequest) => {
    const { data, errors } = await sendRequest<InviteProviderData>(
      InviteProviderMutation,
      {
        ...params,
      },
    );

    if (data && data.inviteProvider) {
      setProvider(data.inviteProvider);

      return {
        provider: data.inviteProvider,
      };
    }

    return {
      errors,
    };
  };

  const updateProvider = async (
    providerId: string,
    params: ProviderRequest,
  ) => {
    const { data, errors } = await sendRequest<UpdateProviderData>(
      UpdateProviderMutation,
      {
        ...params,
        providerId,
      },
    );

    if (data && data.updateProvider) {
      if (data.updateProvider.status === 'INVITED') {
        await sendRequest(ResendProviderInviteMutation, {
          providerId,
        });
      }

      setProvider(data.updateProvider);

      return {
        provider: data.updateProvider,
      };
    }

    return {
      errors,
    };
  };

  const deactivateProvider = async (
    providerId: string,
    params: DeactivateProviderRequest,
  ) => {
    const { data, errors } = await sendRequest<DeactivateProviderData>(
      DeactivateProviderMutation,
      {
        ...params,
        providerId,
      },
    );

    if (data && data.deactivateProvider) {
      const provider = data.deactivateProvider;
      const reassignToProvider = store.organizationStore?.providers.get(
        params.reassignToProviderId,
      );

      setStore({
        ...store,
        organizationStore: {
          ...store.organizationStore!,
          providers: removeProvider(provider),
          clients: reassignClients(provider, reassignToProvider!),
        },
      });
    }

    return {
      errors,
    };
  };

  const inviteClient = async (params: InviteClientRequest) => {
    const { data, errors } = await sendRequest<InviteClientData>(
      InviteClientMutation,
      {
        ...params,
      },
    );

    if (data && data.inviteClient) {
      setClient(data.inviteClient);

      return {
        client: data.inviteClient,
      };
    }

    return {
      errors,
    };
  };

  const updateClient = async (
    clientId: string,
    params: UpdateClientRequest,
  ) => {
    const { data, errors } = await sendRequest<UpdateClientData>(
      UpdateClientMutation,
      {
        ...params,
        clientId,
      },
    );

    if (data && data.updateClient) {
      setClient(data.updateClient);
    }

    return {
      errors,
    };
  };

  const dischargeClient = async (clientId: string) => {
    const { data, errors } = await sendRequest<DischargeClientData>(
      DischargeClientMutation,
      {
        clientId,
      },
    );

    if (data && data.dischargeClient) {
      setClient(data.dischargeClient);
    }

    return {
      errors,
    };
  };

  const reactivateClient = async (clientId: string) => {
    const { data, errors } = await sendRequest<ReactivateClientData>(
      ReactivateClientMutation,
      {
        clientId,
      },
    );

    if (data && data.reactivateClient) {
      setClient(data.reactivateClient);
    }

    return {
      errors,
    };
  };

  const loadClientSchedules = async (id: string) => {
    let flows = new Map(store.organizationStore?.flows || new Map());

    if (!store.organizationStore?.flows) {
      const flowsData = await sendRequest<FlowsData>(AllFlowsQuery);

      if (flowsData && flowsData.data && flowsData.data.allFlows) {
        flows = arrayToMap(flowsData.data.allFlows);
      }
    }

    const schedulesData = await sendRequest<SchedulesData>(
      ClientSchedulesQuery,
      {
        clientId: id,
      },
    );

    if (
      schedulesData &&
      schedulesData.data &&
      schedulesData.data.clientSchedules
    ) {
      const { clientSchedules } = schedulesData.data;
      const clone = new Map(store.organizationStore?.schedules || new Map());

      clone.set(id, arrayToMap(clientSchedules));

      setStore({
        ...store,
        organizationStore: {
          ...store.organizationStore!,
          flows,
          schedules: clone,
        },
      });
    }
  };

  const updateClientSchedule = async (
    id: string,
    flowId: string,
    scheduleId: string,
    frequency: TScheduleType,
    rrule: string | null,
  ) => {
    const { data, errors } = await sendRequest<AssignFlowScheduleData>(
      AssignFlowScheduleMutation,
      {
        clientId: id,
        flows: [
          {
            flowId,
            scheduleId,
            frequency,
            rrule,
          },
        ],
      },
    );

    if (data && data.assignFlowSchedule) {
      const { assignFlowSchedule } = data;
      const clone = new Map(store.organizationStore?.schedules || new Map());
      const currentClientSchedules = clone.get(id);

      assignFlowSchedule.forEach((schedule) =>
        currentClientSchedules.set(schedule.id, schedule),
      );

      clone.set(id, currentClientSchedules);

      setStore({
        ...store,
        organizationStore: {
          ...store.organizationStore!,
          schedules: clone,
        },
      });
    }

    return {
      errors,
    };
  };

  const removeClientSchedule = async (id: string, scheduleId: string) => {
    const { data, errors } = await sendRequest<RemoveFlowScheduleData>(
      RemoveFlowScheduleMutation,
      {
        clientId: id,
        scheduleIds: [scheduleId],
      },
    );

    if (data && data.removeFlowSchedule) {
      const { removeFlowSchedule } = data;

      const clone = new Map(store.organizationStore?.schedules || new Map());
      const currentClientSchedules = clone.get(id);

      removeFlowSchedule.forEach((schedule) =>
        currentClientSchedules.delete(schedule.id),
      );

      clone.set(id, currentClientSchedules);

      setStore({
        ...store,
        organizationStore: {
          ...store.organizationStore!,
          schedules: clone,
        },
      });
    }

    return {
      errors,
    };
  };

  const bulkUpdateClientSchedule = async (
    id: string,
    toAdd: any[],
    toRemove: string[],
  ) => {
    let errors: any[] = [];
    const clone = new Map(store.organizationStore?.schedules || new Map());
    const currentClientSchedules = clone.get(id);

    if (toAdd.length > 0) {
      const assignFlowScheduleData = await sendRequest<AssignFlowScheduleData>(
        AssignFlowScheduleMutation,
        {
          clientId: id,
          flows: toAdd,
        },
      );

      if (
        assignFlowScheduleData &&
        assignFlowScheduleData.data &&
        assignFlowScheduleData.data.assignFlowSchedule
      ) {
        const { assignFlowSchedule } = assignFlowScheduleData.data;

        assignFlowSchedule.forEach((schedule) =>
          currentClientSchedules.set(schedule.id, schedule),
        );
      }
    }

    if (toRemove.length > 0) {
      const removeFlowScheduleData = await sendRequest<RemoveFlowScheduleData>(
        RemoveFlowScheduleMutation,
        {
          clientId: id,
          scheduleIds: toRemove,
        },
      );

      if (
        removeFlowScheduleData &&
        removeFlowScheduleData.data &&
        removeFlowScheduleData.data.removeFlowSchedule
      ) {
        const { removeFlowSchedule } = removeFlowScheduleData.data;

        removeFlowSchedule.forEach((schedule) =>
          currentClientSchedules.delete(schedule.id),
        );
      } else {
        errors = errors.concat(removeFlowScheduleData?.errors || []);
      }
    }

    if (toAdd.length > 0 || toRemove.length > 0) {
      clone.set(id, currentClientSchedules);

      setStore({
        ...store,
        organizationStore: {
          ...store.organizationStore!,
          schedules: clone,
        },
      });
    }

    return {
      errors,
    };
  };

  const getFlowPreview = async (flowId: string) => {
    const { data } = await sendRequest<GetFlowPreviewData>(
      GetFlowPreviewMutation,
      {
        flowId,
      },
    );

    return data?.getFlowPreview?.previewUrl;
  };

  const isUserLoading = () => store.userStore.loading;

  const getUser = () => store.userStore.user;

  const isAdmin = (provider: IProvider) => provider.user.role === 'ADMIN';

  const isSupervisor = (provider: IProvider) =>
    provider.user.role === 'SUPERVISOR';

  const isClinician = (provider: IProvider) =>
    provider.user.role === 'CLINICIAN';

  const isAdminOrSupervisor = (provider: IProvider) =>
    isAdmin(provider) || isSupervisor(provider);

  const isOrganizationLoading = () =>
    !store.organizationStore ||
    (store.organizationStore && store.organizationStore.loading);

  const getOrganization = () => store.organizationStore?.organization;

  const getProviders = () =>
    mapToArray(store.organizationStore?.providers || new Map());

  const getProviderById = (id: string) =>
    store.organizationStore?.providers.get(id);

  const getMyClients = () =>
    getClientsByProviderId(store.userStore.user?.id as string);

  const getOtherClients = () => {
    const clients: IClient[] = mapToArray(
      store.organizationStore?.clients || new Map(),
    );

    return clients.filter((client) => {
      const clientProviders = client.providers.map((_) => _.id);

      return clientProviders.indexOf(store.userStore.user?.id as string) === -1;
    });
  };

  const getClientById = (id: string) =>
    store.organizationStore?.clients.get(id);

  const getClientsByProviderId = (id: string) => {
    const clients: IClient[] = mapToArray(
      store.organizationStore?.clients || new Map(),
    );

    return clients.filter((client) => {
      const clientProviders = client.providers.map((_) => _.id);

      return clientProviders.indexOf(id) !== -1;
    });
  };

  const getHighRiskClientsByProviderId = (id: string) =>
    getClientsByProviderId(id).filter((client) => client.isHighRisk);

  const getFlows = () =>
    mapToArray(store.organizationStore?.flows || new Map());

  const getFlowById = (id: string) =>
    (store.organizationStore?.flows || new Map()).get(id);

  const getClientSchedules = (id: string) => {
    const schedules = (store.organizationStore?.schedules || new Map()).get(id);

    return mapToArray(schedules || new Map());
  };

  const setProvider = (provider: IProvider) => {
    const clone = new Map(store.organizationStore?.providers || new Map());

    const previousProviderValue: IProvider = clone.get(provider.id);

    if (provider.supervisors && provider.supervisors.length) {
      if (previousProviderValue) {
        previousProviderValue.supervisors.forEach((supervisor) => {
          const item: IProvider = clone.get(supervisor.id);

          item.subordinates = item.subordinates.filter(
            (subordinate) => subordinate.id !== provider.id,
          );

          clone.set(item.id, item);
        });
      }

      provider.supervisors.forEach((supervisor) => {
        const item: IProvider = clone.get(supervisor.id);

        item.subordinates.push({ id: provider.id });

        clone.set(item.id, item);
      });
    }

    if (provider.subordinates && provider.subordinates.length) {
      if (previousProviderValue) {
        previousProviderValue.subordinates.forEach((subordinate) => {
          const item: IProvider = clone.get(subordinate.id);

          item.supervisors = item.supervisors.filter(
            (supervisor) => supervisor.id !== provider.id,
          );

          clone.set(item.id, item);
        });
      }

      provider.subordinates.forEach((subordinate) => {
        const item: IProvider = clone.get(subordinate.id);

        item.supervisors.push({ id: provider.id });

        clone.set(item.id, item);
      });
    }

    clone.set(provider.id, provider);

    setStore({
      ...store,
      organizationStore: {
        ...store.organizationStore!,
        providers: clone,
      },
    });
  };

  const removeProvider = (provider: IProvider) => {
    const clone = new Map(store.organizationStore?.providers || new Map());

    const previousProviderValue: IProvider = clone.get(provider.id);

    if (
      previousProviderValue.supervisors &&
      previousProviderValue.supervisors.length
    ) {
      previousProviderValue.supervisors.forEach((supervisor) => {
        const item: IProvider = clone.get(supervisor.id);

        item.subordinates = item.subordinates.filter(
          (subordinate) => subordinate.id !== provider.id,
        );

        clone.set(item.id, item);
      });
    }

    if (
      previousProviderValue.subordinates &&
      previousProviderValue.subordinates.length
    ) {
      previousProviderValue.subordinates.forEach((subordinate) => {
        const item: IProvider = clone.get(subordinate.id);

        item.supervisors = item.supervisors.filter(
          (supervisor) => supervisor.id !== provider.id,
        );

        item.supervisors.push({
          id: store.userStore.user?.id as string,
        });

        clone.set(item.id, item);
      });
    }

    clone.delete(provider.id);

    return clone;
  };

  const reassignClients = (
    provider: IProvider,
    reassignToProvider: IProvider,
  ) => {
    const clone = new Map(store.organizationStore?.clients || new Map());
    const providerClients = getClientsByProviderId(provider.id);

    providerClients.forEach((client) => {
      const providers = client.providers.filter(
        (clientProvider) =>
          [provider.id, reassignToProvider.id].indexOf(clientProvider.id) ===
          -1,
      );

      providers.push({ id: reassignToProvider.id });

      clone.set(client.id, {
        ...client,
        providers,
      });
    });

    return clone;
  };

  const setClient = (client: IClient) => {
    const clone = new Map(store.organizationStore?.clients || new Map());

    clone.set(client.id, client);

    setStore({
      ...store,
      organizationStore: {
        ...store.organizationStore!,
        clients: clone,
      },
    });
  };

  const value = {
    isInitiated,
    store,
    loadUser,
    loadOrganization,
    updateOrganization,
    updateOrganizationLogo,
    removeOrganizationLogo,
    acceptInvitation,
    initiateResetPassword,
    resetPassword,
    inviteProvider,
    updateProvider,
    deactivateProvider,
    inviteClient,
    updateClient,
    dischargeClient,
    reactivateClient,
    loadClientSchedules,
    updateClientSchedule,
    removeClientSchedule,
    bulkUpdateClientSchedule,
    signIn,
    signOut,
    sendFeedback,
    getFlowPreview,

    isUserLoading,
    getUser,
    isAdmin,
    isSupervisor,
    isClinician,
    isAdminOrSupervisor,
    isOrganizationLoading,
    getOrganization,
    getProviders,
    getProviderById,
    getMyClients,
    getOtherClients,
    getClientById,
    getClientsByProviderId,
    getHighRiskClientsByProviderId,
    getFlows,
    getFlowById,
    getClientSchedules,
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
