import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ChakraProvider } from '@chakra-ui/react';
import { ToastProviderProps } from '@chakra-ui/toast';
import { StoreProvider } from '@app/store/StoreContext';
import { App } from '@app/App';

import './styles.scss';
import { theme } from './theme';
import { Toast } from '@app/layout/Toast';

import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

Sentry.init({
  dsn: 'https://228bd59106f2b055ada10a996af6c897@o4506405805162496.ingest.sentry.io/4506852654710784',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/provider\.silenttime\.com/],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV === 'production',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const toastOptions: ToastProviderProps = {
  defaultOptions: {
    position: 'bottom',
    duration: 2000,
    isClosable: true,
    render: ({ title, variant, isClosable, onClose, description }) => (
      <Toast
        title={title}
        variant={variant}
        isClosable={isClosable}
        onClose={onClose}
        description={description}
      />
    ),
  },
};

root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme} toastOptions={toastOptions}>
    <StoreProvider>
      <App />
    </StoreProvider>
  </ChakraProvider>,
  // </React.StrictMode>,
);
