import { useState } from 'react';

export interface FilterItem {
  id: string;
  name: string;
}

export interface FilterValue {
  [key: string]: string[];
}

export const useFilter = (defaultValue: FilterValue) => {
  const [filterValue, setFilterValue] = useState<FilterValue>(defaultValue);

  const applyFilter = (name: string, id: string) => {
    const currentValue = { ...filterValue };

    if (currentValue[name].indexOf(id) === -1) {
      currentValue[name] = currentValue[name].concat([id]);
    } else {
      currentValue[name] = currentValue[name].filter((item) => item !== id);
    }

    setFilterValue(currentValue);
  };

  const resetFilter = (name: string) => {
    const currentValue = { ...filterValue };

    currentValue[name] = [];

    setFilterValue(currentValue);
  };

  const resetAllFilters = (value: FilterValue) => {
    setFilterValue(value);
  };

  return {
    filterValue,
    applyFilter,
    resetFilter,
    resetAllFilters,
  };
};
