import React from 'react';
import {
  BsArrowDownShort,
  BsArrowRightShort,
  BsArrowUpShort,
} from 'react-icons/bs';
import {
  defineStyleConfig,
  forwardRef,
  HStack,
  Icon,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';

export const scoreMovementTheme = defineStyleConfig({
  baseStyle: {
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
});

export const ScoreMovement = forwardRef((props, ref) => {
  const { scoreMovement, variant, size, ...rest } = props;
  const styles = useStyleConfig('ScoreMovement', { variant });

  const { asset, text } = scoreMovement;

  let icon = BsArrowRightShort;

  if (asset && asset.icon === 'ARROW_UP_SMALL') {
    icon = BsArrowUpShort;
  }

  if (asset && asset.icon === 'ARROW_DOWN_SMALL') {
    icon = BsArrowDownShort;
  }

  return (
    <HStack ref={ref} __css={styles} {...rest}>
      {asset && <Icon as={icon} width="24px" height="24px" />}
      <Text size={size}>{text}</Text>
    </HStack>
  );
});

ScoreMovement.displayName = 'ScoreMovement';
