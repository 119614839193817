import React, { useContext } from 'react';
import { Text } from '@chakra-ui/react';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import { periodTitle } from '@app/utils/date';

export function ChartPeriod() {
  const { store } = useContext(WidgetsContext);

  if (!store.period) {
    return <></>;
  }

  return <Text color="gray.500">{periodTitle(store.period)}</Text>;
}
