import React from 'react';
import { Heading } from '@chakra-ui/react';

export function WidgetPlaceholder() {
  return (
    <Heading size="md" variant="widgetPlaceholder">
      No entries
    </Heading>
  );
}
