import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { FlowEditorScreenPropertyActions } from './FlowEditorApp';
import {
  ScreenFragmentFragment,
  ScreenType,
} from '../../../__generated__/graphql';
import { useFragment } from '../../../__generated__';
import { InputFragment, InputInterfaceFragment } from './fragments/common';

interface ScreenPropertiesProps {
  screen: ScreenFragmentFragment | null;
}

export default function ScreenProperties({
  onChangeScreenProperty,
  onChangeScreenCtaLabel,
  onChangeScreenInputProperty,
  screen,
}: FlowEditorScreenPropertyActions & ScreenPropertiesProps) {
  if (!screen) return null;
  if (
    [ScreenType.SurveyScreen, ScreenType.CongratulationsScreen].includes(
      screen.screenType,
    )
  )
    return <div>No editable properties</div>;

  const cta = useFragment(InputFragment, screen.cta);

  const inputs = useFragment(InputInterfaceFragment, screen.inputs);

  return (
    <Box p={2} maxWidth="1000px">
      <FormControl isInvalid={false} marginBottom={5}>
        <FormLabel>Title</FormLabel>
        <Input
          onChange={(ev) => {
            onChangeScreenProperty(screen.id, 'title', ev.target.value);
          }}
          placeholder="name"
          value={screen.title ?? ''}
        />
        <FormErrorMessage></FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={false} marginBottom={5}>
        <FormLabel>Subtitle</FormLabel>
        <Textarea
          onChange={(ev) => {
            onChangeScreenProperty(screen.id, 'subtitle', ev.target.value);
          }}
          placeholder="subtitle"
          value={screen.subtitle ?? ''}
        />
        <FormErrorMessage></FormErrorMessage>
      </FormControl>

      {inputs?.map((input) => {
        return (
          <Box key={input.id}>
            {'label' in input && (
              <FormControl isInvalid={false} marginBottom={5}>
                <FormLabel>Subtitle</FormLabel>
                <Textarea
                  onChange={(ev) => {
                    onChangeScreenInputProperty(
                      screen.id,
                      input.id,
                      'label',
                      ev.target.value,
                    );
                  }}
                  placeholder="Subtitle"
                  value={input.label ?? ''}
                />
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
