import React, { useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { IoEye } from 'react-icons/io5';
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { StoreContext } from '@app/store/StoreContext';
import { IClient, TFlowType, TScheduleType } from '@app/store/schema';
import { flowTypeName } from '@app/utils/flowTypeName';
import { flowName } from '@app/utils/flowName';
import PreviewModal from './PreviewModal';

interface ExercisesModalProps {
  title: string;
  searchPlaceholder: string;
  client: IClient;
  types: TFlowType[];
  isOpen: boolean;
  onClose(): void;
}

export function ExercisesModal(props: ExercisesModalProps) {
  const { title, searchPlaceholder, client, types, isOpen, onClose } = props;
  const {
    getFlows,
    getClientSchedules,
    bulkUpdateClientSchedule,
    getFlowPreview,
  } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const toast = useToast();

  const flows = getFlows().filter((flow) => types.indexOf(flow.type) !== -1);
  const schedules = getClientSchedules(client!.id) || [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: flows.reduce((fields, flow) => {
      const schedulesPerFlow = schedules.filter(
        (schedule) => schedule.flowId === flow.id,
      );

      fields[flow.id] = schedulesPerFlow.length > 0;

      return fields;
    }, {} as any),
    onSubmit: async (value) => {
      setIsLoading(true);

      const toAdd: { flowId: string; frequency: TScheduleType }[] = [];
      const toRemove: string[] = [];

      Object.keys(value).forEach((id) => {
        const isChecked = value[id];

        const schedulesPerFlow = schedules.filter(
          (schedule) => schedule.flowId === id,
        );

        if (isChecked && schedulesPerFlow.length === 0) {
          toAdd.push({
            flowId: id,
            frequency: 'FREE',
          });
        }

        if (!isChecked && schedulesPerFlow.length > 0) {
          schedulesPerFlow.forEach((schedule) => toRemove.push(schedule.id));
        }
      });

      if (toAdd.length === 0 && toRemove.length === 0) {
        clearForm();
        return;
      }

      const { errors } = await bulkUpdateClientSchedule(
        client.id,
        toAdd,
        toRemove,
      );

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const filteredFlows = flows.filter((flow) => {
    const { name, description } = flow;

    return (
      (name || '').toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      (description || '').toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  });

  const clearForm = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <PreviewModal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        previewUrl={previewUrl}
        title=""
      />
      <Modal isOpen={isOpen} onClose={clearForm}>
        <ModalOverlay />
        <ModalContent
          margin={0}
          borderRadius={0}
          minHeight="100%"
          height="100%"
          overflow="hidden"
          containerProps={{
            justifyContent: 'end',
            left: 'auto',
            padding: 0,
            height: '100%',
          }}
        >
          <ModalHeader>
            {title}

            <FormControl marginTop="24px">
              <InputGroup display="flex" alignItems="center">
                <InputLeftElement
                  pointerEvents="none"
                  height="48px"
                  width="48px"
                >
                  <Icon as={FiSearch} color="gray.400" boxSize="18px" />
                </InputLeftElement>

                <Input
                  type="text"
                  placeholder={searchPlaceholder}
                  size="lg"
                  autoFocus={true}
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                />
              </InputGroup>
            </FormControl>
          </ModalHeader>
          <ModalCloseButton />
          <FormikProvider value={formik}>
            <form
              onSubmit={formik.handleSubmit}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <ModalBody overflow="auto">
                <VStack gap="25px" alignItems="start">
                  <VStack gap="10px" alignItems="start" width="100%">
                    {filteredFlows.length > 0 &&
                      filteredFlows.map((flow) => (
                        <Checkbox
                          size="lg"
                          colorScheme="purple"
                          width="100%"
                          key={flow.id}
                          id={flow.id}
                          name={flow.id}
                          onChange={formik.handleChange}
                          isChecked={formik.values[flow.id]}
                        >
                          <Box marginInlineStart="22px" padding="10px 0">
                            <Text variant="medium">
                              {flowName(flow)}{' '}
                              {flow.previewUrl && (
                                <IconButton
                                  aria-label="See preview"
                                  size="xs"
                                  icon={<IoEye />}
                                  onClick={async () => {
                                    const previewUrl = await getFlowPreview(
                                      flow.id,
                                    );
                                    setPreviewUrl(previewUrl);
                                    setIsPreviewOpen(true);
                                  }}
                                />
                              )}
                            </Text>
                            {flow.description && (
                              <Text variant="medium" size="sm" color="gray.500">
                                {flow.description}
                              </Text>
                            )}

                            {flow.type !== 'ASSESSMENT' && (
                              <Text variant="medium" size="sm" color="gray.600">
                                {flowTypeName(flow.type)}
                              </Text>
                            )}
                          </Box>
                        </Checkbox>
                      ))}

                    {filteredFlows.length === 0 && (
                      <Center width="100%">
                        <Text color="gray.500">No results</Text>
                      </Center>
                    )}
                  </VStack>
                </VStack>
              </ModalBody>

              <ModalFooter flexShrink={0}>
                <Button size="sm" mr="12px" onClick={clearForm}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  isLoading={isLoading}
                >
                  Save changes
                </Button>
              </ModalFooter>
            </form>
          </FormikProvider>
        </ModalContent>
      </Modal>
    </>
  );
}
