import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, VStack, Heading, useToast } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import {
  compose,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { FormPassword } from '@app/layout/form/FormPassword';

export function SettingsSetNewPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const { resetPassword } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        password: compose([
          requiredValidator(values.password),
          minLength(values.password, 10),
        ]),
        confirmPassword: compose([
          requiredValidator(values.confirmPassword),
          values.password.length > 0 &&
          values.password !== values.confirmPassword
            ? 'Passwords do not match.'
            : null,
        ]),
      }),
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await resetPassword({
        token: params.token!,
        password: value.password,
      });

      if (errors) {
        setIsLoading(false);

        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        navigate('/settings/personal');
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <VStack gap="25px" alignItems="start" pr="30px" pl="30px">
          <Heading size="md">Account security</Heading>

          <VStack gap="20px" alignItems="start">
            <Heading size="sm">Reset password</Heading>

            <FormPassword
              name="password"
              placeholder="Password*"
              autoFocus={true}
              width="500px"
            />

            <FormPassword
              name="confirmPassword"
              placeholder="Confirm new password*"
              width="500px"
            />
          </VStack>

          <Button
            type="submit"
            variant="primary"
            size="md"
            isLoading={isLoading}
          >
            Reset password
          </Button>
        </VStack>
      </form>
    </FormikProvider>
  );
}
