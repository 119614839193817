import React from 'react';
import { FiCalendar } from 'react-icons/fi';
import { Button, forwardRef, Icon, Menu, MenuButton } from '@chakra-ui/react';
import { periodTitle } from '@app/utils/date';
import { DatepickerDropdownOptions } from '@app/layout/dropdown/DatepickerDropdownOptions';

export const DatepickerDropdown = forwardRef((props, ref) => {
  const { value, onChange, ...rest } = props;

  const title = value ? periodTitle(value, 'MMM D, YYYY') : 'All time';

  return (
    <Menu closeOnSelect={false} isLazy>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            ref={ref}
            {...rest}
            as={Button}
            size="sm"
            variant="select"
            flexShrink={0}
            rightIcon={
              <Icon
                as={FiCalendar}
                color="gray.900"
                width="18px"
                height="18px"
              />
            }
          >
            {title}
          </MenuButton>

          {isOpen && (
            <DatepickerDropdownOptions
              value={value}
              onClose={onClose}
              onChange={onChange}
            />
          )}
        </>
      )}
    </Menu>
  );
});

DatepickerDropdown.displayName = 'Datepicker';
