import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { WidgetHeader } from '@app/layout/widget/WidgetHeader';
import { WidgetPlaceholder } from '@app/layout/widget/WidgetPlaceholder';
import { Filter } from '@app/layout/filter/Filter';
import { FilterItem, useFilter } from '@app/layout/filter/useFilter';
import { Comparator } from '@app/utils/comparator';
import { useSort } from '@app/layout/sort/useSort';
import { mapToArray } from '@app/store/StoreContext';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import {
  IExtendedTrackerResultInput,
  ITrackerItem,
  ITrackerTrigger,
} from '@app/store/schema';

interface MoodLine {
  createdAt: string;
  value: number;
  trackerItem: ITrackerItem;
  trackerTrigger: ITrackerTrigger;
}

export function MoodTableWidget() {
  const { store } = useContext(WidgetsContext);
  const [lines, setLines] = useState([] as MoodLine[]);
  const [visibleCount, setVisibleCount] = useState(10);
  const [filterMood, setFilterMood] = useState<FilterItem[]>([]);
  const [filterFactors, setFilterFactors] = useState<FilterItem[]>([]);
  const { filterValue, applyFilter, resetFilter, resetAllFilters } = useFilter({
    mood: [],
    factors: [],
  });
  const { sortValue, applySort } = useSort({
    column: 'createdAt',
    order: 'asc',
  });

  const updateLines = () => {
    const trackerResults = store.trackerResults || [];
    const availableMood = new Map();
    const availableFactors = new Map();

    const results: MoodLine[] = [];

    trackerResults.forEach((item: IExtendedTrackerResultInput) => {
      item.trackerTriggers.forEach((trackerTrigger) => {
        const { trackerItem } = item;

        results.push({
          createdAt: item.createdAt,
          value: item.value,
          trackerItem: trackerItem,
          trackerTrigger,
        });

        availableMood.set(trackerItem.id, {
          id: trackerItem.id,
          name: trackerItem.label,
        });

        availableFactors.set(trackerTrigger.id, {
          id: trackerTrigger.id,
          name: trackerTrigger.text,
        });
      });
    });

    resetAllFilters({
      mood: [],
      factors: [],
    });
    setFilterMood(mapToArray(availableMood));
    setFilterFactors(mapToArray(availableFactors));
    setLines(results);
  };

  useEffect(() => {
    if (!store.loading) {
      updateLines();
    } else {
      setVisibleCount(10);
    }
  }, [store.loading]);

  const sortedTable = lines
    .filter((item) => {
      let isFiltered = true;

      if (filterValue.mood.length > 0 && isFiltered) {
        isFiltered = filterValue.mood.indexOf(item.trackerItem.id) !== -1;
      }

      if (filterValue.factors.length > 0 && isFiltered) {
        isFiltered = filterValue.factors.indexOf(item.trackerTrigger.id) !== -1;
      }

      return isFiltered;
    })
    .sort((a: MoodLine, b: MoodLine) => {
      const isAsc = sortValue.order === 'asc';

      if (sortValue.column === 'name') {
        return Comparator.orderWithDirection(
          isAsc,
          a.trackerItem.label.toLowerCase(),
          b.trackerItem.label.toLowerCase(),
        );
      }

      if (sortValue.column === 'factors') {
        return Comparator.orderWithDirection(
          isAsc,
          a.trackerTrigger.text.toLowerCase(),
          b.trackerTrigger.text.toLowerCase(),
        );
      }

      if (sortValue.column === 'severity') {
        return Comparator.orderWithDirection(isAsc, a.value, b.value);
      }

      if (sortValue.column === 'createdAt') {
        return Comparator.orderDates(isAsc, a.createdAt, b.createdAt);
      }

      return 0;
    });

  return (
    <>
      <WidgetHeader title="Mood">
        {lines && lines.length > 0 && (
          <HStack marginLeft="35px" alignItems="start" gap="10px">
            <Filter
              title="Mood"
              items={filterMood}
              value={filterValue.mood}
              onChange={(id) => applyFilter('mood', id)}
              onReset={() => resetFilter('mood')}
            />
            <Filter
              title="Factors"
              items={filterFactors}
              value={filterValue.factors}
              onChange={(id) => applyFilter('factors', id)}
              onReset={() => resetFilter('factors')}
            />
          </HStack>
        )}
      </WidgetHeader>

      <VStack width="100%" gap="15px" alignItems="start">
        {store.loading && (
          <>
            <Table
              width="calc(100% + 48px)"
              maxWidth="calc(100% + 48px)"
              marginLeft="-24px"
            >
              <TableContainer>
                <TableHeader>
                  <TableHeaderCell width="20%" flexShrink={0}>
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                  <TableHeaderCell width="20%" flexShrink={0}>
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                  <TableHeaderCell width="10%" flexShrink={0}>
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                  <TableHeaderCell width="100%">
                    <Skeleton height="16px" width="100px" />
                  </TableHeaderCell>
                </TableHeader>
                <TableBody>
                  {Array.from(Array(3)).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell width="20%" flexShrink={0}>
                        <Skeleton height="24px" width="80%" />
                      </TableCell>
                      <TableCell width="20%" flexShrink={0}>
                        <Skeleton height="24px" width="50%" />
                      </TableCell>
                      <TableCell width="10%" flexShrink={0}>
                        <Skeleton height="24px" width="50%" />
                      </TableCell>
                      <TableCell width="100%">
                        <Skeleton height="24px" width="20%" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Table>
          </>
        )}

        {!store.loading && sortedTable.length === 0 && <WidgetPlaceholder />}

        {!store.loading && sortedTable.length > 0 && (
          <>
            <Table
              width="calc(100% + 48px)"
              maxWidth="calc(100% + 48px)"
              marginLeft="-24px"
            >
              <TableContainer minWidth="870px">
                <TableHeader pb="25px">
                  <TableHeaderCell
                    label="Mood"
                    width="20%"
                    sortColumn="name"
                    sortValue={sortValue}
                    onSort={applySort}
                    flexShrink={0}
                    variant="simple"
                  />
                  <TableHeaderCell
                    label="Factors"
                    width="20%"
                    sortColumn="factors"
                    sortValue={sortValue}
                    onSort={applySort}
                    flexShrink={0}
                    variant="simple"
                  />
                  <TableHeaderCell
                    label="Severity"
                    width="10%"
                    sortColumn="severity"
                    sortValue={sortValue}
                    onSort={applySort}
                    flexShrink={0}
                    variant="simple"
                  />
                  <TableHeaderCell
                    label="Date submitted"
                    width="100%"
                    sortColumn="createdAt"
                    sortValue={sortValue}
                    onSort={applySort}
                    variant="simple"
                  />
                </TableHeader>
                <TableBody gap="25px">
                  {sortedTable.slice(0, visibleCount).map((line, index) => (
                    <TableRow key={index}>
                      <TableCell width="20%" flexShrink={0} variant="simple">
                        <Text variant="medium">{line.trackerItem.label}</Text>
                      </TableCell>
                      <TableCell width="20%" flexShrink={0} variant="simple">
                        <Text variant="medium">{line.trackerTrigger.text}</Text>
                      </TableCell>
                      <TableCell width="10%" flexShrink={0} variant="simple">
                        <Text variant="medium">
                          {line.value}/{line.trackerItem.max}
                        </Text>
                      </TableCell>
                      <TableCell width="100%" variant="simple">
                        <Text variant="medium">
                          {dayjs(line.createdAt).format('MMM D, YYYY, h:mm A')}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Table>

            {visibleCount < sortedTable.length && (
              <Button
                size="xs"
                colorScheme="purple"
                variant="outline"
                height="32px"
                width="100%"
                fontSize="14px"
                lineHeight="20px"
                margin="15px 0"
                onClick={() => setVisibleCount(visibleCount + 10)}
              >
                Load more
              </Button>
            )}
          </>
        )}
      </VStack>
    </>
  );
}
