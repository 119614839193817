import { Box, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import HomeScreenBackground from '../components/HomeScreenBackground';
import PhoneFrame from '../components/PhoneFrame';
import NightStreetIcon from '../components/NightStreetIcon';
import { FlowType } from '../../../../__generated__/graphql';
import AppFooter from '../components/AppFooter';

interface HomeScreenProps {
  type: FlowType;
  title: string;
  description?: string | null;
  providerFirstName?: string | null;
  providerLastName?: string | null;
}

export default function HomeScreen({
  providerFirstName,
  providerLastName,
  type,
  title,
  description,
}: HomeScreenProps) {
  return (
    <PhoneFrame appPadding>
      <HomeScreenBackground />
      <Box position="absolute" top={0} left={0} right={0}>
        <HStack height="100%" marginBottom="8px">
          <Box
            fontWeight="600"
            fontSize="28px"
            lineHeight="42px"
            color="#FFFFFF"
            maxWidth="280px"
          >
            Good evening, Victoria!
          </Box>
          <Spacer />
          <Box
            width="24px"
            height="24px"
            alignSelf="flex-start"
            marginTop="6px"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.75 6C8.75 4.20507 10.2051 2.75 12 2.75C13.7949 2.75 15.25 4.20507 15.25 6C15.25 7.79493 13.7949 9.25 12 9.25C10.2051 9.25 8.75 7.79493 8.75 6ZM12 1.25C9.37665 1.25 7.25 3.37665 7.25 6C7.25 8.62335 9.37665 10.75 12 10.75C14.6234 10.75 16.75 8.62335 16.75 6C16.75 3.37665 14.6234 1.25 12 1.25ZM8.8 13.25C5.73482 13.25 3.25 15.7348 3.25 18.8C3.25 20.9815 5.01848 22.75 7.2 22.75L16.8 22.75C18.9815 22.75 20.75 20.9815 20.75 18.8C20.75 15.7348 18.2652 13.25 15.2 13.25H8.8ZM4.75 18.8C4.75 16.5632 6.56325 14.75 8.8 14.75H15.2C17.4368 14.75 19.25 16.5632 19.25 18.8C19.25 20.1531 18.1531 21.25 16.8 21.25L7.2 21.25C5.8469 21.25 4.75 20.1531 4.75 18.8Z"
                fill="#E8EAED"
              />
            </svg>
          </Box>
        </HStack>
        <Box maxWidth="280px">
          Get ready for your next session with {providerFirstName ?? 'Patricia'}{' '}
          {providerLastName ?? 'Alvarado'}
        </Box>
      </Box>
      <Box marginTop="-24px">
        <Text
          fontWeight="600"
          fontSize="20px"
          lineHeight="30px"
          marginBottom="8px"
        >
          Complete activities
        </Text>
        <Flex backgroundColor="#120F24" borderRadius="20px" padding="12px">
          <Box marginRight="18px">
            <NightStreetIcon />
          </Box>
          <VStack alignItems="flex-start">
            <Text color="#7F8294" fontSize="12px" lineHeight="18px">
              {type === FlowType.Journal ? 'Journaling' : 'Checklist'}
            </Text>
            <Text fontWeight="500" fontSize="18px" lineHeight="27px">
              {title}
            </Text>
            <Text color="#CDCED4" fontSize="16px" lineHeight="24px">
              {description}
            </Text>
          </VStack>
        </Flex>
      </Box>
      <Box position="absolute" bottom="-35px" left="-25px" right="-25px">
        <AppFooter />
      </Box>
    </PhoneFrame>
  );
}
