import { gql } from '../../../__generated__/gql';

export const RESEND_CLIENT_INVITE = gql(/* GraphQL */ `
  mutation ResendClientInvite($clientId: String!) {
    resendClientInvite(clientId: $clientId) {
      id
      firstName
    }
  }
`);
