import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Datum, Point } from '@nivo/line';
import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IAssessment, IAssessmentResult } from '@app/store/schema';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { ScoreMovement } from '@app/layout/widget/ScoreMovement';
import { badgeColor } from '@app/utils/badgeColor';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';

interface AssessmentResultsModalProps {
  assessment?: IAssessment;
  point?: Point;
  isOpen: boolean;
  onClose(): void;
}

export function AssessmentResultsModal(props: AssessmentResultsModalProps) {
  const { assessment, point, isOpen, onClose } = props;
  const { setPeriod } = useContext(WidgetsContext);

  if (!assessment || !point) {
    return <></>;
  }

  const { badge, scoreMovement, dataPoint, input } = (point.data as Datum)
    ?.item as IAssessmentResult;

  const onDetails = () => {
    setPeriod({
      from: dayjs(dataPoint.timestamp).startOf('day'),
      to: dayjs(dataPoint.timestamp).endOf('day'),
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxHeight="80%" overflow="hidden" maxWidth="1041px">
        <ModalHeader>{assessment.nameFull}</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom="20px" overflow="auto">
          <VStack gap="20px" alignItems="start">
            <Table width="auto" minWidth="420px">
              <TableContainer>
                <TableHeader gap="50px">
                  <TableHeaderCell
                    width="50%"
                    padding={0}
                    label="Final score"
                  />
                  <TableHeaderCell
                    width="50%"
                    padding={0}
                    label="Date submitted"
                  />
                </TableHeader>

                <TableBody paddingTop="20px">
                  <TableRow alignItems="start" gap="50px">
                    <TableCell width="50%" padding={0} minHeight="auto">
                      <VStack alignItems="start" gap={0}>
                        <Text
                          variant="semibold"
                          color={badgeColor(badge) + '.600'}
                          as="span"
                        >
                          {badge.text}
                        </Text>

                        {scoreMovement.length > 0 &&
                          scoreMovement.map((item, index) => (
                            <ScoreMovement
                              scoreMovement={item}
                              key={index}
                              size="sm"
                            />
                          ))}
                      </VStack>
                    </TableCell>
                    <TableCell width="50%" padding={0} minHeight="auto">
                      <VStack alignItems="start">
                        <Text variant="medium">
                          {dayjs(dataPoint.timestamp).format(
                            'MMM D, YYYY, h:mm A',
                          )}
                        </Text>

                        <Button
                          size="xs"
                          colorScheme="purple"
                          variant="outline"
                          onClick={onDetails}
                        >
                          See all activities on this day
                        </Button>
                      </VStack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>
            </Table>

            <Divider />

            <Heading size="sm" fontWeight="500">
              {assessment.prompt}
            </Heading>

            <Table
              width="calc(100% + 48px)"
              maxWidth="calc(100% + 48px)"
              marginLeft="-24px"
            >
              <TableContainer>
                <TableHeader>
                  <TableHeaderCell
                    label="Final score"
                    width="100%"
                    paddingTop={0}
                    paddingBottom={0}
                  />
                  <TableHeaderCell
                    label="Response"
                    width="17%"
                    flexShrink={0}
                    paddingTop={0}
                    paddingBottom={0}
                  />
                  <TableHeaderCell
                    label="Score"
                    width="60px"
                    flexShrink={0}
                    paddingTop={0}
                    paddingBottom={0}
                  />
                  <TableHeaderCell
                    label="Since previous"
                    width="105px"
                    flexShrink={0}
                    paddingTop={0}
                    paddingBottom={0}
                  />
                  <TableHeaderCell
                    label="Since baseline"
                    width="105px"
                    flexShrink={0}
                    paddingTop={0}
                    paddingBottom={0}
                  />
                </TableHeader>
                <TableBody gap="20px" paddingTop="20px">
                  {assessment.questions &&
                    assessment.questions.map((question, index) => {
                      const questionInput = input?.find(
                        (item) => item.inputId === question.id,
                      );

                      const answer = question.answers.find(
                        (item) => item.id === questionInput?.value,
                      );

                      return (
                        <TableRow alignItems="start" key={index}>
                          <TableCell width="100%" minHeight="auto">
                            <Text variant="medium" color="gray.500">
                              {index + 1} – {question.text}
                            </Text>
                          </TableCell>
                          <TableCell
                            width="17%"
                            flexShrink={0}
                            minHeight="auto"
                          >
                            <Text variant="medium">{answer?.text || ''}</Text>
                          </TableCell>
                          <TableCell
                            width="60px"
                            flexShrink={0}
                            minHeight="auto"
                          >
                            <Text variant="medium">
                              {questionInput?.score}/{questionInput?.max}
                            </Text>
                          </TableCell>
                          <TableCell
                            width="105px"
                            flexShrink={0}
                            minHeight="auto"
                          >
                            {questionInput?.scoreMovement[0] && (
                              <ScoreMovement
                                scoreMovement={questionInput?.scoreMovement[0]}
                                fontWeight="500"
                              />
                            )}
                          </TableCell>
                          <TableCell
                            width="105px"
                            flexShrink={0}
                            minHeight="auto"
                          >
                            {questionInput?.scoreMovement[1] && (
                              <ScoreMovement
                                scoreMovement={questionInput?.scoreMovement[1]}
                                fontWeight="500"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </TableContainer>
            </Table>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
