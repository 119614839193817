import { extendTheme } from '@chakra-ui/react';
import { toastTheme } from '@app/layout/Toast';
import { tableHeaderTheme } from '@app/layout/table/TableHeader';
import { tableHeaderCellTheme } from '@app/layout/table/TableHeaderCell';
import { tableRowTheme } from '@app/layout/table/TableRow';
import { tableCellTheme } from '@app/layout/table/TableCell';
import { widgetTheme } from '@app/layout/widget/Widget';
import { widgetHeaderTheme } from '@app/layout/widget/WidgetHeader';
import { scoreMovementTheme } from '@app/layout/widget/ScoreMovement';

export const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  styles: {
    global: {
      body: {
        color: 'gray.900',
        bg: 'gray.50',
      },
      '#root': {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minHeight: 0,
      },
    },
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: '18px',
        lineHeight: '28px',
        marginBottom: '15px',
      },
    },
    Input: {
      sizes: {
        lg: {
          field: {
            height: '48px',
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'purple.500',
      },
    },
    Button: {
      sizes: {
        lg: {
          height: '55px',
          borderRadius: 100,
        },
        md: {
          height: '48px',
          borderRadius: 100,
          fontSize: '18px',
          lineHeight: '28px',
          paddingLeft: '24px',
          paddingRight: '24px',
        },
        sm: {
          height: '40px',
          borderRadius: 100,
          fontSize: '16px',
          lineHeight: '24px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
        xs: {
          height: '24px',
          borderRadius: 100,
          fontSize: '12px',
          lineHeight: '16px',
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
      variants: {
        primary: {
          bg: 'purple.900',
          color: 'white',
          _hover: {
            bg: 'purple.800',
          },
          _active: {
            bg: 'purple.700',
          },
          '&:hover[disabled]': {
            bg: 'purple.900',
          },
        },
        select: {
          background: 'gray.200',
          borderRadius: 6,
          fontWeight: '400',
          justifyContent: 'space-between',
          overflow: 'hidden',
          '& span': {
            flexGrow: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ':first-letter': {
              textTransform: 'capitalize',
            },
          },
        },
        formSelect: {
          border: '1px solid',
          borderColor: 'gray.200',
          borderRadius: 6,
          display: 'grid',
          gridTemplateColumns: '20fr 1fr',
          fontWeight: '400',
          _hover: {
            borderColor: 'gray.300',
          },
          _expanded: {
            borderColor: 'purple.500',
            boxShadow: '0 0 0 1px #805AD5',
          },
          '&.invalid:not([aria-expanded="true"])': {
            borderColor: 'red.500',
            boxShadow: '0 0 0 1px #E53E3E',
          },
        },
      },
    },
    Text: {
      sizes: {
        lg: {
          fontSize: '18px',
          lineHeight: '28px',
        },
        md: {
          fontSize: '16px',
          lineHeight: '24px',
        },
        sm: {
          fontSize: '14px',
          lineHeight: '20px',
        },
        xs: {
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
      variants: {
        bold: {
          fontWeight: '700',
        },
        semibold: {
          fontWeight: '600',
        },
        medium: {
          fontWeight: '500',
        },
        mediumSemibold: {
          fontWeight: '500',
          _after: {
            color: 'transparent',
            content: 'attr(aria-label)',
            display: 'block',
            fontWeight: '600',
            height: 0,
            overflow: 'hidden',
            visibility: 'hidden',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: '600',
      },
      sizes: {
        lg: {
          fontSize: '30px',
          lineHeight: '36px',
        },
        md: {
          fontSize: '24px',
          lineHeight: '32px',
        },
        sm: {
          fontSize: '20px',
          lineHeight: '28px',
        },
      },
      variants: {
        widgetPlaceholder: {
          color: 'gray.300',
          fontWeight: 400,
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'purple.600',
      },
      variants: {
        text: {
          color: 'gray.900',
          textDecoration: 'none',
          _hover: {
            textDecoration: 'underline',
          },
        },
        textUnderline: {
          color: 'gray.900',
          textDecoration: 'underline',
          _hover: {
            textDecoration: 'none',
          },
        },
        sidebar: {
          alignItems: 'center',
          borderRadius: '8px',
          color: 'gray.800',
          display: 'flex',
          height: '50px',
          fontWeight: '500',
          flexShrink: 0,
          padding: '0 15px',
          width: '100%',
          _hover: {
            background: 'gray.100',
            textDecoration: 'none',
          },
          '&.active': {
            background: 'gray.100',
          },
        },
        pill: {
          borderRadius: '100px',
          color: 'gray.800',
          fontSize: '18px',
          lineHeight: '28px',
          fontWeight: '500',
          padding: '12px 16px',
          _hover: {
            background: 'gray.200',
            textDecoration: 'none',
          },
          _after: {
            color: 'transparent',
            content: 'attr(aria-label)',
            display: 'block',
            fontWeight: '600',
            height: 0,
            overflow: 'hidden',
            visibility: 'hidden',
          },
          '&.active': {
            background: 'gray.200',
            fontWeight: '600',
          },
        },
        tab: {
          color: 'gray.500',
          padding: '8px 16px',
          borderBottom: '1px solid',
          borderBottomColor: 'transparent',
          fontWeight: '500',
          textDecoration: 'none',
          _hover: {
            color: 'gray.900',
            textDecoration: 'none',
          },
          '&.active': {
            borderBottomColor: 'gray.900',
            color: 'gray.900',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '10px',
        },
        item: {
          borderRadius: '8px',
          padding: '6px 12px',
          _focus: {
            background: 'transparent',
          },
          _hover: {
            background: 'gray.100',
          },
          '&.active': {
            background: 'gray.100',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          minWidth: '516px',
        },
        header: {
          fontSize: '20px',
          lineHeight: '28px',
        },
        closeButton: {
          right: '16px',
          top: '14px',
        },
      },
    },
    Tabs: {
      variants: {
        pill: {
          root: {
            width: '100%',
          },
          tab: {
            borderRadius: '100px',
            color: 'gray.800',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: '500',
            padding: '12px 16px',
            _hover: {
              background: 'gray.200',
              textDecoration: 'none',
            },
            _after: {
              color: 'transparent',
              content: 'attr(aria-label)',
              display: 'block',
              fontWeight: '600',
              height: 0,
              overflow: 'hidden',
              visibility: 'hidden',
            },
            '&[tabindex="0"]': {
              background: 'gray.200',
              fontWeight: '600',
            },
          },
          tabpanels: {
            marginTop: '25px',
          },
          tabpanel: {
            padding: '0',
          },
        },
      },
    },
    Radio: {
      variants: {
        widget: {
          control: {
            height: '20px',
            width: '20px',
          },
          label: {
            marginLeft: '10px',
          },
        },
      },
    },
    Checkbox: {
      variants: {
        formSelect: {
          control: {
            borderColor: 'gray.400',
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderColor: 'gray.900',
        borderRadius: '4px',
        fontWeight: 400,
      },
    },
    Toast: toastTheme,
    TableHeader: tableHeaderTheme,
    TableHeaderCell: tableHeaderCellTheme,
    TableRow: tableRowTheme,
    TableCell: tableCellTheme,
    Widget: widgetTheme,
    WidgetHeader: widgetHeaderTheme,
    ScoreMovement: scoreMovementTheme,
  },
});
