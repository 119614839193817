import React, { useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { IClient } from '@app/store/schema';
import {
  compose,
  emailValidator,
  getErrors,
  minLength,
  phoneValidator,
  requiredValidator,
} from '@app/utils/validators';
import { FormInput } from '@app/layout/form/FormInput';
import { StoreContext } from '@app/store/StoreContext';

interface ClientModalProps {
  client?: IClient;
  isOpen: boolean;
  onClose(): void;
}

export function ClientModal(props: ClientModalProps) {
  const { client, isOpen, onClose } = props;
  const { updateClient } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  if (!client) {
    return <></>;
  }

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        firstName: compose([
          requiredValidator(values.firstName),
          minLength(values.firstName, 2),
        ]),
        lastName: compose([
          requiredValidator(values.lastName),
          minLength(values.lastName, 2),
        ]),
        email: emailValidator(values.email),
        phone: phoneValidator(values.phone),
      }),
    initialValues: {
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      email: client?.user.email || '',
      phone: client?.user.phone || '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await updateClient(client.id, value);

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();

        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const clearForm = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit client</ModalHeader>
        <ModalCloseButton />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <Box p="8px 0">
                <Grid
                  width="100%"
                  gridColumnGap="15px"
                  gap="20px"
                  templateColumns="repeat(2, 1fr)"
                >
                  <GridItem>
                    <FormInput
                      label="Client name*"
                      name="firstName"
                      placeholder="First name"
                      autoFocus={true}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInput
                      label="&nbsp;"
                      name="lastName"
                      placeholder="Last name"
                    />
                  </GridItem>
                  <GridItem>
                    <FormInput
                      label="Client contact info*"
                      name="email"
                      placeholder="Email"
                    />
                  </GridItem>
                  <GridItem>
                    <FormInput
                      label="&nbsp;"
                      name="phone"
                      placeholder="Phone number"
                    />
                  </GridItem>
                </Grid>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button size="sm" mr="12px" onClick={clearForm}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                isLoading={isLoading}
              >
                Save changes
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
}
