import { TFlowType } from '@app/store/schema';

const flowTypeNames: Record<string, string> = {
  JOURNAL: 'Journaling',
  ASSESSMENT: '',
  TRACKER: 'Tracker',
  CHECKLIST: 'Checklist',
};

export const flowTypeName = (type: TFlowType) => flowTypeNames[type];
