import { gql } from 'graphql-request';

export const ResendProviderInviteMutation = gql`
  mutation ($providerId: String!) {
    resendProviderInvite(providerId: $providerId) {
      id
      firstName
      lastName
      credentials
      status
      invitedAt
      user {
        id
        email
        phone
        role
        activeAt
      }
      supervisors {
        id
      }
      subordinates {
        id
      }
    }
  }
`;
