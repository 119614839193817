import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  NavLink as ReactRouterLink,
  Outlet,
  useParams,
} from 'react-router-dom';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { StoreContext } from '@app/store/StoreContext';
import { Breadcrumbs } from '@app/layout/breadcrumbs/Breadcrumbs';
import { ClientModal } from '@app/features/clients/modals/ClientModal';
import { ReassignClientModal } from '@app/features/clients/modals/ReassignClientModal';
import { ReactivateClientModal } from '@app/features/clients/modals/ReactivateClientModal';
import { DischargeClientModal } from '@app/features/clients/modals/DischargeClientModal';
import { providerFullName, userFullName } from '@app/utils/userFullName';

export function ClientDetails() {
  const params = useParams();
  const {
    getUser,
    isAdminOrSupervisor,
    getProviderById,
    getClientById,
    updateClient,
  } = useContext(StoreContext);
  const [isHighRiskLocked, setIsHighRiskLocked] = useState(false);

  useEffect(() => {
    if (isHighRiskLocked) {
      updateHighRisk();
    }
  }, [isHighRiskLocked]);

  const client = getClientById(params.id as string);

  if (!client) {
    return <Navigate to="/clients" replace />;
  }

  const updateHighRisk = async () => {
    await updateClient(client.id!, {
      isHighRisk: !client?.isHighRisk,
    });

    setIsHighRiskLocked(false);
  };

  const provider = getProviderById(client.providers[0].id);
  const dischargedBy = client.dischargedByProvider
    ? getProviderById(client.dischargedByProvider.id)
    : null;

  const clientModal = useDisclosure();
  const reassignClientModal = useDisclosure();
  const reactivateClientModal = useDisclosure();
  const dischargeClientModal = useDisclosure();

  const editClient = () => {
    clientModal.onOpen();
  };

  const reassignClient = () => {
    reassignClientModal.onOpen();
  };

  const reactivateClient = () => {
    reactivateClientModal.onOpen();
  };

  const dischargeClient = () => {
    dischargeClientModal.onOpen();
  };

  const highRiskHandle = () => {
    if (!isHighRiskLocked) {
      setIsHighRiskLocked(true);
    }
  };

  return (
    <>
      <VStack gap={0} alignItems="start" width="100%">
        <Box bg="white" width="100%">
          <Box
            p="35px 50px"
            width="100%"
            borderBottom="1px solid"
            borderBottomColor="gray.200"
          >
            <Breadcrumbs
              items={[
                {
                  url: '/providers',
                  title: 'My team',
                },
                {
                  url: '/providers/' + provider?.id,
                  title: providerFullName(provider!),
                },
                {
                  url: '/clients/' + client.id,
                  title: userFullName(client!),
                },
              ]}
            />
          </Box>
        </Box>

        <Box bg="white" width="100%">
          {client.status === 'DEACTIVATED' && (
            <HStack gap="25px" p="15px 50px" width="100%" bg="purple.900">
              <Text color="white" size="sm">
                {userFullName(client!)} has been discharged on{' '}
                {dayjs(client.dischargedAt).format('MMM DD, YYYY')} by{' '}
                {providerFullName(dischargedBy!)}
              </Text>

              <Button
                size="xs"
                fontSize="14px"
                lineHeight="20px"
                height="32px"
                padding="0 12px"
                onClick={() => reactivateClient()}
              >
                Reactivate client
              </Button>
            </HStack>
          )}

          <Box
            p={client.status === 'DEACTIVATED' ? '18px 50px 0' : '26px 50px 0'}
            width="100%"
          >
            <HStack
              gap={0}
              justifyContent="space-between"
              width="100%"
              pb="17px"
            >
              <HStack>
                <Heading size="sm">{userFullName(client!)}</Heading>

                <Tooltip
                  label={
                    client.isHighRisk ? 'Remove High-Risk' : 'Mark as High-Risk'
                  }
                  placement="right"
                  hasArrow
                  bg="gray.900"
                >
                  <HStack
                    gap="8px"
                    bg={client.isHighRisk ? 'red.500' : 'gray.300'}
                    rounded="6px"
                    padding="0 8px"
                    cursor={isHighRiskLocked ? 'default' : 'pointer'}
                    onClick={highRiskHandle}
                  >
                    <Text color="white" size="sm" variant="medium">
                      High-risk
                    </Text>

                    <Icon
                      as={IoCheckmarkCircleSharp}
                      color="white"
                      boxSize="14px"
                    />
                  </HStack>
                </Tooltip>
              </HStack>

              <Menu isLazy>
                <MenuButton
                  as={Button}
                  size="sm"
                  variant="primary"
                  flexShrink={0}
                  rightIcon={<ChevronDownIcon />}
                >
                  More
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => editClient()}>Edit client</MenuItem>

                  {isAdminOrSupervisor(getUser()!) && (
                    <MenuItem onClick={() => reassignClient()}>
                      Reassign client
                    </MenuItem>
                  )}

                  {client.status === 'DEACTIVATED' && (
                    <MenuItem onClick={() => reactivateClient()}>
                      Reactivate client
                    </MenuItem>
                  )}

                  {client.status !== 'DEACTIVATED' && (
                    <MenuItem onClick={() => dischargeClient()}>
                      Discharge client
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </Box>

          <HStack gap={0} p="0 50px">
            <Link
              variant="tab"
              as={ReactRouterLink}
              to={`/clients/${client.id}/progress`}
            >
              Progress
            </Link>
            <Link
              variant="tab"
              as={ReactRouterLink}
              to={`/clients/${client.id}/homework`}
            >
              Manage homework
            </Link>
          </HStack>
        </Box>

        <Box p="35px 50px" width="100%">
          <Outlet />
        </Box>
      </VStack>

      <ClientModal
        client={client}
        isOpen={clientModal.isOpen}
        onClose={clientModal.onClose}
      />

      <ReassignClientModal
        client={client}
        isOpen={reassignClientModal.isOpen}
        onClose={reassignClientModal.onClose}
      />

      <ReactivateClientModal
        client={client}
        isOpen={reactivateClientModal.isOpen}
        onClose={reactivateClientModal.onClose}
      />

      <DischargeClientModal
        client={client}
        isOpen={dischargeClientModal.isOpen}
        onClose={dischargeClientModal.onClose}
      />
    </>
  );
}
