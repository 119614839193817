import React from 'react';
import { BiSortAlt2, BiSortDown } from 'react-icons/bi';
import {
  Box,
  defineStyleConfig,
  forwardRef,
  Icon,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';

export const tableHeaderCellTheme = defineStyleConfig({
  baseStyle: {
    alignItems: 'center',
    boxSizing: 'content-box',
    display: 'flex',
    gap: '6px',
  },
  variants: {
    lg: {
      padding: '12px 30px',
    },
    md: {
      padding: '12px 24px',
    },
    simple: {
      padding: '0 24px',
    },
  },
  defaultProps: {
    variant: 'md',
  },
});

export const TableHeaderCell = forwardRef((props, ref) => {
  const { label, sortColumn, sortValue, onSort, variant, ...rest } = props;

  const styles = useStyleConfig('TableHeaderCell', { variant });
  const isSorted = sortValue && sortColumn && sortValue.column === sortColumn;

  return (
    <Box ref={ref} __css={styles} {...rest} role="columnheader">
      {label && (
        <Text
          color="gray.500"
          size="xs"
          fontWeight={700}
          textTransform="uppercase"
          letterSpacing="0.05em"
          userSelect="none"
        >
          {label}
        </Text>
      )}

      {sortColumn && (
        <Box
          width="24px"
          height="24px"
          background={isSorted ? 'gray.200' : 'transparent'}
          borderColor={isSorted ? 'gray.200' : 'transparent'}
          borderWidth="2px"
          borderRadius="2px"
          cursor="pointer"
          onClick={() => onSort(sortColumn)}
        >
          {!isSorted && (
            <Icon as={BiSortAlt2} width="20px" height="20px" color="gray.500" />
          )}
          {isSorted && (
            <Icon
              as={BiSortDown}
              width="20px"
              height="20px"
              color="gray.500"
              transform={
                sortValue && sortValue.order === 'asc'
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)'
              }
            />
          )}
        </Box>
      )}

      {props.children}
    </Box>
  );
});

TableHeaderCell.displayName = 'TableHeaderCell';
