import React, { useContext, useMemo } from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { Box, Link, Text } from '@chakra-ui/react';
import { providerFullName } from '@app/utils/userFullName';
import { StoreContext } from '@app/store/StoreContext';

interface ProviderSupervisorProps {
  id: string;
}

export function ProviderSupervisorContent(props: ProviderSupervisorProps) {
  const { id } = props;
  const { getProviderById } = useContext(StoreContext);

  const supervisor = useMemo(() => getProviderById(id), [id]);

  const userNameFormatted = useMemo(
    () => providerFullName(supervisor!),
    [supervisor],
  );

  return (
    <Box p="16px 0">
      <Text variant="medium" size="sm">
        Under supervision of{' '}
        <Link
          as={ReactRouterLink}
          to={'/providers/' + supervisor?.id}
          variant="textUnderline"
        >
          {userNameFormatted}
        </Link>
      </Text>
    </Box>
  );
}

export const ProviderSupervisor = React.memo(ProviderSupervisorContent);
