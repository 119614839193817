import {
  Flex,
  Spacer,
  Editable,
  EditableTextarea,
  EditablePreview,
  Box,
} from '@chakra-ui/react';
import { ScreenFragmentFragment } from '../../../../__generated__/graphql';
import { useFragment } from '../../../../__generated__/fragment-masking';
import { InputFragment } from '../fragments/common';
import PhoneFrame from '../components/PhoneFrame';
import { FlowEditorScreenPropertyActions } from '../FlowEditorApp';
import { EditableTitle } from '../components/EditableTitle';
import EditableCta from '../components/EditableCta';
import { useState } from 'react';

interface PreviewScreenProps {
  screen: ScreenFragmentFragment;
}

export default function PreviewScreen({
  screen,
  onChangeScreenProperty,
  onChangeScreenCtaLabel,
}: PreviewScreenProps & FlowEditorScreenPropertyActions) {
  const cta = useFragment(InputFragment, screen.cta);
  const [subtitleState, setSubtitleState] = useState(screen.subtitle ?? '');

  return (
    <PhoneFrame appPadding>
      <Flex direction="column" height="100%">
        <EditableTitle
          initialValue={screen.title ?? ''}
          onSubmit={(val) => onChangeScreenProperty(screen.id, 'title', val)}
        />
        <Box height="12px" />
        <Editable
          value={subtitleState}
          onChange={setSubtitleState}
          onSubmit={(val) => onChangeScreenProperty(screen.id, 'subtitle', val)}
        >
          <EditablePreview />
          <EditableTextarea />
        </Editable>
        <Spacer />
        <EditableCta
          value={cta?.label ?? ''}
          onChange={(val) => onChangeScreenCtaLabel(screen.id, val)}
        />
      </Flex>
    </PhoneFrame>
  );
}
