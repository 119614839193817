import React, { useCallback, useState } from 'react';
import { FiMail } from 'react-icons/fi';
import {
  Box,
  Button,
  HStack,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { PageHeader } from '@app/layout/PageHeader';
import { VideoModal } from '@app/features/help/modals/VideoModal';
import { ContactModal } from '@app/features/help/modals/ContactModal';
import { IHelpVideo } from '@app/store/schema';

export function Help() {
  const [currentVideo, setCurrentVideo] = useState<IHelpVideo | undefined>(
    undefined,
  );

  const videos: IHelpVideo[] = [
    {
      title: 'How to: Invite a New Client',
      time: '0:40',
      videoId: 'kxmtI10SrGk',
    },
    {
      title: 'How to: Assign Homework To Clients',
      time: '1:52',
      videoId: 'OKneKg88dv0',
    },
  ];

  const videoModal = useDisclosure();
  const contactModal = useDisclosure();

  const openVideo = useCallback((video: IHelpVideo) => {
    setCurrentVideo(video);
    videoModal.onOpen();
  }, []);

  const contactUs = useCallback(() => {
    contactModal.onOpen();
  }, []);

  return (
    <>
      <VStack gap="40px" alignItems="start" width="100%" p="35px 50px">
        <HStack gap="0" width="100%" alignItems="start">
          <PageHeader
            title="Help"
            breadcrumbs={[
              {
                url: '/help',
                title: 'Help',
              },
            ]}
          />

          <Button
            size="md"
            variant="primary"
            flexShrink={0}
            leftIcon={<FiMail />}
            onClick={contactUs}
          >
            Contact us
          </Button>
        </HStack>

        <VStack gap="35px" alignItems="start" width="100%">
          <Tabs variant="pill">
            <TabList>
              <Tab aria-label="Video: Learn Basics">Video: Learn Basics</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <HStack width="100%" gap="25px 30px" flexWrap="wrap">
                  {videos &&
                    videos.map((item, index) => (
                      <VStack
                        key={index}
                        alignItems="flex-start"
                        backgroundColor="white"
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="12px"
                        cursor="pointer"
                        height="285px"
                        flexShrink={0}
                        gap="16px"
                        padding="20px"
                        width="344px"
                        onClick={() => openVideo(item)}
                      >
                        <Image
                          src={`https://i3.ytimg.com/vi/${item.videoId}/maxresdefault.jpg`}
                          maxHeight="100%"
                        />

                        <Box>
                          <Text variant="semibold">{item.title}</Text>
                          <Text size="xs" color="gray.500">
                            {item.time}
                          </Text>
                        </Box>
                      </VStack>
                    ))}
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </VStack>

      <VideoModal
        video={currentVideo}
        isOpen={videoModal.isOpen}
        onClose={videoModal.onClose}
      />

      <ContactModal
        isOpen={contactModal.isOpen}
        onClose={contactModal.onClose}
      />
    </>
  );
}
