import { gql } from 'graphql-request';

export const AcceptInvitationMutation = gql`
  mutation ($token: String!, $password: String!) {
    acceptInvitation(data: { token: $token, password: $password }) {
      id
      firstName
      lastName
      status
      credentials
      user {
        id
        email
        phone
        role
        activeAt
      }
    }
  }
`;
