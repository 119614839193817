import { IconId } from '../../../../__generated__/graphql';

export default function IconAsset({ iconId }: { iconId: IconId }) {
  switch (iconId) {
    case IconId.ArrowLeft:
      return (
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_4730_11144)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.172 7.11243C13.478 7.3916 13.4998 7.86597 13.2206 8.17198L6.6994 15.3202L28.6667 15.3205C29.0809 15.3205 29.4167 15.6563 29.4167 16.0705C29.4167 16.4847 29.0809 16.8205 28.6667 16.8205L6.69944 16.8202L13.2206 23.9684C13.4998 24.2744 13.478 24.7488 13.172 25.028C12.866 25.3072 12.3916 25.2854 12.1125 24.9794L4.44593 16.5757C4.18469 16.2893 4.18469 15.8511 4.44593 15.5647L12.1125 7.16104C12.3916 6.85503 12.866 6.83327 13.172 7.11243Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_4730_11144"
              x="-3"
              y="-2"
              width="38"
              height="38"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4730_11144"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4730_11144"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );

    case IconId.Close:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2087 5.965C22.5015 6.2579 22.5015 6.73277 22.2087 7.02566L15.1476 14.0867L22.2087 21.1478C22.5015 21.4407 22.5015 21.9156 22.2087 22.2085C21.9158 22.5014 21.4409 22.5014 21.148 22.2085L14.0869 15.1474L7.02583 22.2085C6.73294 22.5014 6.25807 22.5014 5.96517 22.2085C5.67228 21.9156 5.67228 21.4407 5.96517 21.1478L13.0263 14.0867L5.96517 7.02566C5.67228 6.73277 5.67228 6.2579 5.96517 5.965C6.25807 5.67211 6.73294 5.67211 7.02583 5.965L14.0869 13.0261L21.148 5.965C21.4409 5.67211 21.9158 5.67211 22.2087 5.965Z"
            fill="#E8EAED"
          />
        </svg>
      );
    case IconId.ArrowDownSmall:
    case IconId.ArrowRight:
    case IconId.ArrowRightSmall:
    case IconId.ArrowUpSmall:
    case IconId.CircleEmpty:
    case IconId.CircleFull:
    case IconId.CircleHalf:
    case IconId.NightStreet:
    case IconId.Particles:
      return <div>icon missing</div>;
  }
}
