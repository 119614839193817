import { HStack } from '@chakra-ui/react';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import ScreenThumbnail from './ScreenThumbnail';

import { ScreenFragmentFragment } from '../../../__generated__/graphql';
import ExpandableSpacer from './components/ExpandableSpacer';
import { FlowEditorScreenActions } from './FlowEditorApp';
import { Fragment } from 'react/jsx-runtime';
import { ALWAYS_FIRST_TYPES, SORTABLE_TYPES } from './FlowEditorSimpleApp';

interface ScreenNavigatorProps {
  screen: ScreenFragmentFragment;
  screens: readonly ScreenFragmentFragment[];
  onScreenChange: (screenId: string) => void;
}

export default function ScreenNavigator({
  screen,
  screens,
  onScreenChange,
  onAddScreen,
  onRemoveScreen,
  onSwapScreen,
}: ScreenNavigatorProps & FlowEditorScreenActions) {
  const alwaysFirstScreens = screens.filter((x) =>
    ALWAYS_FIRST_TYPES.includes(x.screenType),
  );
  const sortableScreens = screens.filter(
    (x) =>
      SORTABLE_TYPES.includes(x.screenType) &&
      !ALWAYS_FIRST_TYPES.includes(x.screenType),
  );

  const nonSortableScreens = screens.filter(
    (x) =>
      !SORTABLE_TYPES.includes(x.screenType) &&
      !ALWAYS_FIRST_TYPES.includes(x.screenType),
  );

  return (
    <HStack spacing="16px" height="100%" padding="16px 16px">
      <DndContext
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={({ active, over }) =>
          over && onSwapScreen(active.id as string, over.id as string)
        }
      >
        {alwaysFirstScreens.map((x) => (
          <ScreenThumbnail
            key={`${x.id}ScreenThumbnail`}
            selected={x.id === screen.id}
            screen={x}
            onClick={onScreenChange}
          />
        ))}
        {!!sortableScreens.length && (
          <SortableContext
            items={sortableScreens.map((x) => x.id)}
            strategy={horizontalListSortingStrategy}
          >
            {sortableScreens.map((x) => (
              <Fragment key={`${x.id}ExpandableSpacerContainer`}>
                <ExpandableSpacer
                  key={`${x.id}ExpandableSpacer`}
                  onClick={() => onAddScreen(x.id, 'before')}
                />
                <ScreenThumbnail
                  key={`${x.id}ScreenThumbnail`}
                  selected={x.id === screen.id}
                  sortable
                  removable={sortableScreens.length > 1}
                  onRemove={onRemoveScreen}
                  screen={x}
                  onClick={onScreenChange}
                />
              </Fragment>
            ))}
            <ExpandableSpacer
              onClick={() => onAddScreen(sortableScreens.at(-1)!.id, 'after')}
            />
          </SortableContext>
        )}
        {/* {nonSortableScreens.map((x) => (
          <ScreenThumbnail
            key={`${x.id}ScreenThumbnail`}
            selected={x.id === screen.id}
            screen={x}
            onClick={onScreenChange}
          />
        ))} */}
      </DndContext>
    </HStack>
  );
}
