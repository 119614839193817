import {
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { ScreenFragmentFragment } from '../../../../__generated__/graphql';
import { useFragment } from '../../../../__generated__/fragment-masking';
import { InputFragment, InputInterfaceFragment } from '../fragments/common';
import PhoneFrame from '../components/PhoneFrame';
import DatePicker from '../components/DatePicker';
import { FlowEditorScreenPropertyActions } from '../FlowEditorApp';
import { EditableTitle } from '../components/EditableTitle';
import EditableCta from '../components/EditableCta';

interface ContainerScreenProps {
  screen: ScreenFragmentFragment;
}

export default function ContainerScreen({
  screen,
  onChangeScreenProperty,
  onChangeScreenInputProperty,
  onChangeScreenCtaLabel,
}: ContainerScreenProps & FlowEditorScreenPropertyActions) {
  const cta = useFragment(InputFragment, screen.cta);
  const inputs = useFragment(InputInterfaceFragment, screen.inputs);

  const firstInput = inputs?.[0];

  return (
    <PhoneFrame appPadding>
      <Flex direction="column" height="100%">
        <EditableTitle
          initialValue={screen.title ?? ''}
          onSubmit={(val) => onChangeScreenProperty(screen.id, 'title', val)}
        />
        {firstInput?.label && (
          <Box color="rgb(127, 130, 148)">
            <Editable
              value={firstInput?.label ?? ''}
              onChange={(val) =>
                onChangeScreenInputProperty(
                  screen.id,
                  firstInput.id,
                  'label',
                  val,
                )
              }
            >
              <EditablePreview />
              <EditableTextarea />
            </Editable>
          </Box>
        )}
        <Box>
          <DatePicker />
        </Box>
        <Spacer />
        <EditableCta
          value={cta?.label ?? ''}
          onChange={(val) => onChangeScreenCtaLabel(screen.id, val)}
        />
      </Flex>
    </PhoneFrame>
  );
}
