import React from 'react';
import { BoxProps, VStack } from '@chakra-ui/react';

export function TableContainer(props: BoxProps) {
  return (
    <VStack gap={0} width="100%" {...props}>
      {props.children}
    </VStack>
  );
}
