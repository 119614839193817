import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  NavLink as ReactRouterLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, Text, VStack, Heading, Box, Link } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { AuthForm } from './AuthForm';
import {
  compose,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { FormInput } from '@app/layout/form/FormInput';
import { FormPassword } from '@app/layout/form/FormPassword';
import { graphQLClient } from '@app/store/client';
import { AcceptInvitationQuery } from '@app/store/queries/provider/acceptInvitation';

interface AcceptInviteData {
  acceptInvitation: {
    id: string;
    user: {
      email: string;
    };
  };
}

export function AcceptInvite() {
  const params = useParams();
  const navigate = useNavigate();
  const { getUser, acceptInvitation } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');

  const loadData = async () => {
    const { data } = await graphQLClient.rawRequest<AcceptInviteData>(
      AcceptInvitationQuery,
      {
        token: params.token,
      },
    );

    if (data && data.acceptInvitation) {
      setEmail(data.acceptInvitation.user.email);
    } else {
      navigate('/', { replace: true });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        password: compose([
          requiredValidator(values.password),
          minLength(values.password, 10),
        ]),
        confirmPassword: compose([
          requiredValidator(values.confirmPassword),
          values.password.length > 0 &&
          values.password !== values.confirmPassword
            ? 'Passwords do not match.'
            : null,
        ]),
      }),
    initialValues: {
      email,
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const result = await acceptInvitation({
        token: params.token!,
        password: value.password,
      });

      if (result.errors) {
        setIsLoading(false);
        setErrors(result.errors as any);
      } else {
        navigate('/login');
      }
    },
  });

  if (getUser()) {
    return <Navigate to="/" replace />;
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <AuthForm>
      <VStack spacing="30px" alignItems="start">
        <Heading size="lg" as="h1">
          Create password
        </Heading>

        <FormikProvider value={formik}>
          <Box as="form" onSubmit={formik.handleSubmit} width="100%">
            <VStack spacing="30px" alignItems="start">
              <VStack spacing="20px" alignItems="start" width="100%">
                <Box width="100%">
                  {errors.length > 0 && (
                    <Text
                      color="red.500"
                      paddingLeft="17px"
                      marginBottom="10px"
                      marginTop="-10px"
                    >
                      {errors.map((error) => (error as any).message).join()}
                    </Text>
                  )}

                  <FormInput
                    name="email"
                    placeholder="Email*"
                    size="lg"
                    disabled={true}
                  />
                </Box>

                <FormPassword
                  name="password"
                  placeholder="Password*"
                  size="lg"
                  autoFocus={true}
                />

                <FormPassword
                  name="confirmPassword"
                  placeholder="Confirm password*"
                  size="lg"
                />
              </VStack>

              <VStack spacing="25px" alignItems="start" width="100%">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  width="full"
                  isLoading={isLoading}
                >
                  Accept invite
                </Button>

                <Box>
                  <Text variant="medium">
                    By logging in, you are agreeing to our{' '}
                    <Link>Terms Of Service</Link> and{' '}
                    <Link>Privacy Policy</Link>
                  </Text>
                </Box>
              </VStack>
            </VStack>
          </Box>
        </FormikProvider>

        <Box>
          <Text variant="medium">
            Already have an account?{' '}
            <Link as={ReactRouterLink} to="/login">
              Log in
            </Link>
          </Text>
        </Box>
      </VStack>
    </AuthForm>
  );
}
