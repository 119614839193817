import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box, Center, Flex, Spinner } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { Sidebar } from '@app/layout/Sidebar';

export function AppShell() {
  const {
    loadOrganization,
    getUser,
    isAdminOrSupervisor,
    isOrganizationLoading,
  } = useContext(StoreContext);
  const location = useLocation();

  if (!getUser()) {
    return <Navigate to="/login" replace />;
  }

  useEffect(() => {
    loadOrganization();
  }, []);

  if (isOrganizationLoading()) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (location.pathname === '/') {
    const url = isAdminOrSupervisor(getUser()!) ? '/providers' : '/clients';

    return <Navigate to={url} replace />;
  }

  return (
    <Flex h="100vh">
      <Sidebar />

      <Box overflow="auto" width="100%">
        <Outlet />
      </Box>
    </Flex>
  );
}
