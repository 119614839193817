import React from 'react';
import { CustomLayerProps, Point } from '@nivo/line';
import { CustomPoint } from '@app/features/clients/details/widgets/components/CustomPoint';

interface CustomPointsProps {
  layer: CustomLayerProps;
  onClick?: (points: Point[]) => void;
  tooltip?: React.FunctionComponent<{ points: Point[] }>;
}

export function CustomPoints(props: CustomPointsProps) {
  const { layer, tooltip, onClick } = props;
  const { points } = layer;

  return (
    <>
      {points.map((point, index) => (
        <CustomPoint
          point={point}
          index={index}
          layer={layer}
          key={index}
          tooltip={tooltip}
          onClick={onClick}
        />
      ))}
    </>
  );
}
