import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { IHelpVideo } from '@app/store/schema';

interface VideoModalProps {
  video?: IHelpVideo;
  isOpen: boolean;
  onClose(): void;
}

export function VideoModal(props: VideoModalProps) {
  const { video, isOpen, onClose } = props;

  if (!video) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="1130px">
        <ModalHeader>{video.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box paddingBottom="16px">
            <iframe
              src={`https://www.youtube.com/embed/${video.videoId}?autoplay=1`}
              allowFullScreen={true}
              allow="autoplay"
              height="612px"
              width="100%"
            ></iframe>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
