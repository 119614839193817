interface Ids {
  id: string;
}

export interface IUser {
  id: string;
  email: string;
  phone: string;
  role: TUserRole;
  activeAt?: string;
}

export type TUserStatus = 'ACTIVE' | 'INVITED' | 'DEACTIVATED';
export type TUserRole = 'ADMIN' | 'SUPERVISOR' | 'CLINICIAN';

export interface IAuthUser extends IProvider {}

export interface IProvider {
  id: string;
  firstName: string;
  lastName: string;
  status: TUserStatus;
  invitedAt: string;
  credentials: string;
  user: IUser;
  supervisors: Ids[];
  subordinates: Ids[];
}

export interface IClient {
  id: string;
  firstName: string;
  lastName: string;
  status: TUserStatus;
  user: IUser;
  isHighRisk: boolean;
  latestAssessmentResult: IAssessmentResult;
  providers: Ids[];
  dischargedAt?: string;
  dischargedByProvider?: Ids;
}

export interface IOrganization {
  id: string;
  domain: string;
  logo?: string | null;
  name: string;
  clients: IClient[];
  providers: IProvider[];
}

export type TAssetType = 'ICON' | 'PICTURE' | 'VIDEO';

export type TAssetIcon =
  | 'NIGHT_STREET'
  | 'PARTICLES'
  | 'ARROW_DOWN_SMALL'
  | 'ARROW_UP_SMALL'
  | 'ARROW_RIGHT'
  | 'ARROW_LEFT'
  | 'ARROW_EXTERNAL'
  | 'CHART_COLUMN'
  | 'CHART_COLUMN_FILLED'
  | 'CIRCLE_EMPTY'
  | 'CIRCLE_HALF'
  | 'CIRCLE_FULL'
  | 'CLOSE'
  | 'EYE'
  | 'EYE_SLASH'
  | 'HEART'
  | 'HEART_FILLED'
  | 'NOTE_TEXT'
  | 'NOTE_TEXT_FILLED'
  | 'USER';

export interface IAsset {
  id?: string;
  icon?: TAssetIcon;
  type: TAssetType;
  url?: string;
}

export interface IScoreMovement {
  asset: IAsset;
  text: string;
}

export interface IClientAssessmentResults {
  title: string;
  titleShort: string;
  titleFull: string;
  subtitle: string;
  chart: IChart;
  results: IAssessmentResult[];
}

export interface IChartDataPoint {
  label: string;
  timestamp: string;
  value: number;
}

export interface IAssessmentResult {
  id: string;
  title: string;
  titleShort: string;
  titleFull: string;
  type: string;
  badge: ICardBadge;
  date: string;
  dateShort: string;
  timestamp: string;
  scoreMovement: IScoreMovement[];
  dataPoint: IChartDataPoint;
  input?: IAssessmentInput[];
}

export interface IAssessmentInput {
  inputId: string;
  value: string;
  score: number;
  max: number;
  scoreMovement: IScoreMovement[];
}

export interface IChart {
  min: number;
  max: number;
  baseline: number;
}

export type TBadgeType = 'ERROR' | 'ALERT' | 'WARNING' | 'INFO';

export interface ICardBadge {
  text: string;
  status: TBadgeType;
}

export interface IAssessmentAnswer {
  id: string;
  text: string;
}

export interface IAssessmentQuestion {
  id: string;
  text: string;
  answers: IAssessmentAnswer[];
}

export interface IAssessment {
  id: string;
  name: string;
  nameShort: string;
  nameFull: string;
  description: string;
  prompt: string;
  questions: IAssessmentQuestion[];
}

export interface ISimpleWidgetTextRow {
  leftColumn: string;
  middleColumn: string;
  rightColumn: string;
}

export interface ISimpleWidget {
  title: string;
  subtitle: string;
  rows: ISimpleWidgetTextRow[];
  link?: string;
}

export type TFlowType = 'JOURNAL' | 'ASSESSMENT' | 'TRACKER' | 'CHECKLIST';

export interface IFlow {
  id: string;
  name: string;
  description: string;
  previewUrl?: string | null;
  type: TFlowType;
  assessment?: IAssessment;
}

export type TScheduleType = 'FREE' | 'DAILY' | 'CUSTOM';

export interface ISchedule {
  id: string;
  type: TScheduleType;
  rrule?: string;
  flowId: string;
}

export enum TSurveyOptions {
  WORSE = '4761e353-291a-4a42-9e24-b7f6434b70c4',
  SAME = '110f4715-459a-4006-9790-67591da9a947',
  BETTER = '4d125a1b-2643-47d2-a7e3-8990403c34ef',
}

export interface ITracker {
  id: string;
  prompt: string;
  items: ITrackerItem[];
}

export interface ITrackerItem {
  id: string;
  defaultValue: number;
  label: string;
  min: number;
  max: number;
  triggers: any[];
  values: any[];
  color?: string;
}

export interface ITrackerTrigger {
  id: string;
  text: string;
}

export interface ITrackerResult {
  id: string;
  createdAt: string;
  flowId: string;
  trackerResultInputs: ITrackerResultInput[];
}

export interface ITrackerResultInput {
  id: string;
  value: number;
  scoreMovement: IScoreMovement[];
  trackerItem: ITrackerItem;
  trackerTriggers: ITrackerTrigger[];
}

export interface IExtendedTrackerResultInput {
  id: string;
  value: number;
  createdAt: string;
  scoreMovement: IScoreMovement[];
  trackerItem: ITrackerItem;
  trackerTriggers: ITrackerTrigger[];
}

export interface IExercise {
  id: string;
  createdAt: string;
  flow: {
    id: string;
    name: string;
  };
  flowResultInputs: any[];
}

export interface IHelpVideo {
  title: string;
  time: string;
  videoId: string;
}
