import { Box } from '@chakra-ui/react';

export default function Button({
  children,
  small,
}: { small?: boolean } & React.PropsWithChildren) {
  return (
    <Box
      display="flex"
      borderRadius={100}
      backgroundColor="white"
      color="#151323"
      justifyContent="center"
      padding={small ? '1px 15px 1px 15px' : '15px 10px 15px 10px'}
      fontSize="18px"
    >
      {children}
    </Box>
  );
}
