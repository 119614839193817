import React from 'react';
import {
  Box,
  defineStyleConfig,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

export const tableCellTheme = defineStyleConfig({
  baseStyle: {
    alignItems: 'center',
    boxSizing: 'content-box',
    display: 'flex',
  },
  variants: {
    lg: {
      minHeight: '82px',
      padding: '0 30px',
    },
    md: {
      minHeight: '52px',
      padding: '0 24px',
    },
    simple: {
      minHeight: 'auto',
      padding: '0 24px',
    },
  },
  defaultProps: {
    variant: 'md',
  },
});

export const TableCell = forwardRef((props, ref) => {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('TableCell', { variant });

  return (
    <Box ref={ref} __css={styles} {...rest} role="cell">
      {props.children}
    </Box>
  );
});

TableCell.displayName = 'TableCell';
