import React, { useContext, useState } from 'react';
import {
  Navigate,
  NavLink as ReactRouterLink,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, Text, VStack, Heading, Box, Link } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { AuthForm } from './AuthForm';
import {
  compose,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { FormPassword } from '@app/layout/form/FormPassword';

export function SetNewPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const { getUser, resetPassword } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        password: compose([
          requiredValidator(values.password),
          minLength(values.password, 10),
        ]),
        confirmPassword: compose([
          requiredValidator(values.confirmPassword),
          values.password.length > 0 &&
          values.password !== values.confirmPassword
            ? 'Passwords do not match.'
            : null,
        ]),
      }),
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const result = await resetPassword({
        token: params.token!,
        password: value.password,
      });

      if (result.errors) {
        setIsLoading(false);
        setErrors(result.errors as any);
      } else {
        navigate('/login');
      }
    },
  });

  if (getUser()) {
    return <Navigate to={'/settings/security/' + params.token!} replace />;
  }

  return (
    <AuthForm>
      <VStack spacing="30px" alignItems="start">
        <Heading size="lg" as="h1">
          Reset password
        </Heading>

        <FormikProvider value={formik}>
          <Box as="form" onSubmit={formik.handleSubmit} width="100%">
            <VStack spacing="30px" alignItems="start">
              <VStack spacing="20px" alignItems="start" width="100%">
                {errors.length > 0 && (
                  <Box width="100%">
                    <Text
                      color="red.500"
                      paddingLeft="17px"
                      marginBottom="10px"
                      marginTop="-10px"
                    >
                      {errors.map((error) => (error as any).message).join()}
                    </Text>
                  </Box>
                )}

                <FormPassword
                  name="password"
                  placeholder="Password*"
                  size="lg"
                  autoFocus={true}
                />

                <FormPassword
                  name="confirmPassword"
                  placeholder="Confirm new password*"
                  size="lg"
                />
              </VStack>

              <VStack spacing="25px" alignItems="start" width="100%">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  width="full"
                  isLoading={isLoading}
                >
                  Reset password
                </Button>
              </VStack>
            </VStack>
          </Box>
        </FormikProvider>

        <Box>
          <Text variant="medium">
            Back to{' '}
            <Link as={ReactRouterLink} to="/login">
              log in
            </Link>
          </Text>
        </Box>
      </VStack>
    </AuthForm>
  );
}
