import { gql } from 'graphql-request';

export const ClientSchedulesQuery = gql`
  query ($clientId: String!) {
    clientSchedules(query: { clientId: $clientId }) {
      id
      flowId
      type
      rrule
    }
  }
`;
