import React, { useCallback, useContext, useMemo } from 'react';
import { Button, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { StoreContext } from '@app/store/StoreContext';
import { PageHeader } from '@app/layout/PageHeader';
import { InviteClientModal } from '@app/features/clients/modals/InviteClientModal';
import { ClientsBlock } from '@app/features/clients/ClientsBlock';

export function Clients() {
  const { getUser, isAdminOrSupervisor, getMyClients, getOtherClients } =
    useContext(StoreContext);

  const user = getUser();
  const myClients = getMyClients();
  const otherClients = getOtherClients();

  const inviteClientModal = useDisclosure();

  const inviteClient = useCallback(() => {
    inviteClientModal.onOpen();
  }, []);

  const isAllowedTeam = useMemo(() => isAdminOrSupervisor(user!), [user]);

  return (
    <>
      <VStack gap="40px" alignItems="start" width="100%" p="35px 50px">
        <HStack gap="0" width="100%" alignItems="start">
          <PageHeader
            title="Clients"
            breadcrumbs={[
              {
                url: '/clients',
                title: 'Clients',
              },
            ]}
          />

          <Button
            size="md"
            variant="primary"
            flexShrink={0}
            leftIcon={<AddIcon />}
            onClick={inviteClient}
          >
            Invite new client
          </Button>
        </HStack>

        <VStack gap="35px" alignItems="start" width="100%">
          <ClientsBlock
            title="Your clients"
            clients={myClients}
            provider={user!}
          />
          {isAllowedTeam && otherClients.length > 0 && (
            <ClientsBlock title="Other clients" clients={otherClients} />
          )}
        </VStack>
      </VStack>

      <InviteClientModal
        isOpen={inviteClientModal.isOpen}
        onClose={inviteClientModal.onClose}
      />
    </>
  );
}
