import React, { useContext } from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { Box, Heading, HStack, Image, Link, VStack } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';

export function Sidebar() {
  const { getUser, isAdminOrSupervisor, signOut, getOrganization } =
    useContext(StoreContext);

  const organization = getOrganization();

  return (
    <Box
      width="260px"
      height="100vh"
      bg="white"
      p="15"
      boxShadow="0px 1px 3px 0px #0000001A"
      flexShrink={0}
      zIndex={1}
      id="sidebar"
    >
      <VStack spacing="55px" height="100%" alignItems="start">
        {!organization?.logo && (
          <Box
            display="block"
            pt="13px"
            pl="15px"
            height="45px"
            boxSizing="content-box"
            flexShrink={0}
          >
            <Heading size="lg" as="p">
              silent time.
            </Heading>
          </Box>
        )}

        {organization?.logo && (
          <HStack
            pt="13px"
            height="45px"
            boxSizing="content-box"
            flexShrink={0}
            justifyContent="center"
            width="100%"
          >
            <Image src={organization?.logo} maxHeight="100%" />
          </HStack>
        )}

        <VStack
          height="100%"
          width="100%"
          alignItems="start"
          overflowY="auto"
          justifyContent="space-between"
        >
          <VStack spacing="10px" width="100%" alignItems="start">
            {isAdminOrSupervisor(getUser()!) && (
              <Link variant="sidebar" as={ReactRouterLink} to="/providers">
                My Team
              </Link>
            )}
            <Link variant="sidebar" as={ReactRouterLink} to="/clients">
              Clients
            </Link>
            <Link
              variant="sidebar"
              as={ReactRouterLink}
              to="/flow-editor-simple"
            >
              Create activity
            </Link>
            <Link variant="sidebar" as={ReactRouterLink} to="/settings">
              Settings
            </Link>
          </VStack>

          <VStack spacing="10px" width="100%" alignItems="start">
            <Link variant="sidebar" as={ReactRouterLink} to="/help">
              Help
            </Link>

            <Link variant="sidebar" onClick={() => signOut()}>
              Log out
            </Link>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
}
