import React, { createRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';

interface CropLogoModalProps {
  image: string;
  isOpen: boolean;
  onSave?: (data: string) => Promise<{ errors?: any[] }>;
  onClose(): void;
}

export function CropImageModal(props: CropLogoModalProps) {
  const { image, isOpen, onSave, onClose } = props;
  const [cropData, setCropData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const cropperRef = createRef<ReactCropperElement>();

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;

    if (cropper) {
      setCropData(cropper?.getCroppedCanvas().toDataURL());
    }
  };

  const submit = async () => {
    if (!onSave) {
      onClose();
    } else {
      setIsLoading(true);

      const { errors } = await onSave(cropData);

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        onClose();
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>&nbsp;</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Cropper ref={cropperRef} src={image} crop={onCrop} />
        </ModalBody>

        <ModalFooter>
          <Button size="sm" mr="12px" onClick={onClose}>
            Cancel
          </Button>

          <Button
            size="sm"
            variant="primary"
            isLoading={isLoading}
            onClick={submit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
