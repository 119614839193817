import React from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';
import { TabPanelContent } from '@app/layout/TabPanelContent';

export function ClientsDischargedPlaceholder() {
  return (
    <TabPanelContent>
      <VStack gap="12px" width="100%" p="13px 25px">
        <Heading size="sm" color="gray.500" as="h3">
          No discharged clients
        </Heading>
        <Text color="gray.500">
          Clients who got discharged will appear here.
        </Text>
      </VStack>
    </TabPanelContent>
  );
}
