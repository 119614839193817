import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IClient } from '@app/store/schema';
import { StoreContext } from '@app/store/StoreContext';

interface ReactivateClientModalProps {
  client: IClient;
  isOpen: boolean;
  onClose(): void;
}

export function ReactivateClientModal(props: ReactivateClientModalProps) {
  const { client, isOpen, onClose } = props;
  const { reactivateClient } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  if (!client) {
    return <></>;
  }

  const submit = async () => {
    setIsLoading(true);

    const { errors } = await reactivateClient(client.id);

    setIsLoading(false);

    if (errors && errors.length) {
      errors.map((error) =>
        toast({
          title: error.message,
          variant: 'error',
        }),
      );
    } else {
      onClose();

      toast({
        title: 'Changes saved',
        variant: 'success',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reactivate client</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap="20px" width="100%" alignItems="start">
            <Text size="lg" variant="medium">
              Are you sure you want to reactivate this client? After your client
              is reactivated, you will start receiving updates about their
              activities.
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" mr="12px" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            isLoading={isLoading}
            onClick={submit}
          >
            Reactivate client
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
