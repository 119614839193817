import {
  AssetType,
  Asset as AssetPropType,
} from '../../../../__generated__/graphql';
import IconAsset from './IconAsset';

export default function Asset({ asset }: { asset: AssetPropType }) {
  if (asset.type === AssetType.Icon && asset.icon) {
    return <IconAsset iconId={asset.icon} />;
  }

  return <div>{asset.type} not implemented</div>;
}
