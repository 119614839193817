import { gql } from 'graphql-request';

export const UpdateOrganizationMutation = gql`
  mutation ($name: String!) {
    organization(data: { name: $name }) {
      id
      domain
      logo
      name
    }
  }
`;
