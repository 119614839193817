import {
  Box,
  Grid,
  GridItem,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import EditorHeader from './EditorHeader';
import EditorPreview from './EditorPreview';
import ScreenNavigator from './ScreenNavigator';
import { ScreenFragment } from './fragments/screen';
import { useFragment } from '../../../__generated__/fragment-masking';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
import {
  CreateFlowInput,
  FLowEditorQueryQuery,
  InputFragmentFragment,
  RemoveFlowInput,
  ScreenFragmentFragment,
} from '../../../__generated__/graphql';
import {
  EditorReducer,
  FlowFields,
  editorReducerInit,
  getDirtyFields,
  reLinkScreens,
} from './reducer/EditorReducer';
import {
  changeScreenProperty,
  replaceState,
  changeScreenCtaLabel,
  changeFlowProperty,
  changeScreenInputProperty,
  addScreen,
  removeScreen,
  swapScreen,
} from './reducer/actions';
import { CREATE_FLOW } from './graphql/CreateFlowMutation';
import { useMutation, ApolloError } from '@apollo/client';
import FlowProperties from './FlowProperties';
import { AssetFragment } from './fragments/common';
import { REMOVE_FLOW } from './graphql/RemoveFlowMutation';
import ScreenProperties from './ScreenProperties';
import Alert from './Alert';

export enum PreviewState {
  HomeScreen,
  Flow,
}

interface FlowEditorAppProps {
  flow: NonNullable<FLowEditorQueryQuery['loadFlow']>;
  flowList: NonNullable<FLowEditorQueryQuery['allFlows']>;
  provider: NonNullable<FLowEditorQueryQuery['provider']>;
  refetch: () => void;
}

export interface FlowEditorScreenPropertyActions {
  onChangeScreenProperty: (
    screenId: string,
    property: keyof ScreenFragmentFragment,
    value: string,
  ) => void;
  onChangeScreenInputProperty: (
    screenId: string,
    inputId: string,
    property: keyof InputFragmentFragment | 'placeholder',
    value: string,
  ) => void;
  onChangeScreenCtaLabel: (screenId: string, label: string) => void;
}

export interface FlowEditorScreenActions {
  onAddScreen: (screenId: string, placement: 'before' | 'after') => void;
  onRemoveScreen: (screenId: string) => void;
  onSwapScreen: (activeScreenId: string, overScreenId: string) => void;
}

export interface FlowEditorFlowActions {
  onChangeFlowProperty: (prop: FlowFields, value: string) => void;
}

export default function FlowEditorApp({
  flow,
  flowList,
  provider,
  refetch,
}: FlowEditorAppProps) {
  const [
    createFlowMutation,
    {
      data: createFlowData,
      error: createFlowError,
      loading: createFlowLoading,
    },
  ] = useMutation(CREATE_FLOW);
  const [
    removeFlowMutation,
    {
      data: removeFlowData,
      error: removeFlowError,
      loading: removeFlowLoading,
    },
  ] = useMutation(REMOVE_FLOW);

  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const screens = useFragment(
    ScreenFragment,
    flow.screens,
  ) as ScreenFragmentFragment[];

  const asset = useFragment(AssetFragment, flow.asset);

  const [state, dispatch] = useReducer(
    EditorReducer,
    editorReducerInit({ ...flow, screens, asset }),
  );

  const [currentScreenId, setCurrentScreenId] = useState(
    state.screens.at(0)!.id,
  );

  const [previewState, setPreviewState] = useState<PreviewState>(
    PreviewState.HomeScreen,
  );

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const [alertModalAction, setAlertModalAction] = useState<() => void>(
    () => {},
  );

  const currentScreen = state.screens.find((x) => x.id === currentScreenId);
  const currentFlowId = params.flowId!;

  useEffect(() => {
    dispatch(replaceState(editorReducerInit({ ...flow, screens, asset })));

    console.log(`flow changed to ${flow.name}`);
  }, [flow.id]);

  useEffect(() => {
    setCurrentScreenId(state.screens.at(0)!.id);
  }, [state.screens.at(0)!.id]);

  useEffect(() => {
    if (!createFlowData?.createFlow?.id) return;
    toast({
      title: 'Exercise created.',
      description: `You have successfully created ${createFlowData.createFlow.name}`,
      status: 'success',
      variant: 'success',
      duration: 5000,
      isClosable: true,
    });
  }, [createFlowData?.createFlow?.id]);

  useEffect(() => {
    if (!createFlowError) return;
    const error = createFlowError as ApolloError;
    toast({
      title: 'Exercise creation error.',
      description: error.graphQLErrors?.[0]?.message,
      status: 'error',
      variant: 'error',
      duration: 5000,
      isClosable: true,
    });
  }, [createFlowError]);

  useEffect(() => {
    if (!removeFlowError) return;
    const error = removeFlowError as ApolloError;
    toast({
      title: 'Exercise remove error.',
      description: error.graphQLErrors?.[0]?.message,
      status: 'error',
      variant: 'error',
      duration: 5000,
      isClosable: true,
    });
  }, [removeFlowError]);

  const handleTemplateChange = (flowId: string) => {
    const hasChanges = getDirtyFields(state).length > 0;
    console.log({ hasChanges });

    if (hasChanges) {
      setAlertModalAction(() => () => {
        changeTemplate(flowId);
        setIsAlertModalOpen(false);
      });
      setIsAlertModalOpen(true);
      return false;
    }

    changeTemplate(flowId);
  };

  const changeTemplate = (flowId: string) => {
    navigate('/flow-editor/' + flowId);
  };

  const onChangeScreenProperty = (
    screenId: string,
    property: keyof ScreenFragmentFragment,
    value: string,
  ) => dispatch(changeScreenProperty(screenId, property, value));

  const onChangeScreenInputProperty = (
    screenId: string,
    inputId: string,
    property: keyof InputFragmentFragment | 'placeholder',
    value: string,
  ) => dispatch(changeScreenInputProperty(screenId, inputId, property, value));

  const onChangeScreenCtaLabel = (screenId: string, label: string) =>
    dispatch(changeScreenCtaLabel(screenId, label));

  const onChangeFlowProperty = (prop: FlowFields, value: string) =>
    dispatch(changeFlowProperty(prop, value));

  const onAddScreen = (screenId: string, placement: 'before' | 'after') =>
    dispatch(addScreen(screenId, placement));

  const onRemoveScreen = (screenId: string) => {
    setCurrentScreenId(state.screens.find((x) => x.id !== screenId)!.id);
    dispatch(removeScreen(screenId));
  };

  const onSwapScreen = (activeScreenId: string, overScreenId: string) =>
    dispatch(swapScreen(activeScreenId, overScreenId));

  const onSave = async () => {
    const { asset, fieldState, ...rest } = state;

    const data = {
      ...rest,
      screens: state.screens.map((x) => {
        return {
          screenType: x.screenType,
          data: JSON.stringify(x),
        };
      }),
      update: true,
    } satisfies CreateFlowInput;

    delete data.__typename;
    delete data.organizationId;

    await saveFlow(data);
  };

  const onSaveAsACopy = async () => {
    const isNameChanged = state.fieldState.flowFieldsState.name.dirty;

    const flowId = uuidv4();
    const newState = {
      ...state,
      name: isNameChanged ? state.name : `${state.name} copy`,
      id: flowId,
      screens: reLinkScreens(
        state.screens.map((x) => ({ ...x, id: uuidv4() })),
        state,
      ),
    };

    const { asset, fieldState, ...rest } = newState;

    const data = {
      ...rest,
      screens: newState.screens.map((x) => {
        return {
          screenType: x.screenType,
          data: JSON.stringify(x),
        };
      }),
    } satisfies CreateFlowInput;

    delete data.__typename;
    delete data.organizationId;

    await saveFlow(data);
  };

  const saveFlow = async (data: CreateFlowInput) => {
    try {
      const newFlow = await createFlowMutation({ variables: { data } });
      if (newFlow.data?.createFlow?.id) {
        navigate('/flow-editor/' + newFlow.data.createFlow.id);
      }
    } catch (error) {}
  };

  const onRemove = async () => {
    const data = {
      id: flow.id,
    } satisfies RemoveFlowInput;

    try {
      const removedFlow = await removeFlowMutation({ variables: { data } });
      if (removedFlow.data?.removeFlow?.id) {
        // hard reload
        window.location.href =
          '/flow-editor/' + '6df644d9-7213-435e-8413-7c2295317090';
      }
    } catch (error) {}
  };

  if (!currentScreen) return null;

  return (
    <Grid
      templateAreas={`"header preview"
                  "main preview"
                  "footer preview"`}
      gridTemplateRows={'50px 1fr 250px'}
      sx={{
        '@media screen and (min-width: 1280px)': {
          gridTemplateColumns: '1fr 375px',
        },
        '@media screen and (min-width: 1440px)': {
          gridTemplateColumns: '1fr 415px',
        },
        '@media screen and (min-width: 1920px)': {
          gridTemplateColumns: '1fr 455px',
        },
      }}
      h="100%"
      gap="1"
      pl={1}
    >
      <GridItem
        pl={2}
        pr={2}
        alignContent="center"
        area={'header'}
        bg="white"
        boxShadow="0px 1px 3px 0px #0000001A"
      >
        <EditorHeader
          flows={flowList}
          currentFlowId={currentFlowId}
          onTemplateChange={handleTemplateChange}
          onSavePress={onSaveAsACopy}
          onRemovePress={onRemove}
          canRemove={!!flow.organizationId}
          canEdit={!!flow.organizationId}
          removingInProgress={removeFlowLoading}
          savingInProgress={createFlowLoading}
          editingInProgress={createFlowLoading}
          onEditPress={onSave}
          provider={provider}
        />
      </GridItem>
      <GridItem
        area={'preview'}
        justifyContent="center"
        alignContent="center"
        bg="white"
        p="2"
        boxShadow="0px 1px 3px 0px #0000001A"
      >
        <EditorPreview
          provider={provider}
          previewState={previewState}
          flow={state}
          screen={currentScreen}
          onChangeScreenProperty={onChangeScreenProperty}
          onChangeScreenCtaLabel={onChangeScreenCtaLabel}
          onChangeScreenInputProperty={onChangeScreenInputProperty}
        />
      </GridItem>
      <GridItem
        area={'main'}
        bg="white"
        boxShadow="0px 1px 3px 0px #0000001A"
        rowSpan={previewState === PreviewState.Flow ? 1 : 2}
      >
        <Tabs
          isFitted
          onChange={(idx) =>
            setPreviewState(
              idx === 0 ? PreviewState.HomeScreen : PreviewState.Flow,
            )
          }
        >
          <TabList>
            <Tab>Description</Tab>
            <Tab>Steps</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {flow.organizationId !== provider.organizationId && (
                <Box fontSize="10px">orgId: {flow.organizationId}</Box>
              )}
              <FlowProperties
                {...state}
                onChangeScreenProperty={onChangeScreenProperty}
                onChangeScreenCtaLabel={onChangeScreenCtaLabel}
                onChangeFlowProperty={onChangeFlowProperty}
                onChangeScreenInputProperty={onChangeScreenInputProperty}
              />
            </TabPanel>
            <TabPanel>
              <ScreenProperties
                screen={currentScreen}
                onChangeScreenProperty={onChangeScreenProperty}
                onChangeScreenCtaLabel={onChangeScreenCtaLabel}
                onChangeScreenInputProperty={onChangeScreenInputProperty}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
      {previewState === PreviewState.Flow && (
        <GridItem
          area={'footer'}
          bg="white"
          boxShadow="0px 1px 3px 0px #0000001A"
          overflow="auto"
        >
          <Box display="flex" h="100%">
            <ScreenNavigator
              screen={currentScreen}
              screens={state.screens}
              onScreenChange={setCurrentScreenId}
              onAddScreen={onAddScreen}
              onRemoveScreen={onRemoveScreen}
              onSwapScreen={onSwapScreen}
            />
          </Box>
        </GridItem>
      )}
      <Alert
        isOpen={isAlertModalOpen}
        onClose={() => setIsAlertModalOpen(false)}
        action={alertModalAction}
      />
    </Grid>
  );
}
