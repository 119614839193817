import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useField } from 'formik';
import {
  Center,
  FormControl,
  FormLabel,
  forwardRef,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useStyleConfig,
} from '@chakra-ui/react';
import { FormError } from '@app/layout/form/FormError';

export const FormPassword = forwardRef((props, ref) => {
  const { label, name, placeholder, autoFocus, variant, size, ...rest } = props;

  const styles = useStyleConfig('FormPassword', { variant });
  const [field, meta] = useField({
    name,
  });
  const isInvalid = meta.touched && !!meta.error;
  const [passwordHidden, setPasswordHidden] = useState(true);
  const handlePasswordHidden = () => setPasswordHidden(!passwordHidden);

  return (
    <FormControl
      id={name}
      ref={ref}
      __css={styles}
      {...rest}
      isInvalid={isInvalid}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Input
          id={name}
          name={name}
          type={passwordHidden ? 'password' : 'text'}
          placeholder={placeholder || ''}
          onChange={field.onChange}
          value={field.value}
          autoFocus={autoFocus}
          size={size}
        />
        <InputRightElement width="48px" height="100%">
          <Center>
            <Icon
              as={passwordHidden ? FiEye : FiEyeOff}
              width="18px"
              height="18px"
              onClick={handlePasswordHidden}
              cursor="pointer"
            />
          </Center>
        </InputRightElement>
      </InputGroup>

      {isInvalid && <FormError error={meta.error!} />}
    </FormControl>
  );
});

FormPassword.displayName = 'FormPassword';
