import React, { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';

export function TabPanelContent(props: PropsWithChildren) {
  return (
    <Box
      bg="white"
      width="100%"
      rounded="12px"
      p="12px 0"
      border="1px solid"
      borderColor="gray.200"
    >
      {props.children}
    </Box>
  );
}
