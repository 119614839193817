import { gql } from '../../../../__generated__/gql';

export const InputInterfaceFragment = gql(/* GraphQL */ `
  fragment InputInterfaceFragment on Input {
    __typename
    __typename
    id
    label
    value
    ...ButtonFragment
    ...TextAreaFragment
    ...RangeFragment
  }
`);

export const InputFragment = gql(/* GraphQL */ `
  fragment InputFragment on Input {
    __typename
    id
    label
    value
  }
`);

export const ButtonFragment = gql(/* GraphQL */ `
  fragment ButtonFragment on Button {
    action
    asset {
      ...AssetFragment
    }
  }
`);

export const TextAreaFragment = gql(/* GraphQL */ `
  fragment TextAreaFragment on TextArea {
    placeholder
  }
`);

export const AssetFragment = gql(/* GraphQL */ `
  fragment AssetFragment on Asset {
    __typename
    id
    type
    icon
    url
  }
`);

export const RangeFragment = gql(/* GraphQL */ `
  fragment RangeFragment on Range {
    min
    max
    defaultValue
    labels {
      text
      value
    }
  }
`);

export const BadgeFragment = gql(/* GraphQL */ `
  fragment BadgeFragment on CardBadge {
    __typename
    text
    status
  }
`);
