import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  FLowEditorQueryQuery,
  FlowType,
  Provider,
} from '../../../__generated__/graphql';
import {
  Button,
  HStack,
  Box,
  Spacer,
  Text,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { FiSave, FiX, FiEdit, FiEye } from 'react-icons/fi';

type Flow = NonNullable<FLowEditorQueryQuery['allFlows']>[0];

interface EditorHeaderProps {
  currentFlowId: string;
  flows: Flow[];
  provider: Partial<Provider>;
  onTemplateChange: (flowId: string) => void;
  onSavePress: () => void;
  onEditPress: () => void;
  onRemovePress: () => void;
  canRemove: boolean;
  canEdit: boolean;
  savingInProgress: boolean;
  editingInProgress: boolean;
  removingInProgress: boolean;
}

export default function EditorHeaderSimple({
  flows,
  provider,
  onTemplateChange,
  currentFlowId,
  onSavePress,
  onEditPress,
  onRemovePress,
  canRemove,
  canEdit,
  savingInProgress,
  editingInProgress,
  removingInProgress,
}: EditorHeaderProps) {
  if (!flows) return null;

  const [customFlows, standardFlows] = flows
    .filter((x): x is NonNullable<Flow> => !!x)
    .filter((x) => [FlowType.Journal, FlowType.Checklist].includes(x!.type))
    .reduce<[Flow[], Flow[]]>(
      (acc, x) => (acc[!!x.organizationId ? 0 : 1].push(x), acc),
      [[], []],
    );

  const renderDeleteButton = () => (
    <Button
      size="sm"
      color="red"
      leftIcon={<FiX />}
      onClick={onRemovePress}
      isLoading={removingInProgress}
      isDisabled={!canRemove}
    >
      Delete
    </Button>
  );

  return (
    <HStack>
      <Box>
        <Menu>
          <MenuButton size="sm" as={Button} rightIcon={<ChevronDownIcon />}>
            Activities
          </MenuButton>
          <MenuList>
            <MenuGroup title="Create new">
              <MenuItem
                onClick={() =>
                  onTemplateChange('26bd3ea3-f3c3-418b-93cd-f3a1ab82170c')
                }
                filter={
                  '26bd3ea3-f3c3-418b-93cd-f3a1ab82170c' === currentFlowId
                    ? 'invert(5%)'
                    : ''
                }
              >
                <HStack w="100%" gap="32px" justifyContent="space-between">
                  <Text maxWidth="350px" size="sm">
                    Journaling
                  </Text>
                </HStack>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onTemplateChange('3577666a-bc62-4286-a040-e1504005a100')
                }
                filter={
                  '3577666a-bc62-4286-a040-e1504005a100' === currentFlowId
                    ? 'invert(5%)'
                    : ''
                }
              >
                <HStack w="100%" gap="32px" justifyContent="space-between">
                  <Text maxWidth="350px" size="sm">
                    Checklist
                  </Text>
                </HStack>
              </MenuItem>
            </MenuGroup>
            {!!customFlows.length && (
              <MenuGroup title="Your activities">
                {customFlows?.map((x) => (
                  <MenuItem
                    key={`${x?.id}flowpicker`}
                    onClick={() => onTemplateChange(x!.id)}
                    filter={x?.id === currentFlowId ? 'invert(5%)' : ''}
                  >
                    <HStack w="100%" gap="32px" justifyContent="space-between">
                      <Text maxWidth="350px" size="sm">
                        {x?.name}
                      </Text>
                      <Text color="gray.500" size="xs">
                        {x?.type}
                      </Text>
                    </HStack>
                  </MenuItem>
                ))}
              </MenuGroup>
            )}
          </MenuList>
        </Menu>
      </Box>
      <Spacer />
      <Box>{canRemove && renderDeleteButton()}</Box>
    </HStack>
  );
}
