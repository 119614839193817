import { useQuery } from '@apollo/client';
import { FLOW_EDITOR } from './graphql/FlowEditorQuery';
import { useNavigate, useParams } from 'react-router-dom';
import FlowEditorApp from './FlowEditorApp';
import FlowEditorSimpleApp from './FlowEditorSimpleApp';

const GRATITUDE_JOURNAL_FLOW_ID = '26bd3ea3-f3c3-418b-93cd-f3a1ab82170c';

export default function FlowEditorBootstrap({ simple }: { simple?: boolean }) {
  const params = useParams();
  const navigate = useNavigate();

  if (simple && !params.flowId) {
    navigate('/flow-editor-simple/' + GRATITUDE_JOURNAL_FLOW_ID);
  }

  if (!simple && !params.flowId) {
    navigate('/flow-editor/' + GRATITUDE_JOURNAL_FLOW_ID);
  }

  const currentFlowId = params.flowId ?? GRATITUDE_JOURNAL_FLOW_ID;
  const { data, error, loading, refetch } = useQuery(FLOW_EDITOR, {
    variables: {
      query: { id: currentFlowId },
    },
  });

  if (loading) return <div>loading</div>;
  if (error) return <div>{error.message}</div>;
  if (!data?.loadFlow?.screens || !data?.allFlows || !data.provider)
    return <div>no data</div>;

  if (simple) {
    return (
      <FlowEditorSimpleApp
        flow={data.loadFlow}
        flowList={data.allFlows}
        provider={data.provider}
        refetch={refetch}
      />
    );
  }

  return (
    <FlowEditorApp
      flow={data.loadFlow}
      flowList={data.allFlows}
      provider={data.provider}
      refetch={refetch}
    />
  );
}
