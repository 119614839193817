import { TUserRole } from '@app/store/schema';

const userRoles: Record<TUserRole, string> = {
  ADMIN: 'Admin',
  SUPERVISOR: 'Supervisor',
  CLINICIAN: 'Clinician',
};

export const userRole = (role: TUserRole) => userRoles[role];

export const userRolesList = [
  {
    value: 'ADMIN',
    title: userRole('ADMIN'),
    description:
      'Can change practice settings and invite new team members. Can see and manage all clients across practice',
  },
  {
    title: userRole('SUPERVISOR'),
    value: 'SUPERVISOR',
    description:
      'Cannot change practice settings but can invite new team members. Can see and manage their own clients and the clients of their assigned team members',
  },
  {
    title: userRole('CLINICIAN'),
    value: 'CLINICIAN',
    description:
      'Cannot change practice settings or invite new team members. Only can see and manage their own clients',
  },
];
