import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import { HiChevronRight } from 'react-icons/hi';
import { Badge, Button, HStack, Icon, Text } from '@chakra-ui/react';
import { IClient, IProvider } from '@app/store/schema';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { badgeColor } from '@app/utils/badgeColor';
import { providerFullName, userFullName } from '@app/utils/userFullName';
import { formatDatetime } from '@app/utils/date';

export interface ClientsActiveListItemProps {
  client: IClient;
  provider?: IProvider;
  onResendClientInvite: (clientId: string) => void;
  isLoading?: boolean;
}

export function ClientsActiveListItemContent({
  client,
  provider,
  onResendClientInvite,
  isLoading,
}: ClientsActiveListItemProps) {
  const navigate = useNavigate();
  const { activeAt } = client.user;

  const userNameFormatted = useMemo(() => userFullName(client!), [client]);

  const providerNameFormatted = useMemo(
    () => (provider ? providerFullName(provider!) : ''),
    [provider],
  );

  const activeAtFormatted = useMemo(
    () => (activeAt ? formatDatetime(activeAt) : '-'),
    [activeAt],
  );

  const navigateToDetails = useCallback(() => {
    navigate('/clients/' + client.id);
  }, []);

  return (
    <TableRow className="clickable" onClick={navigateToDetails}>
      <TableCell width="100%">
        <HStack>
          <Text variant="medium" size="sm">
            {userNameFormatted}
          </Text>
          {client.isHighRisk && <Icon as={FiAlertCircle} color="red.500" />}
        </HStack>
      </TableCell>
      <TableCell width={!provider ? '35%' : '25%'} flexShrink={0}>
        {client.latestAssessmentResult && (
          <HStack>
            <Text variant="medium" size="sm">
              {client.latestAssessmentResult.titleShort}
            </Text>
            <Badge
              colorScheme={badgeColor(client.latestAssessmentResult.badge)}
              fontSize="12px"
              fontWeight="500"
              textTransform="none"
            >
              {client.latestAssessmentResult.badge.text}
            </Badge>
          </HStack>
        )}
        {!client.latestAssessmentResult && (
          <Text variant="medium" size="sm">
            -
          </Text>
        )}
      </TableCell>
      <TableCell width="15%" flexShrink={0}>
        <Text variant="medium" size="sm">
          {activeAtFormatted}
        </Text>
      </TableCell>

      {provider && (
        <TableCell width="20%" flexShrink={0}>
          <Text variant="medium" size="sm">
            {providerNameFormatted}
          </Text>
        </TableCell>
      )}
      <TableCell width="98px" flexShrink={0}>
        {!activeAt && (
          <Text variant="medium" size="sm">
            <Button
              size="xs"
              colorScheme="purple"
              variant="outline"
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();

                onResendClientInvite(client.id);
              }}
              isLoading={isLoading}
            >
              Resend invite
            </Button>
          </Text>
        )}
      </TableCell>
      <TableCell width="24px" flexShrink={0} justifyContent="end">
        <Icon as={HiChevronRight} width="24px" height="24px" color="gray.400" />
      </TableCell>
    </TableRow>
  );
}

export const ClientsActiveListItem = React.memo(ClientsActiveListItemContent);
