import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Skeleton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { ClientScheduleItem } from '@app/features/clients/details/ClientScheduleItem';
import { ExercisesModal } from '@app/features/clients/modals/ExercisesModal';
import { StoreContext } from '@app/store/StoreContext';
import { TFlowType } from '@app/store/schema';

export function ClientHomework() {
  const params = useParams();
  const {
    getClientById,
    getClientSchedules,
    getFlows,
    getFlowById,
    loadClientSchedules,
  } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [groupIndex, setGroupIndex] = useState<number>();
  const exercisesModal = useDisclosure();

  const client = getClientById(params.id as string);
  const flows = getFlows();
  const schedules = getClientSchedules(client!.id);

  const editExercises = (index: number) => {
    setGroupIndex(index);
    exercisesModal.onOpen();
  };

  useEffect(() => {
    loadClientSchedules(client!.id);
  }, []);

  useEffect(() => {
    if (flows && schedules) {
      setLoading(false);
    }
  }, [flows, schedules]);

  const groups = [
    {
      types: ['ASSESSMENT'] as TFlowType[],
      title: 'Assessment surveys',
      modalTitle: 'Assign assessment surveys',
      searchPlaceholder: 'Search assessment surveys',
      button: 'Assign assessment survey',
      schedules: (schedules || []).filter(
        (schedule) => getFlowById(schedule.flowId)?.type === 'ASSESSMENT',
      ),
    },
    {
      types: ['JOURNAL', 'CHECKLIST'] as TFlowType[],
      title: 'Activities',
      modalTitle: 'Assign activities',
      searchPlaceholder: 'Search activities',
      button: 'Assign activity',
      schedules: (schedules || []).filter(
        (schedule) =>
          getFlowById(schedule.flowId)?.type === 'JOURNAL' ||
          getFlowById(schedule.flowId)?.type === 'CHECKLIST',
      ),
    },
    {
      types: ['TRACKER'] as TFlowType[],
      title: 'Trackers',
      modalTitle: 'Assign trackers',
      searchPlaceholder: 'Search tracker',
      button: 'Assign tracker',
      schedules: (schedules || []).filter(
        (schedule) => getFlowById(schedule.flowId)?.type === 'TRACKER',
      ),
    },
  ];

  return (
    <>
      <VStack gap="35px" alignItems="start" width="100%">
        {loading && (
          <VStack
            gap="10px"
            alignItems="start"
            bg="white"
            p="30px 0"
            width="100%"
          >
            <Box p="0 24px">
              <Skeleton height="32px" width="200px" />
            </Box>

            <Table>
              <TableContainer>
                <TableHeader>
                  <TableHeaderCell width="60%">
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                  <TableHeaderCell width="40%">
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                </TableHeader>
                <TableBody>
                  {Array.from(Array(3)).map((item, index) => (
                    <TableRow key={index} padding="16px 0">
                      <TableCell width="60%">
                        <Skeleton height="50px" width="100%" />
                      </TableCell>
                      <TableCell width="40%">
                        <Skeleton height="50px" width="100px" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Table>

            <Box p="0 24px">
              <Skeleton height="40px" width="200px" />
            </Box>
          </VStack>
        )}

        {!loading &&
          groups &&
          groups.map(({ title, button, schedules }, index) => (
            <VStack
              key={index}
              gap="10px"
              alignItems="start"
              bg="white"
              p="30px 0"
              width="100%"
            >
              <Box p="0 24px">
                <Heading size="md">{title}</Heading>
              </Box>

              <Table p="12px 0">
                <TableContainer>
                  <TableHeader>
                    <TableHeaderCell width="60%" label="Name" />
                    <TableHeaderCell
                      width="40%"
                      label="Frequency"
                      minWidth="304px"
                    />
                  </TableHeader>

                  <TableBody>
                    {schedules &&
                      schedules.map((schedule) => (
                        <ClientScheduleItem
                          key={schedule.id}
                          client={client!}
                          schedule={schedule}
                        />
                      ))}
                  </TableBody>
                </TableContainer>
              </Table>

              <Box p="0 24px">
                <Button
                  size="sm"
                  colorScheme="purple"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  onClick={() => editExercises(index)}
                >
                  {button}
                </Button>
              </Box>
            </VStack>
          ))}
      </VStack>

      <ExercisesModal
        client={client!}
        title={groupIndex !== undefined ? groups[groupIndex!].modalTitle! : ''}
        searchPlaceholder={
          groupIndex !== undefined ? groups[groupIndex!].searchPlaceholder! : ''
        }
        types={groupIndex !== undefined ? groups[groupIndex!].types : []}
        isOpen={exercisesModal.isOpen}
        onClose={exercisesModal.onClose}
      />
    </>
  );
}
