import React from 'react';
import { Text } from '@chakra-ui/react';

export interface FormErrorProps {
  error: string;
}

export function FormError(props: FormErrorProps) {
  const { error } = props;

  return (
    <Text color="red.500" padding="8px 0 0 16px" size="sm">
      {error}
    </Text>
  );
}
