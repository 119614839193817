import React, { useContext, useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { Center, Spinner } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { TUserRole } from '@app/store/schema';
import { AppShell } from '@app/AppShell';
import { Login } from '@app/auth/Login';
import { ResetPassword } from '@app/auth/ResetPassword';
import { SetNewPassword } from '@app/auth/SetNewPassword';
import { AcceptInvite } from '@app/auth/AcceptInvite';
import { Providers } from '@app/features/providers/Providers';
import { ProviderDetails } from '@app/features/providers/details/ProviderDetails';
import { Clients } from '@app/features/clients/Clients';
import { ClientDetails } from '@app/features/clients/details/ClientDetails';
import { ClientProgress } from '@app/features/clients/details/ClientProgress';
import { ClientHomework } from '@app/features/clients/details/ClientHomework';
import { Settings } from '@app/features/settings/Settings';
import { SettingsProviders } from '@app/features/settings/SettingsProviders';
import { SettingsSecurity } from '@app/features/settings/SettingsSecurity';
import { SettingsSetNewPassword } from '@app/features/settings/SettingsSetNewPassword';
import { SettingsPersonal } from '@app/features/settings/SettingsPersonal';
import { Help } from '@app/features/help/Help';
import { ErrorBoundary } from '@app/auth/ErrorBoundary';
import FlowEditorBootstrap from './features/flow-editor/FlowEditorBootstrap';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache({
    typePolicies: {
      Button: {
        keyFields: ['id', 'label', 'value'],
      },
    },
    possibleTypes: {
      Screen: [
        'TimerScreen',
        'ContainerScreen',
        'HomeScreen',
        'YourEntriesScreen',
        'EntryScreen',
        'JournalingScreen',
        'CongratulationsScreen',
        'SurveyScreen',
        'ButtonAssessmentScreen',
        'AssessmentResultScreen',
        'PreviewScreen',
        'ButtonTrackerScreen',
        'RangeTrackerScreen',
      ],
      Input: ['Input', 'TextArea', 'Range', 'Button'],
    },
  }),
  link: createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
});

const ProtectedRoute = ({ roles }: { roles: TUserRole[] }) => {
  const { getUser } = useContext(StoreContext);

  const user = getUser();

  if (roles.indexOf(user?.user.role as TUserRole) === -1) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/reset-password/:token',
    element: <SetNewPassword />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/accept-invite/:token',
    element: <AcceptInvite />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/',
    element: <AppShell />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoute roles={['ADMIN', 'SUPERVISOR']} />,
        children: [
          {
            path: 'providers',
            element: <Providers />,
          },
          {
            path: 'providers/:id',
            element: <ProviderDetails />,
          },
        ],
      },
      {
        path: 'clients',
        element: <Clients />,
      },
      {
        path: 'clients/:id',
        element: <ClientDetails />,
        children: [
          {
            index: true,
            loader: ({ params }) => redirect(`/clients/${params.id}/progress`),
          },
          {
            path: 'progress',
            element: <ClientProgress />,
          },
          {
            path: 'homework',
            element: <ClientHomework />,
          },
        ],
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            index: true,
            loader: () => redirect('/settings/personal'),
          },
          {
            path: 'personal',
            element: <SettingsPersonal />,
          },
          {
            path: 'security',
            element: <SettingsSecurity />,
          },
          {
            path: 'security/:token',
            element: <SettingsSetNewPassword />,
          },
          {
            element: <ProtectedRoute roles={['ADMIN', 'SUPERVISOR']} />,
            children: [
              {
                path: 'providers',
                element: <SettingsProviders />,
              },
            ],
          },
        ],
      },
      {
        path: 'help',
        element: <Help />,
      },
      {
        path: 'flow-editor-simple',
        element: <FlowEditorBootstrap simple />,
        children: [
          {
            path: ':flowId',
            element: <FlowEditorBootstrap simple />,
          },
        ],
      },
      {
        path: 'flow-editor',
        element: <FlowEditorBootstrap />,
        children: [
          {
            path: ':flowId',
            element: <FlowEditorBootstrap />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <ErrorBoundary />,
  },
]);

export function App() {
  const { loadUser, isUserLoading } = useContext(StoreContext);

  useEffect(() => {
    loadUser();
  }, []);

  if (isUserLoading()) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
}
