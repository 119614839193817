import React from 'react';
import {
  defineStyleConfig,
  forwardRef,
  HStack,
  useStyleConfig,
} from '@chakra-ui/react';

export const tableRowTheme = defineStyleConfig({
  baseStyle: {
    '&.clickable': {
      _hover: {
        background: 'gray.100',
        cursor: 'pointer',
      },
    },
  },
  variants: {
    lg: {
      borderBottom: '1px solid',
      borderBottomColor: 'gray.200',
    },
    md: {},
  },
  defaultProps: {
    variant: 'md',
  },
});

export const TableRow = forwardRef((props, ref) => {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('TableRow', { variant });

  return (
    <HStack gap={0} width="100%" ref={ref} __css={styles} {...rest} role="row">
      {props.children}
    </HStack>
  );
});

TableRow.displayName = 'TableRow';
