import { gql } from 'graphql-request';

export const ClientTrackersQuery = gql`
  query ($clientId: String!) {
    clientTrackers(query: { clientId: $clientId }) {
      id
      prompt
      items {
        id
        label
        min
        max
        defaultValue
        values {
          id
          text
          value
        }
        triggers {
          id
          text
        }
      }
    }
  }
`;
