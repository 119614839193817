import React, { PropsWithChildren } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

export function AuthForm(props: PropsWithChildren) {
  return (
    <Flex flex="1" minHeight="0" flexDirection="row" width="100%">
      <Flex
        alignItems="safe center"
        flex="1"
        flexDirection="column"
        minHeight="0"
        overflow="auto"
        width="100%"
        justifyContent="safe center"
      >
        <Box
          position={{ base: 'relative', sm: 'absolute' }}
          left={{ base: '0', sm: '30px' }}
          top={{ base: '0', sm: '36px' }}
          padding={{ base: '36px 0', sm: '0' }}
        >
          <Heading size="lg" as="p">
            silent time.
          </Heading>
        </Box>

        <Box
          width="520px"
          maxWidth="80%"
          rounded={24}
          bg="white"
          padding="30px"
          margin={{ base: '0 0 60px 0', sm: '80px 0' }}
          boxShadow="0px 25px 50px -12px #00000040"
        >
          {props.children}
        </Box>
      </Flex>
    </Flex>
  );
}
