import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { WidgetHeader } from '@app/layout/widget/WidgetHeader';
import { WidgetPlaceholder } from '@app/layout/widget/WidgetPlaceholder';
import { Filter } from '@app/layout/filter/Filter';
import { FilterItem, useFilter } from '@app/layout/filter/useFilter';
import { useSort } from '@app/layout/sort/useSort';
import { Comparator } from '@app/utils/comparator';
import { surveyColor, surveyName } from '@app/utils/surveyValue';
import { IExercise } from '@app/store/schema';
import { mapToArray } from '@app/store/StoreContext';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';

export function ExercisesTableWidget() {
  const { store } = useContext(WidgetsContext);
  const [lines, setLines] = useState([] as IExercise[]);
  const [visibleCount, setVisibleCount] = useState(10);
  const [filterFlows, setFilterFlows] = useState<FilterItem[]>([]);
  const [filterOutcomes, setFilterOutcomes] = useState<FilterItem[]>([]);
  const { filterValue, applyFilter, resetFilter, resetAllFilters } = useFilter({
    flows: [],
    outcomes: [],
  });
  const { sortValue, applySort } = useSort({
    column: 'createdAt',
    order: 'asc',
  });

  const updateLines = () => {
    const exercises = store.exercises || [];
    const availableFlows = new Map();
    const availableOutcomes = new Map();

    exercises.forEach((item) => {
      availableFlows.set(item.flow.id, {
        id: item.flow.id,
        name: item.flow.name,
      });
      availableOutcomes.set(item.flowResultInputs[0].value, {
        id: item.flowResultInputs[0].value,
        name: surveyName(item.flowResultInputs[0].value),
      });
    });

    resetAllFilters({
      flows: [],
      outcomes: [],
    });
    setFilterFlows(mapToArray(availableFlows));
    setFilterOutcomes(mapToArray(availableOutcomes));
    setLines(exercises);
  };

  useEffect(() => {
    if (!store.loading) {
      updateLines();
    } else {
      setVisibleCount(10);
    }
  }, [store.loading]);

  const sortedTable = lines
    .filter((item) => {
      let isFiltered = true;

      if (filterValue.flows.length > 0 && isFiltered) {
        isFiltered = filterValue.flows.indexOf(item.flow.id) !== -1;
      }

      if (filterValue.outcomes.length > 0 && isFiltered) {
        isFiltered =
          filterValue.outcomes.indexOf(item.flowResultInputs[0].value) !== -1;
      }

      return isFiltered;
    })
    .sort((a: IExercise, b: IExercise) => {
      const isAsc = sortValue.order === 'asc';

      if (sortValue.column === 'name') {
        return Comparator.orderWithDirection(
          isAsc,
          a.flow.name.toLowerCase(),
          b.flow.name.toLowerCase(),
        );
      }

      if (sortValue.column === 'outcome') {
        return Comparator.orderWithDirection(
          isAsc,
          surveyName(a.flowResultInputs[0].value).toLowerCase(),
          surveyName(b.flowResultInputs[0].value).toLowerCase(),
        );
      }

      if (sortValue.column === 'createdAt') {
        return Comparator.orderDates(isAsc, a.createdAt, b.createdAt);
      }

      return 0;
    });

  return (
    <>
      <WidgetHeader title="Activities">
        {lines && lines.length > 0 && (
          <HStack marginLeft="35px" alignItems="start" gap="10px">
            <Filter
              title="Activities"
              items={filterFlows}
              value={filterValue.flows}
              onChange={(id) => applyFilter('flows', id)}
              onReset={() => resetFilter('flows')}
            />
            <Filter
              title="Outcomes"
              items={filterOutcomes}
              value={filterValue.outcomes}
              onChange={(id) => applyFilter('outcomes', id)}
              onReset={() => resetFilter('outcomes')}
            />
          </HStack>
        )}
      </WidgetHeader>

      <VStack width="100%" gap="15px" alignItems="start">
        {store.loading && (
          <>
            <Table
              width="calc(100% + 48px)"
              maxWidth="calc(100% + 48px)"
              marginLeft="-24px"
            >
              <TableContainer>
                <TableHeader>
                  <TableHeaderCell width="20%" flexShrink={0}>
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                  <TableHeaderCell width="20%" flexShrink={0}>
                    <Skeleton height="16px" width="60px" />
                  </TableHeaderCell>
                  <TableHeaderCell width="100%">
                    <Skeleton height="16px" width="100px" />
                  </TableHeaderCell>
                </TableHeader>
                <TableBody>
                  {Array.from(Array(3)).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell width="20%" flexShrink={0}>
                        <Skeleton height="24px" width="80%" />
                      </TableCell>
                      <TableCell width="20%" flexShrink={0}>
                        <Skeleton height="24px" width="50%" />
                      </TableCell>
                      <TableCell width="100%">
                        <Skeleton height="24px" width="20%" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Table>
          </>
        )}

        {!store.loading && sortedTable.length === 0 && <WidgetPlaceholder />}

        {!store.loading && sortedTable.length > 0 && (
          <>
            <Table
              width="calc(100% + 48px)"
              maxWidth="calc(100% + 48px)"
              marginLeft="-24px"
            >
              <TableContainer minWidth="870px">
                <TableHeader pb="25px">
                  <TableHeaderCell
                    label="Exercise"
                    width="20%"
                    sortColumn="name"
                    sortValue={sortValue}
                    onSort={applySort}
                    flexShrink={0}
                    variant="simple"
                  />
                  <TableHeaderCell
                    label="Outcome"
                    width="20%"
                    sortColumn="outcome"
                    sortValue={sortValue}
                    onSort={applySort}
                    flexShrink={0}
                    variant="simple"
                  />
                  <TableHeaderCell
                    label="Date submitted"
                    width="100%"
                    sortColumn="createdAt"
                    sortValue={sortValue}
                    onSort={applySort}
                    variant="simple"
                  />
                </TableHeader>
                <TableBody gap="25px">
                  {sortedTable.slice(0, visibleCount).map((line, index) => (
                    <TableRow key={index}>
                      <TableCell width="20%" flexShrink={0} variant="simple">
                        <Text variant="medium">{line.flow.name}</Text>
                      </TableCell>
                      <TableCell width="20%" flexShrink={0} variant="simple">
                        <Text
                          variant="medium"
                          flexShrink={0}
                          color={
                            surveyColor(line.flowResultInputs[0].value) + '.500'
                          }
                        >
                          {surveyName(line.flowResultInputs[0].value)}
                        </Text>
                      </TableCell>
                      <TableCell width="100%" variant="simple">
                        <Text variant="medium">
                          {dayjs(line.createdAt).format('MMM D, YYYY, h:mm A')}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Table>

            {visibleCount < sortedTable.length && (
              <Button
                size="xs"
                colorScheme="purple"
                variant="outline"
                height="32px"
                width="100%"
                fontSize="14px"
                lineHeight="20px"
                margin="15px 0"
                onClick={() => setVisibleCount(visibleCount + 10)}
              >
                Load more
              </Button>
            )}
          </>
        )}
      </VStack>
    </>
  );
}
