import React, { useContext, useEffect } from 'react';
import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import { IClient } from '@app/store/schema';
import { DatepickerDropdown } from '@app/layout/dropdown/DatepickerDropdown';
import { Widget } from '@app/layout/widget/Widget';
import { AssessmentsWidget } from '@app/features/clients/details/widgets/assessments/AssessmentsWidget';
import { MoodWidget } from '@app/features/clients/details/widgets/mood/MoodWidget';
import { ExercisesTableWidget } from '@app/features/clients/details/widgets/ExercisesTableWidget';
import { MoodTableWidget } from '@app/features/clients/details/widgets/MoodTableWidget';
import { SummaryWidget } from '@app/features/clients/details/widgets/SummaryWidget';

interface WidgetsProps {
  client: IClient;
}

export function Widgets(props: WidgetsProps) {
  const { client } = props;
  const { store, loadData, loadDataByPeriod, setPeriod } =
    useContext(WidgetsContext);

  useEffect(() => {
    loadData(client);
  }, []);

  useEffect(() => {
    if (store.initiated) {
      loadDataByPeriod(store.period);
    }
  }, [store.initiated, store.period]);

  return (
    <VStack gap="35px" width="100%" alignItems="start">
      <DatepickerDropdown
        value={store.period}
        onChange={setPeriod}
        minWidth="224px"
      />

      <Widget width="100%">
        <SummaryWidget />
      </Widget>

      <Tabs variant="pill">
        <TabList justifyContent="space-between" alignItems="center">
          <HStack gap={0}>
            <Tab aria-label="Assessments">Assessments</Tab>
            <Tab aria-label="Mood">Mood</Tab>
            <Tab aria-label="Activities">Activities</Tab>
          </HStack>
        </TabList>
        <TabPanels mt="35px">
          <TabPanel>
            <Widget width="100%">
              <AssessmentsWidget />
            </Widget>
          </TabPanel>

          <TabPanel>
            <VStack gap="35px" width="100%" alignItems="start">
              <Widget width="100%">
                <MoodWidget />
              </Widget>

              <Widget width="100%">
                <MoodTableWidget />
              </Widget>
            </VStack>
          </TabPanel>

          <TabPanel>
            <Widget width="100%">
              <ExercisesTableWidget />
            </Widget>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
