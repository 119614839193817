import { gql } from 'graphql-request';

export const AcceptInvitationQuery = gql`
  query ($token: String!) {
    acceptInvitation(token: $token) {
      id
      user {
        email
      }
    }
  }
`;
