import {
  InputFragmentFragment,
  ScreenFragmentFragment,
} from '../../../../__generated__/graphql';
import { EditorReducerState, FlowFields } from './EditorReducer';

export enum ActionTypes {
  AddScreen = 'AddScreen',
  RemoveScreen = 'RemoveScreen',
  SwapScreen = 'SwapScreen',
  ChangeFlowProperty = 'ChangeFlowProperty',
  ChangeScreenProperty = 'ChangeScreenProperty',
  ChangeScreenInputProperty = 'ChangeScreenInputProperty',
  ChangeCtaLabel = 'ChangeCtaLabel',
  ReplaceState = 'ReplaceState',
}

export function changeFlowProperty(prop: FlowFields, value: string) {
  return {
    type: ActionTypes.ChangeFlowProperty as const,
    payload: { prop, value },
  };
}

export function addScreen(screenId: string, placement: 'before' | 'after') {
  return {
    type: ActionTypes.AddScreen as const,
    payload: { screenId, placement },
  };
}

export function removeScreen(screenId: string) {
  return {
    type: ActionTypes.RemoveScreen as const,
    payload: { screenId },
  };
}

export function swapScreen(activeScreenId: string, overScreenId: string) {
  return {
    type: ActionTypes.SwapScreen as const,
    payload: { activeScreenId, overScreenId },
  };
}

export function changeScreenProperty(
  screenId: string,
  prop: keyof ScreenFragmentFragment,
  value: string,
) {
  return {
    type: ActionTypes.ChangeScreenProperty as const,
    payload: { screenId, prop, value },
  };
}

export function changeScreenInputProperty(
  screenId: string,
  inputId: string,
  prop: keyof InputFragmentFragment | 'placeholder',
  value: string,
) {
  return {
    type: ActionTypes.ChangeScreenInputProperty as const,
    payload: { screenId, inputId, prop, value },
  };
}

export function changeScreenCtaLabel(screenId: string, label: string) {
  return {
    type: ActionTypes.ChangeCtaLabel as const,
    payload: { screenId, label },
  };
}

export function replaceState(state: EditorReducerState) {
  return {
    type: ActionTypes.ReplaceState as const,
    payload: state,
  };
}
