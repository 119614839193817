import 'react-device-frameset/styles/marvel-devices.min.css';
import {
  Provider,
  ScreenFragmentFragment,
  ScreenType,
} from '../../../__generated__/graphql';
import JournalingScreen from './screens/JournalingScreen';
import ContainerScreen from './screens/ContainerScreen';
import { FlowEditorScreenPropertyActions } from './FlowEditorApp';
import { EditorReducerState } from './reducer/EditorReducer';
import HomeScreen from './screens/HomeScreen';
import PreviewScreen from './screens/PreviewScreen';
import { PreviewState } from './FlowEditorSimpleApp';

interface EditorPreviewProps {
  screen: ScreenFragmentFragment | null;
  previewState: PreviewState;
  provider: Partial<Provider>;
  flow: EditorReducerState;
}

export default function EditorPreview({
  provider,
  flow,
  previewState,
  screen,
  onChangeScreenProperty,
  onChangeScreenCtaLabel,
  onChangeScreenInputProperty,
}: EditorPreviewProps & FlowEditorScreenPropertyActions) {
  if (!screen) return <div>no screen</div>;

  const renderPreview = (screen: ScreenFragmentFragment) => {
    if (previewState === PreviewState.HomeScreen) {
      return (
        <HomeScreen
          providerFirstName={provider.firstName}
          providerLastName={provider.lastName}
          type={flow.type}
          title={flow.name}
          description={flow.description}
        />
      );
    }

    switch (screen.screenType) {
      case ScreenType.JournalingScreen:
        return (
          <JournalingScreen
            screen={screen}
            onChangeScreenProperty={onChangeScreenProperty}
            onChangeScreenCtaLabel={onChangeScreenCtaLabel}
            onChangeScreenInputProperty={onChangeScreenInputProperty}
          />
        );
      case ScreenType.ContainerScreen:
        return (
          <ContainerScreen
            screen={screen}
            onChangeScreenProperty={onChangeScreenProperty}
            onChangeScreenCtaLabel={onChangeScreenCtaLabel}
            onChangeScreenInputProperty={onChangeScreenInputProperty}
          />
        );
      case ScreenType.PreviewScreen:
        return (
          <PreviewScreen
            screen={screen}
            onChangeScreenProperty={onChangeScreenProperty}
            onChangeScreenCtaLabel={onChangeScreenCtaLabel}
            onChangeScreenInputProperty={onChangeScreenInputProperty}
          />
        );

      default:
        return (
          <div style={{ padding: 50 }}>{screen.screenType} not implemented</div>
        );
    }
  };

  return (
    <div className="iphone white portrait">
      <div className="caseBorder"></div>
      <div className="case"></div>
      <div className="camera"></div>
      <div className="speaker"></div>
      <div className="homeButtonBorder"></div>
      <div className="homeButton"></div>
      <div className="content">{renderPreview(screen)}</div>
    </div>
  );
}
