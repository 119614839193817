import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { IClient, IProvider } from '@app/store/schema';
import {
  compose,
  emailValidator,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { FormInput } from '@app/layout/form/FormInput';
import { StoreContext } from '@app/store/StoreContext';
import { FormSelect } from '@app/layout/form/FormSelect';
import { usersToList } from '@app/utils/usersList';

interface InviteClientModalProps {
  client?: IClient;
  provider?: IProvider;
  isOpen: boolean;
  onClose(): void;
}

export function InviteClientModal(props: InviteClientModalProps) {
  const { client, provider, isOpen, onClose } = props;
  const { getUser, isAdminOrSupervisor, getProviders, inviteClient } =
    useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const providers = getProviders();
  const defaultProviderId = providers.length === 1 ? providers[0].id : '';

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        firstName: compose([
          requiredValidator(values.firstName),
          minLength(values.firstName, 2),
        ]),
        lastName: compose([
          requiredValidator(values.lastName),
          minLength(values.lastName, 2),
        ]),
        email: emailValidator(values.email),
        providerId: requiredValidator(values.providerId),
      }),
    initialValues: {
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      email: client?.user.email || '',
      phone: client?.user.phone || '',
      providerId: client?.providers[0].id || provider?.id || defaultProviderId,
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { client, errors } = await inviteClient(value);

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();
        navigate('/clients/' + client!.id + '/homework');
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const clearForm = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite new client</ModalHeader>
        <ModalCloseButton />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <Grid
                width="100%"
                gridColumnGap="15px"
                gap="20px"
                templateColumns="repeat(2, 1fr)"
              >
                <GridItem>
                  <FormInput
                    label="Client name*"
                    name="firstName"
                    placeholder="First name"
                    autoFocus={true}
                  />
                </GridItem>
                <GridItem>
                  <FormInput
                    label="&nbsp;"
                    name="lastName"
                    placeholder="Last name"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormInput
                    label="Client email*"
                    name="email"
                    placeholder="Email"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormInput
                    label="Client phone number"
                    name="phone"
                    placeholder="Phone number"
                  />
                </GridItem>

                {isAdminOrSupervisor(getUser()!) && (
                  <GridItem colSpan={2}>
                    <FormSelect
                      label="Assign to"
                      name="providerId"
                      placeholder="Select clinician"
                      items={usersToList(providers)}
                    />
                  </GridItem>
                )}
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button size="sm" mr="12px" onClick={clearForm}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                isLoading={isLoading}
              >
                Send invite
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
}
