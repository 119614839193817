import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from '@app/store/StoreContext';
import { Widgets } from '@app/features/clients/details/widgets/Widgets';
import { WidgetsProvider } from '@app/features/clients/details/widgets/WidgetsContext';

export function ClientProgress() {
  const params = useParams();
  const { getClientById } = useContext(StoreContext);

  const client = getClientById(params.id as string);

  return (
    <WidgetsProvider>
      <Widgets client={client!} />
    </WidgetsProvider>
  );
}
