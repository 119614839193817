import { v4 as uuidv4 } from 'uuid';
import { arrayMove } from '@dnd-kit/sortable';
import {
  AssetFragmentFragment,
  ButtonAction,
  ButtonFragmentFragment,
  FLowEditorQueryQuery,
  FlowType,
  InputInterfaceFragmentFragment,
  ScreenFragmentFragment,
  ScreenType,
} from '../../../../__generated__/graphql';

import {
  ActionTypes,
  addScreen,
  changeFlowProperty,
  changeScreenCtaLabel,
  changeScreenInputProperty,
  changeScreenProperty,
  removeScreen,
  replaceState,
  swapScreen,
} from './actions';
import { useFragment } from '../../../../__generated__/fragment-masking';
import {
  ButtonFragment,
  InputFragment,
  InputInterfaceFragment,
} from '../fragments/common';

const UNDO_STATE_CACHE = [];
const REDO_STATE_CACHE = [];
const MAX_CACHE_ENTRIES = [];

type FlowData = NonNullable<FLowEditorQueryQuery['loadFlow']>;

type FieldState = {
  dirty: boolean;
  error: string | null;
  initialValue: string | null | undefined;
};

type ScreenFieldState = {
  dirty: boolean;
  error: string | null;
  initialValue: string | null | undefined;
  property: ScreenFields;
};

type InputFieldState = {
  dirty: boolean;
  error: string | null;
  initialValue: string | null | undefined;
  property: InputFields;
};

const editableScreenFields = ['title', 'subtitle'] as const;
const editableInputFields = ['label', 'value', 'placeholder'] as const;

export type FlowFields = 'name' | 'description';
export type ScreenFields = 'title' | 'subtitle';
export type InputFields = 'label' | 'value' | 'placeholder';

export type EditorFieldsState = {
  flowFieldsState: {
    [Property in FlowFields]: FieldState;
  };
  screenFieldsState: {
    [key: string]: ScreenFieldState[];
  };
  inputFieldsState: {
    [key: string]: InputFieldState[];
  };
};

export type EditorReducerState = {
  fieldState: EditorFieldsState;
  screens: ScreenFragmentFragment[];
  asset?: AssetFragmentFragment | null;
} & FlowData;

type PossibleActions = ReturnType<
  | typeof replaceState
  | typeof changeFlowProperty
  | typeof changeScreenCtaLabel
  | typeof changeScreenProperty
  | typeof changeScreenInputProperty
  | typeof addScreen
  | typeof removeScreen
  | typeof swapScreen
>;

type EditorReducerData<Type> = {
  [Property in keyof Type as Exclude<Property, 'fieldState'>]: Type[Property];
};

export function editorReducerInit(
  state: EditorReducerData<EditorReducerState>,
): EditorReducerState {
  return { ...state, fieldState: computeInitialFieldState(state) };
}

export function computeInitialFieldState(
  state: EditorReducerData<EditorReducerState>,
): EditorFieldsState {
  const initialFlowFieldState = ({ initialValue }: Partial<FieldState>) => {
    return {
      dirty: false,
      error: null,
      initialValue,
    };
  };

  const initialScreenFieldsState = (
    state: EditorReducerData<EditorReducerState>,
  ) => {
    const screenFieldsState = {} as EditorFieldsState['screenFieldsState'];

    state.screens.forEach((screen) => {
      editableScreenFields.forEach((field) => {
        if (!screenFieldsState[screen.id]) {
          screenFieldsState[screen.id] = [];
        }

        screenFieldsState[screen.id].push({
          dirty: false,
          error: null,
          initialValue: screen[field],
          property: field,
        });
      });
    });

    return screenFieldsState;
  };

  const initialInputFieldsState = (
    state: EditorReducerData<EditorReducerState>,
  ) => {
    const inputFieldsState = {} as EditorFieldsState['inputFieldsState'];

    state.screens.forEach((screen) => {
      const inputs = useFragment(InputInterfaceFragment, screen.inputs);
      inputs?.forEach((input) => {
        editableInputFields.forEach((field) => {
          if (!inputFieldsState[input.id]) {
            inputFieldsState[input.id] = [];
          }

          inputFieldsState[input.id].push({
            dirty: false,
            error: null,
            // @ts-expect-error ...
            initialValue: input[field],
            property: field,
          });
        });
      });

      const cta = useFragment(InputFragment, screen.cta);

      if (cta) {
        ['label' as const].forEach((field) => {
          if (!inputFieldsState[cta.id]) {
            inputFieldsState[cta.id] = [];
          }

          inputFieldsState[cta.id].push({
            dirty: false,
            error: null,
            initialValue: cta[field],
            property: field,
          });
        });
      }
    });

    return inputFieldsState;
  };

  return {
    flowFieldsState: {
      name: initialFlowFieldState({ initialValue: state.name }),
      description: initialFlowFieldState({ initialValue: state.description }),
    },
    screenFieldsState: initialScreenFieldsState(state),
    inputFieldsState: initialInputFieldsState(state),
  };
}

const recomputeScreenFieldsState = (state: EditorReducerState) => {
  const screenFieldsState = {} as EditorFieldsState['screenFieldsState'];

  state.screens.forEach((screen) => {
    editableScreenFields.forEach((field) => {
      if (!screenFieldsState[screen.id]) {
        screenFieldsState[screen.id] = [];
      }

      if (state.fieldState.screenFieldsState[screen.id]) {
        screenFieldsState[screen.id] = [
          ...state.fieldState.screenFieldsState[screen.id],
        ];

        return;
      }

      screenFieldsState[screen.id].push({
        dirty: false,
        error: null,
        initialValue: screen[field],
        property: field,
      });
    });
  });

  return screenFieldsState;
};

const recomputeInputFieldsState = (state: EditorReducerState) => {
  const inputFieldsState = {} as EditorFieldsState['inputFieldsState'];

  state.screens.forEach((screen) => {
    const inputs = useFragment(InputInterfaceFragment, screen.inputs);
    inputs?.forEach((input) => {
      editableInputFields.forEach((field) => {
        if (!inputFieldsState[input.id]) {
          inputFieldsState[input.id] = [];
        }

        if (state.fieldState.inputFieldsState[input.id]) {
          inputFieldsState[input.id] = [
            ...state.fieldState.inputFieldsState[input.id],
          ];

          return;
        }

        inputFieldsState[input.id].push({
          dirty: false,
          error: null,
          // @ts-expect-error ...
          initialValue: input[field],
          property: field,
        });
      });
    });

    const cta = useFragment(InputFragment, screen.cta);

    if (cta) {
      ['label' as const].forEach((field) => {
        if (!inputFieldsState[cta.id]) {
          inputFieldsState[cta.id] = [];
        }

        if (state.fieldState.inputFieldsState[cta.id]) {
          inputFieldsState[cta.id] = [
            ...state.fieldState.inputFieldsState[cta.id],
          ];

          return;
        }

        inputFieldsState[cta.id].push({
          dirty: false,
          error: null,
          initialValue: cta[field],
          property: field,
        });
      });
    }
  });

  return inputFieldsState;
};

export function getDirtyFields(state: EditorReducerState) {
  const dirtyFields = [];

  for (const [prop, fieldState] of Object.entries(
    state.fieldState.flowFieldsState,
  )) {
    if (fieldState.dirty) {
      dirtyFields.push(fieldState);
    }
  }

  for (const [screenId, fieldState] of Object.entries(
    state.fieldState.screenFieldsState,
  )) {
    if (fieldState) {
      console.log({ fieldState });
      fieldState
        .filter((x) => x.dirty)
        .forEach((field) => dirtyFields.push(field));
    }
  }

  for (const [inputId, fieldState] of Object.entries(
    state.fieldState.inputFieldsState,
  )) {
    if (fieldState) {
      fieldState
        .filter((x) => x.dirty)
        .forEach((field) => dirtyFields.push(field));
    }
  }

  return dirtyFields;
}

export function EditorReducer(
  state: EditorReducerState,
  action: PossibleActions,
): EditorReducerState {
  console.log(action.type, action.payload, state);

  switch (action.type) {
    case ActionTypes.ChangeFlowProperty:
      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          flowFieldsState: {
            ...state.fieldState.flowFieldsState,
            [action.payload.prop]: {
              ...state.fieldState.flowFieldsState[action.payload.prop],
              error:
                action.payload.value.length < 2
                  ? 'Activity name can not be shorter than two characters'
                  : null,
              dirty:
                action.payload.value !==
                state.fieldState.flowFieldsState[action.payload.prop]
                  .initialValue,
            } satisfies FieldState,
          },
        },
        [action.payload.prop]: action.payload.value,
      };

    case ActionTypes.ChangeScreenProperty:
      if (!state.screens.find((x) => x.id === action.payload.screenId)) {
        console.error(
          `${action.payload.screenId} does not exists in the state`,
        );
        return state;
      }

      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          screenFieldsState: {
            ...state.fieldState.screenFieldsState,
            [action.payload.screenId]: [
              ...state.fieldState.screenFieldsState[
                action.payload.screenId
              ].filter((x) => x.property !== action.payload.prop),
              {
                ...state.fieldState.screenFieldsState[
                  action.payload.screenId
                ].find((x) => x.property === action.payload.prop)!,
                dirty: true,
              },
            ],
          },
        },
        screens: [
          ...exclude<ScreenFragmentFragment>(
            action.payload.screenId,
            state.screens,
          ),
          {
            ...findById<ScreenFragmentFragment>(
              action.payload.screenId,
              state.screens,
            ),
            [action.payload.prop]: action.payload.value,
          },
        ].sort((a, b) => a.order - b.order),
      };

    case ActionTypes.ChangeScreenInputProperty:
      const screen = findById<ScreenFragmentFragment>(
        action.payload.screenId,
        state.screens,
      );

      if (!screen.inputs) return state;

      const inputs = useFragment(
        InputInterfaceFragment,
        screen.inputs,
      ) as InputInterfaceFragmentFragment[];

      if (!inputs.find((x) => x.id === action.payload.inputId)) {
        console.error(`${action.payload.inputId} does not exists in the state`);
        return state;
      }

      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          inputFieldsState: {
            ...state.fieldState.inputFieldsState,
            [action.payload.inputId]: [
              ...state.fieldState.inputFieldsState[
                action.payload.inputId
              ].filter((x) => x.property !== action.payload.prop),
              {
                ...state.fieldState.inputFieldsState[
                  action.payload.inputId
                ].find((x) => x.property === action.payload.prop)!,
                dirty: true,
              },
            ],
          },
        },
        screens: [
          ...exclude<ScreenFragmentFragment>(
            action.payload.screenId,
            state.screens,
          ),
          {
            ...screen,
            inputs: [
              ...exclude<InputInterfaceFragmentFragment>(
                action.payload.inputId,
                inputs,
              ),
              {
                ...findById<InputInterfaceFragmentFragment>(
                  action.payload.inputId,
                  inputs,
                ),
                [action.payload.prop]: action.payload.value,
              },
            ],
          } as ScreenFragmentFragment,
        ].sort((a, b) => a.order - b.order),
      };

    case ActionTypes.ChangeCtaLabel:
      const edited = findById<ScreenFragmentFragment>(
        action.payload.screenId,
        state.screens,
      );

      const cta = useFragment(InputFragment, edited.cta);

      if (!cta) return state;

      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          inputFieldsState: {
            ...state.fieldState.inputFieldsState,
            [cta.id]: [
              ...state.fieldState.inputFieldsState[cta.id].filter(
                (x) => x.property !== 'label',
              ),
              {
                ...state.fieldState.inputFieldsState[cta.id].find(
                  (x) => x.property === 'label',
                )!,
                dirty: true,
              },
            ],
          },
        },
        screens: [
          ...exclude<ScreenFragmentFragment>(
            action.payload.screenId,
            state.screens,
          ),
          {
            ...edited,
            // @ts-expect-error fuck fragment masking
            cta: { ...edited.cta, label: action.payload.label },
          } as ScreenFragmentFragment,
        ].sort((a, b) => a.order - b.order),
      };
    case ActionTypes.ReplaceState:
      return editorReducerInit(action.payload);

    case ActionTypes.AddScreen:
      const screenIdx =
        state.screens.findIndex((x) => x.id === action.payload.screenId) +
        (action.payload.placement === 'after' ? 1 : 0);

      const intermediateState = {
        ...state,
        screens: reLinkScreens(
          [
            ...state.screens.slice(0, screenIdx),
            makeJournalingScreen({ flowId: state.id }),
            ...state.screens.slice(screenIdx, state.screens.length),
          ],
          state,
          { fixOrder: true, fixNavbarTitle: true },
        ),
      };

      const addScreenState = {
        ...intermediateState,
        fieldState: {
          ...intermediateState.fieldState,
          screenFieldsState: recomputeScreenFieldsState(intermediateState),
          inputFieldsState: recomputeInputFieldsState(intermediateState),
        },
      };

      console.log('>>>AddScreen', addScreenState);

      return addScreenState;

    case ActionTypes.RemoveScreen:
      const removeScreenState = {
        ...state,
        screens: reLinkScreens(
          [
            ...exclude<ScreenFragmentFragment>(
              action.payload.screenId,
              state.screens,
            ),
          ],
          state,
          { fixOrder: true, fixNavbarTitle: true },
        ).sort((a, b) => a.order - b.order),
      };

      console.log('>>>RemoveScreen', removeScreenState);

      return removeScreenState;

    case ActionTypes.SwapScreen:
      const screenAIdx = state.screens.findIndex(
        (x) => x.id === action.payload.activeScreenId,
      )!;

      const screenBIdx = state.screens.findIndex(
        (x) => x.id === action.payload.overScreenId,
      )!;

      const swapScreenState = {
        ...state,
        screens: reLinkScreens(
          arrayMove(
            state.screens,
            screenAIdx,
            screenBIdx,
          ) as ScreenFragmentFragment[],
          state,
          {
            fixOrder: true,
            fixNavbarTitle: true,
          },
        ),
      };

      console.log('>>>SwapScreen', swapScreenState);

      return swapScreenState;

    default:
      return state;
  }
}

function exclude<T extends object>(id: string, list: T[]) {
  return list.filter((x) => 'id' in x && x.id !== id);
}

function findById<T extends object>(id: string, screens: T[]) {
  return screens.find((x) => 'id' in x && x.id === id)!;
}

export function reLinkScreens(
  screens: ScreenFragmentFragment[],
  state: EditorReducerState,
  opts?: { fixOrder?: boolean; fixNavbarTitle?: boolean },
) {
  const navbarHeaderRegex = /\d of \d/;
  let navbarCounter = 1;
  const totalScreensWithNavbarCount = screens.filter(
    (x) =>
      x.navigationBar?.header && navbarHeaderRegex.test(x.navigationBar.header),
  ).length;

  return screens.map((screen, idx) => {
    let newScreen = { ...screen };

    const leftItem = useFragment(
      ButtonFragment,
      screen.navigationBar?.leftItem,
    );

    const cta = useFragment(ButtonFragment, screen.cta);

    if (
      opts?.fixNavbarTitle &&
      newScreen?.navigationBar?.header &&
      navbarHeaderRegex.test(newScreen.navigationBar.header)
    ) {
      newScreen = {
        ...newScreen,
        navigationBar: {
          ...newScreen.navigationBar,
          header: opts?.fixNavbarTitle
            ? `${navbarCounter} of ${totalScreensWithNavbarCount}`
            : newScreen.navigationBar?.header,
        },
      };
      navbarCounter++;
    }

    if (idx === 0) {
      newScreen = {
        ...newScreen,
        navigationBar: {
          ...newScreen.navigationBar,
          leftItem: {
            ...leftItem,
            action: ButtonAction.Close,
          } as ButtonFragmentFragment,
        },
      };
    }

    // Re-link navbar BACK button
    if (
      idx !== 0 &&
      idx !== screens.length &&
      leftItem?.action &&
      screens[idx - 1]?.id &&
      [
        ButtonAction.BackToScreen,
        ButtonAction.NavigateToScreen,
        ButtonAction.SaveToServerAndNavigateToScreen,
      ].includes(leftItem.action)
    ) {
      newScreen = {
        ...newScreen,
        navigationBar: {
          ...newScreen.navigationBar,
          leftItem: {
            ...leftItem,
            value: screens[idx - 1].id,
          } as ButtonFragmentFragment,
        },
      };
    }

    // Re-link CTAs
    if (
      idx !== screens.length &&
      cta?.action &&
      screens[idx + 1]?.id &&
      [
        ButtonAction.NavigateToScreen,
        ButtonAction.SaveToServerAndNavigateToScreen,
      ].includes(cta.action)
    ) {
      const inputCta = useFragment(InputFragment, screen.cta)!;
      const isCtaDirty = state.fieldState.inputFieldsState[inputCta.id]?.find(
        (x) => x.property === 'label',
      )?.dirty;
      const newCtaLabel =
        screens[idx + 1]?.screenType === ScreenType.CongratulationsScreen
          ? 'Submit'
          : 'Next';
      newScreen = {
        ...newScreen,
        cta: {
          ...cta,
          label: isCtaDirty ? inputCta.label : newCtaLabel,
          value: screens[idx + 1].id,
          action:
            screens[idx + 1]?.screenType === ScreenType.CongratulationsScreen
              ? ButtonAction.SaveToServerAndNavigateToScreen
              : ButtonAction.NavigateToScreen,
        } as ButtonFragmentFragment,
      };
    }

    if (opts?.fixOrder) {
      newScreen = {
        ...newScreen,
        order: idx + 1,
      };
    }

    return newScreen;
  });
}

function makeJournalingScreen({
  screenId,
  flowId,
}: {
  screenId?: string;
  flowId: string;
}) {
  return {
    __typename: 'JournalingScreen',
    id: screenId ?? uuidv4(),
    screenType: ScreenType.JournalingScreen,
    version: '0.0.1',
    order: 1,
    flowId: flowId,
    flowType: FlowType.Journal,
    title: 'Write your prompt here...',
    subtitle: null,
    inputs: [
      {
        __typename: 'TextArea',
        // @ts-expect-error fragment masking
        id: uuidv4(),
        label: 'Description...',
        value: null,
        placeholder: 'Type here',
      },
    ],
    cta: {
      __typename: 'Button',
      // @ts-expect-error fragment masking
      id: uuidv4(),
      label: 'Next',
      value: uuidv4(),
      action: 'NAVIGATE_TO_SCREEN',
      asset: null,
    },
    navigationBar: {
      __typename: 'NavigationBar',
      leftItem: {
        __typename: 'Button',
        // @ts-expect-error fragment masking
        id: uuidv4(),
        label: null,
        value: uuidv4(),
        action: 'BACK_TO_SCREEN',
        asset: {
          __typename: 'Asset',
          id: uuidv4(),
          type: 'ICON',
          icon: 'ARROW_LEFT',
          url: null,
        },
      },
      header: '1 of 5',
      rightItem: {
        __typename: 'Button',
        // @ts-expect-error fragment masking
        id: uuidv4(),
        label: null,
        value: null,
        action: 'CLOSE',
        asset: {
          __typename: 'Asset',
          id: uuidv4(),
          type: 'ICON',
          icon: 'CLOSE',
          url: null,
        },
      },
    },
  } satisfies ScreenFragmentFragment;
}
