import React, { useContext, useEffect, useState } from 'react';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { WidgetPlaceholder } from '@app/layout/widget/WidgetPlaceholder';
import { WidgetHeader } from '@app/layout/widget/WidgetHeader';

import { graphQLClient } from '@app/store/client';
import { ClientSummaryMutation } from '@app/store/queries/widgets/clientSummary';
import { WidgetsContext } from './WidgetsContext';

import { FormikProvider, useFormik } from 'formik';
import { Skeleton, Button, Text, VStack, Box, HStack } from '@chakra-ui/react';

import { FormInput } from '@app/layout/form/FormInput';
import {
  compose,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';

interface ISummaryResponse {
  aiClientSummary: SummaryData;
}

interface SummaryData {
  createdAt?: string;
  fingerprint?: string;
  text?: string;
}

// Testing staging deploy...1...2...3

export function SummaryWidget() {
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [isFollowUpUsed, setFollowUpUsed] = useState(false);
  const { store } = useContext(WidgetsContext);

  const loadData = async (followUp?: string) => {
    setLoading(true);

    const { data } = await graphQLClient.rawRequest<ISummaryResponse>(
      ClientSummaryMutation,
      {
        clientId: store.client?.id,
        followUp,
      },
    );

    const { aiClientSummary } = data;

    setText(aiClientSummary ? aiClientSummary.text || '' : '');
    setLoading(false);
  };

  useEffect(() => {
    if (store.client?.id) {
      loadData();
    }
  }, [store.client?.id]);

  const followUpEnabled = false;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      followUp: '',
    },
    validate: (values: any) =>
      getErrors(values, {
        followUp: compose([
          requiredValidator(values.followUp),
          minLength(values.followUp, 20),
        ]),
      }),
    onSubmit: async (data) => {
      await loadData(data.followUp);
      setFollowUpUsed(true);
    },
  });

  const renderFollowUpForm = () => (
    <FormikProvider value={formik}>
      <Box as="form" onSubmit={formik.handleSubmit} width="100%">
        <VStack spacing="30px" alignItems="start">
          <HStack spacing="20px" alignItems="start" width="100%">
            <FormInput
              name="followUp"
              placeholder="Ask a follow-up question"
              size="lg"
            />
            <Box>
              <Button type="submit" variant="primary" width="full">
                Send
              </Button>
            </Box>
          </HStack>
        </VStack>
      </Box>
    </FormikProvider>
  );

  return (
    <>
      <WidgetHeader
        title="Updates"
        variant="xl"
        icon={MdOutlineTipsAndUpdates}
        iconColor="green.500"
      />

      <VStack width="100%" gap="15px" alignItems="start">
        {loading && (
          <>
            <Skeleton height="24px" width="100%" />
            <Skeleton height="24px" width="100%" />
            <Skeleton height="24px" width="100%" />
            <Skeleton height="24px" width="100%" />
          </>
        )}

        {!loading && (!text || text.length === 0) && <WidgetPlaceholder />}

        {!loading && text && text.length > 0 && (
          <Text whiteSpace={'pre-wrap'}>{text}</Text>
        )}

        {followUpEnabled &&
          !isFollowUpUsed &&
          !loading &&
          text &&
          text.length > 0 &&
          renderFollowUpForm()}

        <Text size="sm" color="gray.500">
          This summary is intended for guidance only and should not replace the
          clinical judgment of qualified clinicians in making care decisions.
        </Text>
      </VStack>
    </>
  );
}
