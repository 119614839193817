import {
  Editable,
  EditableTextarea,
  EditablePreview,
  Box,
} from '@chakra-ui/react';
import { ScreenFragmentFragment } from '../../../../__generated__/graphql';
import { useFragment } from '../../../../__generated__/fragment-masking';
import {
  InputFragment,
  InputInterfaceFragment,
  TextAreaFragment,
} from '../fragments/common';
import { FlowEditorScreenPropertyActions } from '../FlowEditorApp';
import PhoneFrame from '../components/PhoneFrame';
import { EditableTitle } from '../components/EditableTitle';
import NavigationBar from '../components/NavigationBar';
import EditableCta from '../components/EditableCta';

interface JournalingScreenProps {
  screen: ScreenFragmentFragment;
}

export default function JournalingScreen({
  screen,
  onChangeScreenProperty,
  onChangeScreenInputProperty,
  onChangeScreenCtaLabel,
}: JournalingScreenProps & FlowEditorScreenPropertyActions) {
  const inputs = useFragment(InputInterfaceFragment, screen.inputs);
  const cta = useFragment(InputFragment, screen.cta);

  const firstInput = inputs?.[0];
  const input =
    firstInput?.__typename === 'TextArea'
      ? useFragment(TextAreaFragment, firstInput)
      : null;

  return (
    <PhoneFrame appPadding>
      {screen.navigationBar && (
        <NavigationBar navBar={screen.navigationBar as any} />
      )}
      <EditableTitle
        initialValue={screen.title ?? ''}
        onSubmit={(val) => onChangeScreenProperty(screen.id, 'title', val)}
      />
      <Editable
        value={screen.subtitle ?? ''}
        onChange={(val) => onChangeScreenProperty(screen.id, 'subtitle', val)}
      >
        <EditablePreview />
        <EditableTextarea />
      </Editable>
      {firstInput?.label && (
        <Box color="rgb(127, 130, 148)">
          <Editable
            value={firstInput?.label ?? ''}
            onChange={(val) =>
              onChangeScreenInputProperty(
                screen.id,
                firstInput.id,
                'label',
                val,
              )
            }
          >
            <EditablePreview />
            <EditableTextarea />
          </Editable>
        </Box>
      )}
      {input?.placeholder && (
        <Box color="#4C4E59">
          <Editable
            value={input.placeholder}
            onChange={(val) =>
              onChangeScreenInputProperty(
                screen.id,
                firstInput!.id!,
                'placeholder',
                val,
              )
            }
          >
            <EditablePreview />
            <EditableTextarea />
          </Editable>
        </Box>
      )}
      <Box position="absolute" bottom={0} right={0}>
        <EditableCta
          value={cta?.label ?? ''}
          small
          onChange={(val) => onChangeScreenCtaLabel(screen.id, val)}
        />
      </Box>
    </PhoneFrame>
  );
}
