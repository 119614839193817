import { gql } from 'graphql-request';

export const ClientSummaryMutation = gql`
  mutation (
    $clientId: String!
    $regenerate: Boolean
    $fromDate: DateTime
    $toDate: DateTime
    $followUp: String
  ) {
    aiClientSummary(
      data: {
        clientId: $clientId
        regenerate: $regenerate
        fromDate: $fromDate
        toDate: $toDate
        followUp: $followUp
      }
    ) {
      id
      clientId
      createdAt
      fingerprint
      text
    }
  }
`;
