import { gql } from 'graphql-request';

export const OrganizationQuery = gql`
  fragment BadgeFragment on CardBadge {
    text
    status
  }

  fragment AssetFragment on Asset {
    type
    icon
    url
  }

  fragment AssessmentResultFragment on AssessmentResult {
    id
    title
    titleShort
    titleFull
    type
    date
    dateShort
    badge {
      ...BadgeFragment
    }
    dataPoint {
      label
      timestamp
      value
    }
    scoreMovement {
      text
      asset {
        ...AssetFragment
      }
    }
  }

  query {
    organization {
      id
      domain
      logo
      name
      providers {
        id
        firstName
        lastName
        credentials
        status
        invitedAt
        user {
          id
          email
          phone
          role
          activeAt
        }
        supervisors {
          id
        }
        subordinates {
          id
        }
      }
      clients {
        id
        firstName
        lastName
        status
        isHighRisk
        latestAssessmentResult {
          ...AssessmentResultFragment
        }
        dischargedAt
        dischargedByProvider {
          id
        }
        providers {
          id
        }
        user {
          id
          email
          phone
          role
          activeAt
        }
      }
    }
  }
`;
