import React, { useCallback, useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Button,
  Checkbox,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IProvider, TUserRole } from '@app/store/schema';
import { FormInput } from '@app/layout/form/FormInput';
import { FormSelect } from '@app/layout/form/FormSelect';
import { FormMultiSelect } from '@app/layout/form/FormMultiSelect';
import { userRolesList } from '@app/utils/userRole';
import { StoreContext } from '@app/store/StoreContext';
import { usersToList } from '@app/utils/usersList';
import {
  compose,
  emailValidator,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { ProviderRequest, Response } from '@app/store/schema-response';

interface ProviderModalProps {
  provider?: IProvider;
  isOpen: boolean;
  onClose(): void;
}

export function ProviderModal(props: ProviderModalProps) {
  const { provider, isOpen, onClose } = props;
  const {
    getProviders,
    isAdminOrSupervisor,
    isClinician,
    inviteProvider,
    updateProvider,
  } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const admins = getProviders().filter(
    (item) => isAdminOrSupervisor(item) && item.id !== provider?.id,
  );
  const clinician = getProviders().filter(
    (item) =>
      (isClinician(item) && item.supervisors.length === 0) ||
      (provider &&
        item.supervisors.find((supervisor) => supervisor.id === provider?.id)),
  );

  let title = 'Invite team member';
  let button = 'Send invite';

  if (provider && provider.status === 'ACTIVE') {
    title = 'Edit info';
    button = 'Save';
  }

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        firstName: compose([
          requiredValidator(values.firstName),
          minLength(values.firstName, 2),
        ]),
        lastName: compose([
          requiredValidator(values.lastName),
          minLength(values.lastName, 2),
        ]),
        credentials: requiredValidator(values.credentials),
        email: emailValidator(values.email),
        role: requiredValidator(values.role),
        supervisorId:
          values.role === 'CLINICIAN' && values.isSupervisorRequired
            ? requiredValidator(values.supervisorId)
            : null,
      }),
    initialValues: {
      firstName: provider?.firstName || '',
      lastName: provider?.lastName || '',
      credentials: provider?.credentials || '',
      email: provider?.user.email || '',
      phone: provider?.user.phone || '',
      role: provider?.user.role || '',
      isSupervisorRequired: provider && provider.supervisors.length > 0,
      supervisorId:
        provider && provider.supervisors.length > 0
          ? provider?.supervisors[0].id
          : '',
      subordinateIds:
        provider && provider.subordinates.length > 0
          ? provider.subordinates.map((item) => item.id)
          : [],
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const params: ProviderRequest = {
        firstName: value.firstName,
        lastName: value.lastName,
        credentials: value.credentials,
        email: value.email,
        phone: null,
        role: value.role as TUserRole,
      };

      let result: Response = {};

      if (provider) {
        const currentSupervisorId = provider.supervisors.map((item) => item.id);
        const newSupervisorId =
          value.supervisorId && value.isSupervisorRequired
            ? [value.supervisorId]
            : [];

        const toAddSupervisorId = newSupervisorId.filter(
          (id) => currentSupervisorId.indexOf(id) === -1,
        );

        if (toAddSupervisorId.length > 0) {
          // @fixme
          params.supervisorId = toAddSupervisorId[0];
        }

        const toRemoveSupervisorId = currentSupervisorId.filter(
          (id) => newSupervisorId.indexOf(id) === -1,
        );

        if (toRemoveSupervisorId.length > 0) {
          // @fixme
          params.disconnectSupervisorId = toRemoveSupervisorId[0];
        }

        if (currentSupervisorId.length > 0 && newSupervisorId.length === 0) {
          params.disconnectSupervisorId = null;
        }

        const currentSubordinateIds = provider.subordinates.map(
          (item) => item.id,
        );
        const newSubordinateIds = value.subordinateIds || [];

        const toAddSubordinateIds = newSubordinateIds.filter(
          (id) => currentSubordinateIds.indexOf(id) === -1,
        );

        if (toAddSubordinateIds.length > 0) {
          params.subordinateIds = toAddSubordinateIds;
        }

        const toRemoveSubordinateIds = currentSubordinateIds.filter(
          (id) => newSubordinateIds.indexOf(id) === -1,
        );

        if (toRemoveSubordinateIds.length > 0) {
          params.disconnectSubordinateIds = toRemoveSubordinateIds;
        }

        if (
          currentSubordinateIds.length > 0 &&
          newSubordinateIds.length === 0
        ) {
          params.disconnectSubordinateIds = null;
        }

        result = await updateProvider(provider.id, params);
      } else {
        if (value.role === 'CLINICIAN' && value.isSupervisorRequired) {
          params.supervisorId = value.supervisorId;
        }

        if (value.role !== 'CLINICIAN') {
          params.subordinateIds = value.subordinateIds;
        }

        result = await inviteProvider(params);
      }

      const { errors } = result;

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const clearForm = useCallback(() => {
    formik.resetForm();
    onClose();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <Grid width="100%" gridColumnGap="15px" gap="20px">
                <GridItem colSpan={2}>
                  <VStack gap="15px" alignItems="start">
                    <FormSelect
                      label="Role*"
                      name="role"
                      placeholder="Choose role"
                      items={userRolesList}
                    />

                    {formik.values.role === 'CLINICIAN' && (
                      <>
                        <Checkbox
                          name="isSupervisorRequired"
                          size="lg"
                          colorScheme="purple"
                          onChange={formik.handleChange}
                          isChecked={formik.values.isSupervisorRequired}
                        >
                          <Text size="sm" variant="medium">
                            Requires supervision
                          </Text>
                        </Checkbox>

                        {formik.values.isSupervisorRequired && (
                          <FormSelect
                            name="supervisorId"
                            placeholder="Choose supervisor"
                            items={usersToList(admins)}
                          />
                        )}
                      </>
                    )}
                  </VStack>
                </GridItem>

                {(formik.values.role === 'ADMIN' ||
                  formik.values.role === 'SUPERVISOR') &&
                  clinician.length > 0 && (
                    <GridItem colSpan={2}>
                      <FormMultiSelect
                        label="Team members to supervise"
                        name="subordinateIds"
                        placeholder="Select clinicians"
                        items={usersToList(clinician)}
                      />
                    </GridItem>
                  )}

                <GridItem>
                  <FormInput
                    label="Name*"
                    name="firstName"
                    placeholder="First name"
                  />
                </GridItem>
                <GridItem>
                  <FormInput
                    label="&nbsp;"
                    name="lastName"
                    placeholder="Last name"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormInput
                    label="Credentials*"
                    name="credentials"
                    placeholder="Credentials"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <VStack gap="15px">
                    <FormInput
                      label="Contact info*"
                      name="email"
                      placeholder="Email"
                    />
                  </VStack>
                </GridItem>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" mr="12px" onClick={clearForm}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                isLoading={isLoading}
              >
                {button}
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
}
