import React, { useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Button, Heading, Text, useToast, VStack } from '@chakra-ui/react';
import { emailValidator, getErrors } from '@app/utils/validators';
import { FormInput } from '@app/layout/form/FormInput';
import { StoreContext } from '@app/store/StoreContext';

export function SettingsSecurity() {
  const { initiateResetPassword } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const toast = useToast();

  const resend = async (email: string) => {
    setIsLoading(true);

    const { errors } = await initiateResetPassword({
      email,
    });

    setIsLoading(false);

    if (errors) {
      errors.map((error) =>
        toast({
          title: error.message,
          variant: 'error',
        }),
      );
    } else {
      setIsSent(true);
    }
  };

  const formik = useFormik({
    validate: (values: any) =>
      getErrors(values, {
        email: emailValidator(values.email),
      }),
    initialValues: {
      email: '',
    },
    onSubmit: async (value) => {
      await resend(value.email);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <VStack gap="25px" alignItems="start" pr="30px" pl="30px">
          <Heading size="md">Account security</Heading>

          {!isSent && (
            <>
              <VStack gap="20px" alignItems="start">
                <Heading size="sm">Reset password</Heading>

                <Text variant="medium">
                  Enter your email address and if it is associated with your
                  account we will send a link to reset your password.
                </Text>

                <FormInput
                  name="email"
                  placeholder="Email"
                  autoFocus={true}
                  width="500px"
                />
              </VStack>

              <Button
                type="submit"
                variant="primary"
                size="md"
                isLoading={isLoading}
              >
                Send a link
              </Button>
            </>
          )}

          {isSent && (
            <>
              <VStack spacing="20px" alignItems="start">
                <Heading size="sm">Check your email</Heading>

                <Text>
                  You will receive an email from us with a link to reset your
                  password. If you don’t receive an email, please check your
                  spam folder.
                </Text>
              </VStack>

              <Button
                variant="primary"
                size="md"
                isLoading={isLoading}
                onClick={async () => resend(formik.values.email)}
              >
                Resend a link
              </Button>
            </>
          )}
        </VStack>
      </form>
    </FormikProvider>
  );
}
