import React, { useCallback } from 'react';
import { Button, Heading, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { ProviderModal } from '@app/features/providers/modals/ProviderModal';

export function ProvidersPendingPlaceholder() {
  const providerModal = useDisclosure();

  const inviteProvider = useCallback(() => {
    providerModal.onOpen();
  }, []);

  return (
    <>
      <TabPanelContent>
        <VStack gap="12px" width="100%" p="13px 25px">
          <Heading size="sm" color="gray.500" as="h3">
            No pending team members
          </Heading>
          <Text color="gray.500">
            Team members who got an invite but haven't accepted yet will show up
            here.
          </Text>

          <Button
            size="sm"
            colorScheme="purple"
            variant="outline"
            leftIcon={<AddIcon />}
            onClick={inviteProvider}
          >
            Invite team member
          </Button>
        </VStack>
      </TabPanelContent>

      <ProviderModal
        isOpen={providerModal.isOpen}
        onClose={providerModal.onClose}
      />
    </>
  );
}
