import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IClient } from '@app/store/schema';
import { StoreContext } from '@app/store/StoreContext';

interface DischargeClientModalProps {
  client: IClient;
  isOpen: boolean;
  onClose(): void;
}

export function DischargeClientModal(props: DischargeClientModalProps) {
  const { client, isOpen, onClose } = props;
  const { dischargeClient } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const toast = useToast();

  if (!client) {
    return <></>;
  }

  const submit = async () => {
    setIsLoading(true);

    const { errors } = await dischargeClient(client.id);

    setIsLoading(false);

    if (errors && errors.length) {
      errors.map((error) =>
        toast({
          title: error.message,
          variant: 'error',
        }),
      );
    } else {
      clearForm();
      toast({
        title: 'Changes saved',
        variant: 'success',
      });
    }
  };

  const clearForm = () => {
    setIsApproved(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Discharge client</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap="20px" width="100%" alignItems="start">
            <Text size="lg" variant="medium">
              Are you sure you want to discharge this client? After your client
              has been discharged, they will still be able to access their
              progress data to date and previously assigned exercises.
            </Text>

            <Checkbox
              size="lg"
              colorScheme="purple"
              isChecked={isApproved}
              onChange={() => setIsApproved(!isApproved)}
            >
              I am sure
            </Checkbox>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" mr="12px" onClick={clearForm}>
            Cancel
          </Button>

          <Button
            size="sm"
            colorScheme={isApproved ? 'red' : 'gray'}
            color={isApproved ? 'white' : 'gray.400'}
            isDisabled={!isApproved}
            isLoading={isLoading}
            onClick={submit}
          >
            Discharge client
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
