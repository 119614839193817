import React from 'react';
import { rrulestr } from 'rrule';
import { Button, forwardRef, Menu, MenuButton } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FrequencyDropdownOptions } from '@app/layout/dropdown/FrequencyDropdownOptions';

export const FrequencyDropdown = forwardRef((props, ref) => {
  const { value, rrule, onChange, ...rest } = props;

  let title = '';

  if (value === 'FREE') {
    title = 'As needed';
  }

  if (value === 'DAILY') {
    title = 'Daily';
  }

  if (value === 'CUSTOM') {
    title = rrule ? rrulestr(rrule).toText() : '';
  }

  return (
    <Menu closeOnSelect={false} isLazy>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            ref={ref}
            {...rest}
            as={Button}
            size="sm"
            variant="select"
            flexShrink={0}
            rightIcon={
              <ChevronDownIcon color="gray.800" width="20px" height="20px" />
            }
          >
            {title}
          </MenuButton>

          {isOpen && (
            <FrequencyDropdownOptions
              value={value}
              rrule={rrule}
              onClose={onClose}
              onChange={onChange}
            />
          )}
        </>
      )}
    </Menu>
  );
});

FrequencyDropdown.displayName = 'FrequencyDropdown';
