import { gql } from 'graphql-request';

export const UpdateProviderMutation = gql`
  mutation (
    $providerId: String!
    $email: String!
    $phone: String
    $firstName: String!
    $lastName: String!
    $role: UserRole!
    $credentials: String!
    $supervisorId: String
    $subordinateIds: [String!]
    $disconnectSupervisorId: String
    $disconnectSubordinateIds: [String!]
  ) {
    updateProvider(
      data: {
        providerId: $providerId
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        role: $role
        credentials: $credentials
        supervisorId: $supervisorId
        subordinateIds: $subordinateIds
        disconnectSupervisorId: $disconnectSupervisorId
        disconnectSubordinateIds: $disconnectSubordinateIds
      }
    ) {
      id
      firstName
      lastName
      credentials
      status
      invitedAt
      user {
        id
        email
        phone
        role
        activeAt
      }
      supervisors {
        id
      }
      subordinates {
        id
      }
    }
  }
`;
