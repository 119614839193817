import { gql } from 'graphql-request';

export const AssessmentResultsQuery = gql`
  fragment BadgeFragment on CardBadge {
    __typename
    text
    status
  }

  fragment AssetFragment on Asset {
    __typename
    type
    icon
    url
  }

  fragment AssessmentResultFragment on AssessmentResult {
    id
    title
    titleFull
    date
    dateShort
    badge {
      ...BadgeFragment
    }
    dataPoint {
      label
      timestamp
      value
    }
    scoreMovement {
      text
      asset {
        ...AssetFragment
      }
    }
    input {
      inputId
      value
      max
      score
      scoreMovement {
        text
        asset {
          ...AssetFragment
        }
      }
    }
  }

  query (
    $clientId: String!
    $assessmentId: String!
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    assessmentResults(
      query: {
        clientId: $clientId
        assessmentId: $assessmentId
        fromDate: $fromDate
        toDate: $toDate
      }
    ) {
      chart {
        min
        max
        baseline
      }
      title
      titleShort
      titleFull
      subtitle
      results {
        ...AssessmentResultFragment
      }
    }
  }
`;
