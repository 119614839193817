import React, { useCallback, useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IProvider } from '@app/store/schema';
import { getErrors, requiredValidator } from '@app/utils/validators';
import { StoreContext } from '@app/store/StoreContext';
import { FormSelect } from '@app/layout/form/FormSelect';
import { usersToList } from '@app/utils/usersList';
import { providerFullName, userFullName } from '@app/utils/userFullName';

interface DeactivateProviderModalProps {
  provider?: IProvider;
  isOpen: boolean;
  onClose(): void;
}

export function DeactivateProviderModal(props: DeactivateProviderModalProps) {
  const { provider, isOpen, onClose } = props;
  const { getUser, getProviders, deactivateProvider, getClientsByProviderId } =
    useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const providers = getProviders().filter((item) => item.id !== provider?.id);
  // const subordinates = getProviders().filter((item) => item.id !== provider?.id);
  const clients = getClientsByProviderId(provider?.id as string);

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        reassignToProviderId:
          clients.length > 0
            ? requiredValidator(values.reassignToProviderId)
            : null,
        approved: requiredValidator(values.approved),
      }),
    initialValues: {
      reassignToProviderId: '',
      approved: false,
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await deactivateProvider(provider?.id as string, {
        reassignToProviderId: value.reassignToProviderId,
      });

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const clearForm = useCallback(() => {
    formik.resetForm();
    onClose();
  }, []);

  if (!provider) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {userFullName(provider)!}</ModalHeader>
        <ModalCloseButton />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <VStack gap="20px" width="100%" alignItems="start">
                {clients.length > 0 && (
                  <>
                    <Text size="lg" variant="semibold">
                      Before deleting their account
                    </Text>

                    <FormSelect
                      label="Re-assign their clients to"
                      name="reassignToProviderId"
                      placeholder="Choose supervisor"
                      items={usersToList(providers)}
                    />

                    <Divider m="5px 0" />
                  </>
                )}

                {provider.subordinates.length > 0 && (
                  <>
                    <Text size="lg" variant="medium">
                      {provider.firstName} has active team members under their
                      supervision.
                    </Text>
                    <Text variant="medium" color="red.500">
                      If {provider.firstName} is deactivated,{' '}
                      {providerFullName(getUser()!)} will become the new
                      supervisor of these team members.
                    </Text>

                    <Divider m="5px 0" />
                  </>
                )}

                <Text size="lg">
                  Are you sure you want to permanently delete{' '}
                  <Text variant="semibold" as="span">
                    {userFullName(provider)!} ({provider.user.email})
                  </Text>{' '}
                  revoke their access to their account? This action cannot be
                  undone.
                </Text>

                <Checkbox
                  id="approved"
                  name="approved"
                  onChange={formik.handleChange}
                  isChecked={formik.values.approved}
                  size="lg"
                  colorScheme="purple"
                >
                  I am sure
                </Checkbox>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button size="sm" mr="12px" onClick={onClose}>
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme={formik.values.approved ? 'red' : 'gray'}
                color={formik.values.approved ? 'white' : 'gray.400'}
                isDisabled={!formik.values.approved}
                isLoading={isLoading}
                type="submit"
              >
                Permanently delete {provider.firstName}
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
}
