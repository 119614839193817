import { Box, Flex, Spacer } from '@chakra-ui/react';
import { NavigationBar as NavigationBarProps } from '../../../../__generated__/graphql';
import Asset from './Asset';

export default function NavigationBar({
  navBar,
}: {
  navBar: NavigationBarProps;
}) {
  return (
    <Flex w="100%" h="42px">
      {navBar.leftItem?.asset && <Asset asset={navBar.leftItem?.asset} />}
      {navBar.header ? (
        <Box flex={1} textAlign="center">
          {navBar.header}
        </Box>
      ) : (
        <Spacer />
      )}
      {navBar.rightItem?.asset && <Asset asset={navBar.rightItem?.asset} />}
    </Flex>
  );
}
