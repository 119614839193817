import React, { useContext, useMemo } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { userRole } from '@app/utils/userRole';
import { userStatus } from '@app/utils/userStatus';
import { providerFullName } from '@app/utils/userFullName';
import { IProvider } from '@app/store/schema';

interface SettingsProvidersItemProps {
  provider: IProvider;
  clients: number;
  onEdit: (provider: IProvider) => void;
  onDeactivate: (provider: IProvider) => void;
}

export function SettingsProvidersItemContent(
  props: SettingsProvidersItemProps,
) {
  const { provider, clients, onEdit, onDeactivate } = props;

  const { getUser } = useContext(StoreContext);

  const currentUser = getUser();
  const isInvited = useMemo(
    () => provider.status === 'INVITED',
    [provider.status],
  );

  const userNameFormatted = useMemo(() => {
    const name = providerFullName(provider!);

    return name + (currentUser?.id === provider.id ? ' (You)' : '');
  }, [provider]);

  const userRoleFormatted = useMemo(
    () => userRole(provider.user.role),
    [provider.user.role],
  );

  const userStatusFormatted = useMemo(
    () => userStatus(provider.status),
    [provider.status],
  );

  return (
    <TableRow variant="lg">
      <TableCell width="100%" variant="lg">
        <Text variant="medium">{userNameFormatted}</Text>
      </TableCell>
      <TableCell width="146px" flexShrink={0} variant="lg">
        <Text size="sm" variant="medium">
          {userRoleFormatted}
        </Text>
      </TableCell>
      <TableCell width="146px" flexShrink={0} variant="lg">
        {isInvited && (
          <Button
            size="xs"
            colorScheme="purple"
            variant="outline"
            onClick={() => onEdit(provider)}
          >
            Resend invite
          </Button>
        )}
        {!isInvited && (
          <Text size="sm" variant="medium">
            {userStatusFormatted}
          </Text>
        )}
      </TableCell>
      <TableCell width="90px" flexShrink={0} variant="lg">
        <Text size="sm">{clients}</Text>
      </TableCell>
      <TableCell width="62px" flexShrink={0} variant="lg" justifyContent="end">
        {currentUser?.id !== provider.id && (
          <Menu isLazy>
            <MenuButton>
              <Icon
                as={FiMoreHorizontal}
                width="24px"
                height="24px"
                color="gray.400"
                cursor="pointer"
              />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => onEdit(provider)}>Edit info</MenuItem>
              {isInvited && (
                <MenuItem onClick={() => onEdit(provider)}>
                  Resend invite
                </MenuItem>
              )}
              <MenuItem color="red.500" onClick={() => onDeactivate(provider)}>
                Delete {provider.firstName}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
}

export const SettingsProvidersItem = React.memo(SettingsProvidersItemContent);
