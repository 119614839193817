import React from 'react';
import { BsX } from 'react-icons/bs';
import { IoCheckmarkCircleSharp, IoAlertCircle } from 'react-icons/io5';
import {
  defineStyleConfig,
  forwardRef,
  HStack,
  Icon,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';

export const toastTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
    justifyContent: 'space-between',
    marginBottom: '30px',
    padding: '18px 25px',
    width: '448px',
  },
  variants: {
    success: {
      background: 'green.500',
    },
    error: {
      background: 'red.500',
    },
  },
  defaultProps: {
    variant: 'success',
  },
});

export const Toast = forwardRef((props, ref) => {
  const { title, variant, isClosable, onClose, description, ...rest } = props;
  const styles = useStyleConfig('Toast', { variant });

  return (
    <HStack ref={ref} __css={styles} {...rest}>
      <HStack>
        <Icon
          as={variant === 'success' ? IoCheckmarkCircleSharp : IoAlertCircle}
          width="24px"
          height="24px"
          color="white"
        />
        <Text color="gray.50" size="sm">
          {title}
          {description && (
            <Text color="gray.50" size="xs">
              {description}
            </Text>
          )}
        </Text>
      </HStack>

      {isClosable && (
        <HStack cursor="pointer" onClick={onClose}>
          <Icon as={BsX} width="24px" height="24px" color="white" />
        </HStack>
      )}
    </HStack>
  );
});

Toast.displayName = 'Toast';
