import React, { useContext, useMemo } from 'react';
import { NavLink as ReactRouterLink, Outlet } from 'react-router-dom';
import { Box, HStack, Link, VStack } from '@chakra-ui/react';
import { StoreContext } from '@app/store/StoreContext';
import { PageHeader } from '@app/layout/PageHeader';

export function Settings() {
  const { getUser, isAdminOrSupervisor } = useContext(StoreContext);

  const user = getUser();
  const isAllowedTeam = useMemo(() => isAdminOrSupervisor(user!), [user]);

  return (
    <VStack gap="40px" alignItems="start" width="100%" p="35px 50px">
      <PageHeader
        title="Settings"
        breadcrumbs={[
          {
            url: '/settings',
            title: 'Settings',
          },
        ]}
      />

      <VStack gap="30px" alignItems="start" width="100%">
        <HStack gap={0}>
          <Link
            variant="pill"
            as={ReactRouterLink}
            to="personal"
            aria-label="Personal info"
          >
            Personal info
          </Link>
          <Link
            variant="pill"
            as={ReactRouterLink}
            to="security"
            aria-label="Account security"
          >
            Account security
          </Link>

          {isAllowedTeam && (
            <Link
              variant="pill"
              as={ReactRouterLink}
              to="providers"
              aria-label="My team"
            >
              My team
            </Link>
          )}
        </HStack>

        <Box bg="white" rounded={12} p="30px 0" width="100%">
          <Outlet />
        </Box>
      </VStack>
    </VStack>
  );
}
