import { gql } from 'graphql-request';

export const AssignFlowScheduleMutation = gql`
  mutation ($clientId: String!, $flows: [AssignFlowRequestInput!]!) {
    assignFlowSchedule(data: { clientId: $clientId, flows: $flows }) {
      id
      flowId
      type
      rrule
    }
  }
`;
