import {
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
} from '@chakra-ui/react';
import Button from './Button';

interface EditableCtaProps {
  value: string;
  onChange?: (value: string) => void;
  small?: boolean;
}

export default function EditableCta({
  value,
  small,
  onChange,
}: EditableCtaProps) {
  return (
    <Box>
      <Button small={small}>
        <Editable value={value} onChange={onChange}>
          <EditablePreview />
          <EditableTextarea />
        </Editable>
      </Button>
    </Box>
  );
}
