import { gql } from 'graphql-request';

export const ClientAssessmentsQuery = gql`
  query ($clientId: String!) {
    clientAssessments(clientId: $clientId) {
      id
      name
      nameShort
      nameFull
      description
      prompt
      questions {
        id
        text
        answers {
          id
          text
        }
      }
    }
  }
`;
