import React, { useCallback, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { IProvider } from '@app/store/schema';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { ProviderModal } from '@app/features/providers/modals/ProviderModal';
import { ProvidersPendingListItem } from '@app/features/providers/ProvidersPendingListItem';
import { providerFullName } from '@app/utils/userFullName';
import { useSort } from '@app/layout/sort/useSort';
import { Comparator } from '@app/utils/comparator';

export interface ProvidersPendingListProps {
  providers: IProvider[];
}

export function ProvidersPendingListContent(props: ProvidersPendingListProps) {
  const { providers } = props;
  const [currentProvider, setCurrentProvider] = useState(undefined);
  const { sortValue, applySort } = useSort({
    column: 'name',
    order: 'asc',
  });

  const providerModal = useDisclosure();

  const inviteProvider = useCallback((item?: any) => {
    setCurrentProvider(item);
    providerModal.onOpen();
  }, []);

  const sortedTable = useMemo(() => {
    return providers.sort((a: IProvider, b: IProvider) => {
      const isAsc = sortValue.order === 'asc';

      if (sortValue.column === 'name') {
        return Comparator.orderWithDirection(
          isAsc,
          providerFullName(a).toLowerCase(),
          providerFullName(b).toLowerCase(),
        );
      }

      if (sortValue.column === 'role') {
        return Comparator.orderWithDirection(isAsc, a.user.role, b.user.role);
      }

      if (sortValue.column === 'invitedAt') {
        return Comparator.orderDates(isAsc, a.invitedAt!, b.invitedAt!);
      }

      return 0;
    });
  }, [providers, sortValue]);

  return (
    <>
      <TabPanelContent>
        <Table>
          <TableContainer minWidth="870px">
            <TableHeader>
              <TableHeaderCell
                label="Team member"
                width="100%"
                sortColumn="name"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell
                label="Role"
                width="20%"
                flexShrink={0}
                sortColumn="role"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell
                label="Invite sent"
                width="20%"
                flexShrink={0}
                sortColumn="invitedAt"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell width="98px" flexShrink={0} />
              <TableHeaderCell width="24px" flexShrink={0} />
            </TableHeader>
            <TableBody>
              {sortedTable &&
                sortedTable.map((provider) => (
                  <ProvidersPendingListItem
                    key={provider.id}
                    provider={provider}
                    onInvite={inviteProvider}
                  />
                ))}
            </TableBody>
          </TableContainer>
        </Table>
      </TabPanelContent>

      <ProviderModal
        provider={currentProvider}
        isOpen={providerModal.isOpen}
        onClose={providerModal.onClose}
      />
    </>
  );
}

export const ProvidersPendingList = React.memo(ProvidersPendingListContent);
