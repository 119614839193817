import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { WidgetPlaceholder } from '@app/layout/widget/WidgetPlaceholder';
import { WidgetHeader } from '@app/layout/widget/WidgetHeader';
import { badgeColor } from '@app/utils/badgeColor';
import { AssessmentResultsChart } from '@app/features/clients/details/widgets/assessments/AssessmentResultsChart';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import { ChartPeriod } from '@app/features/clients/details/widgets/ChartPeriod';

export function AssessmentsWidget() {
  const { store } = useContext(WidgetsContext);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    if (!store.loading) {
      if (!selectedId.length && (store.assessments || []).length > 0) {
        setSelectedId((store.assessments || [])[0].id);
      }
    }
  }, [store.loading]);

  const selectedAssessment = (store.assessments || []).find(
    (assessment) => assessment.id === selectedId,
  );

  const assessmentResults = store.assessmentResults || new Map();

  return (
    <>
      <WidgetHeader
        title="Assessments"
        subtitle="Based on the assessment surveys results"
        variant="xl"
      />

      {store.loading && (
        <>
          <Skeleton height="46px" width="100%" />
          <Skeleton height="28px" width="100%" />
          <Skeleton height="305px" width="100%" />
        </>
      )}

      {!store.loading && (
        <>
          <RadioGroup
            onChange={setSelectedId}
            value={selectedId}
            variant="widget"
          >
            <Stack spacing="15px" direction="row">
              {store.assessments &&
                store.assessments.map((assessment, index) => {
                  const results = assessmentResults.get(assessment.id);
                  const lastResult =
                    results?.results?.[results.results.length - 1];

                  return (
                    <Radio
                      colorScheme="purple"
                      value={assessment.id}
                      key={index}
                    >
                      <VStack gap="0" alignItems="start">
                        <Text
                          aria-label={assessment.nameShort}
                          variant={
                            selectedId === assessment.id
                              ? 'semibold'
                              : 'mediumSemibold'
                          }
                          size="lg"
                          color={
                            selectedId === assessment.id
                              ? 'gray.700'
                              : 'gray.600'
                          }
                        >
                          {assessment.nameShort}
                        </Text>
                        {lastResult && (
                          <Badge
                            colorScheme={badgeColor(lastResult.badge)}
                            fontSize="12px"
                            fontWeight="500"
                            textTransform="none"
                          >
                            {lastResult.badge.text}
                          </Badge>
                        )}
                      </VStack>
                    </Radio>
                  );
                })}
            </Stack>
          </RadioGroup>

          <ChartPeriod />

          {selectedAssessment && (
            <Box height="330px" width="100%" marginTop="-25px">
              <AssessmentResultsChart assessment={selectedAssessment!} />
            </Box>
          )}

          {!selectedId && <WidgetPlaceholder />}
        </>
      )}
    </>
  );
}
