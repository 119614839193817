import React from 'react';

interface GradientBackgroundLayerProps {
  innerHeight: number;
  innerWidth: number;
}

export const GradientBackgroundLayer = (
  props: GradientBackgroundLayerProps,
) => {
  const { innerWidth, innerHeight } = props;

  return (
    <rect
      x={0}
      y={0}
      width={innerWidth}
      height={innerHeight}
      fill="url(#gradient)"
    />
  );
};
