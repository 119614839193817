import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tag,
  Textarea,
} from '@chakra-ui/react';
import { Asset, FlowType } from '../../../__generated__/graphql';
import {
  FlowEditorFlowActions,
  FlowEditorScreenPropertyActions,
} from './FlowEditorApp';
import { EditorFieldsState } from './reducer/EditorReducer';

interface FlowPropertiesProps {
  name: string;
  description?: string | null;
  type: FlowType;
  asset?: Asset | null;
  fieldState: EditorFieldsState;
}

export default function FlowProperties({
  name,
  description,
  type,
  onChangeFlowProperty,
  fieldState,
}: FlowPropertiesProps &
  FlowEditorFlowActions &
  FlowEditorScreenPropertyActions) {
  return (
    <Box p={2} maxWidth="1000px">
      <Tag marginBottom={5}>{type}</Tag>
      <FormControl
        isInvalid={!!fieldState.flowFieldsState.name.error}
        marginBottom={5}
      >
        <FormLabel>Activity name</FormLabel>
        <Input
          onChange={(ev) => {
            onChangeFlowProperty('name', ev.target.value);
          }}
          placeholder="name"
          value={name}
        />
        <FormErrorMessage>
          {fieldState.flowFieldsState.name.error}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!fieldState.flowFieldsState.description.error}
        marginBottom={5}
      >
        <FormLabel>Activity description</FormLabel>
        <Textarea
          onChange={(ev) => {
            onChangeFlowProperty('description', ev.target.value);
          }}
          placeholder="description"
          value={description ?? ''}
        />
        <FormErrorMessage>
          {fieldState.flowFieldsState.description.error}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}
