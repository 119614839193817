import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiChevronRight } from 'react-icons/hi';
import { Icon, Text } from '@chakra-ui/react';
import { IClient, IProvider } from '@app/store/schema';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { providerFullName, userFullName } from '@app/utils/userFullName';
import { formatDatetime } from '@app/utils/date';

export interface ClientsDischargedListItemProps {
  client: IClient;
  provider?: IProvider;
  dischargedBy: IProvider;
}

export function ClientsDischargedListItemContent(
  props: ClientsDischargedListItemProps,
) {
  const { client, provider, dischargedBy } = props;
  const navigate = useNavigate();

  const userNameFormatted = useMemo(() => userFullName(client!), [client]);

  const providerNameFormatted = useMemo(
    () => providerFullName(provider!),
    [provider],
  );

  const dischargedByFormatted = useMemo(
    () => providerFullName(dischargedBy!),
    [dischargedBy],
  );

  const dischargedAtFormatted = useMemo(
    () => formatDatetime(client.dischargedAt!),
    [client.dischargedAt],
  );

  const navigateToDetails = useCallback(() => {
    navigate('/clients/' + client.id);
  }, []);

  return (
    <TableRow className="clickable" onClick={navigateToDetails}>
      <TableCell width="100%">
        <Text variant="medium" size="sm">
          {userNameFormatted}
        </Text>
      </TableCell>
      {provider && (
        <TableCell width="20%" flexShrink={0}>
          <Text variant="medium" size="sm">
            {providerNameFormatted}
          </Text>
        </TableCell>
      )}
      <TableCell width={!provider ? '30%' : '20%'} flexShrink={0}>
        <Text variant="medium" size="sm">
          {dischargedAtFormatted}
        </Text>
      </TableCell>
      <TableCell width={!provider ? '30%' : '20%'} flexShrink={0}>
        <Text variant="medium" size="sm">
          {dischargedByFormatted}
        </Text>
      </TableCell>
      <TableCell width="24px" flexShrink={0} justifyContent="end">
        <Icon as={HiChevronRight} width="24px" height="24px" color="gray.400" />
      </TableCell>
    </TableRow>
  );
}

export const ClientsDischargedListItem = React.memo(
  ClientsDischargedListItemContent,
);
