import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { Point, ResponsiveLine } from '@nivo/line';
import { useDisclosure } from '@chakra-ui/react';
import { IAssessment, IClientAssessmentResults } from '@app/store/schema';
import { GradientBackgroundLayer } from '@app/features/clients/details/widgets/components/GradientBackgroundLayer';
import { CustomPoints } from '@app/features/clients/details/widgets/components/CustomPoints';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import { chartTheme } from '@app/features/clients/details/widgets/components/chartTheme';
import { AssessmentPointTooltip } from '@app/features/clients/details/widgets/assessments/AssessmentPointTooltip';
import { AssessmentResultsModal } from '@app/features/clients/details/widgets/assessments/AssessmentResultsModal';

interface AssessmentResultsChartWidgetProps {
  assessment: IAssessment;
}

export function AssessmentResultsChart(
  props: AssessmentResultsChartWidgetProps,
) {
  const { assessment } = props;
  const { store } = useContext(WidgetsContext);
  const [currentPoint, setCurrentPoint] = useState<Point | undefined>(
    undefined,
  );

  const assessmentResultsModal = useDisclosure();

  const assessmentResults: IClientAssessmentResults = (
    store.assessmentResults || new Map()
  ).get(assessment.id);

  if (!assessmentResults?.results?.length) return null;

  const showPointDetails = (points: Point[]) => {
    setCurrentPoint(points[0]);
    assessmentResultsModal.onOpen();
  };

  const chartData = [
    {
      id: assessment.id,
      data: assessmentResults.results.map((item: any) => ({
        id: item.id,
        x: item.dataPoint.timestamp,
        y: item.dataPoint.value,
        item,
      })),
    },
  ];

  return (
    <>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 25, right: 16, bottom: 47, left: 36 }}
        yScale={{
          type: 'linear',
          min: assessmentResults.chart.min,
          max: assessmentResults.chart.max,
        }}
        axisLeft={{
          legendPosition: 'start',
          legendOffset: 0,
          tickSize: 14,
          tickValues: 5,
        }}
        axisBottom={{
          tickSize: 14,
          tickValues: 'every 1 day',
          legendOffset: 0,
          format: (tick) => dayjs(tick).format('D MMM'),
        }}
        curve="linear"
        enablePointLabel={true}
        pointSize={8}
        pointColor={{ from: 'color' }}
        gridXValues={[]}
        gridYValues={5}
        defs={[
          {
            id: 'gradient',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: 'rgba(255, 41, 67, 0.15)' },
              { offset: 52.08, color: 'rgba(255, 169, 41, 0.15)' },
              { offset: 98.96, color: 'rgba(0, 187, 131, 0.15)' },
              { offset: 98.97, color: 'rgba(0, 187, 131, 0.15)' },
            ],
          },
        ]}
        layers={[
          GradientBackgroundLayer,
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'slices',
          (props) => (
            <CustomPoints
              layer={props}
              onClick={(points) => showPointDetails(points)}
              tooltip={({ points }) => (
                <AssessmentPointTooltip points={points} />
              )}
            />
          ),
          'legends',
        ]}
        colors={['#44337A']}
        useMesh={true}
        enableSlices="x"
        sliceTooltip={({ slice }) => (
          <AssessmentPointTooltip points={slice.points} />
        )}
        isInteractive={true}
        theme={chartTheme}
        markers={[
          {
            axis: 'y',
            value: assessmentResults.chart.baseline,
            lineStyle: {
              stroke: '#A0AEC0',
              strokeWidth: 1,
              strokeDasharray: 2,
              strokeDashoffset: 2,
            },
          },
        ]}
      />

      <AssessmentResultsModal
        assessment={assessment}
        point={currentPoint}
        isOpen={assessmentResultsModal.isOpen}
        onClose={assessmentResultsModal.onClose}
      />
    </>
  );
}
