import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StoreContext } from '@app/store/StoreContext';
import { IClient, IProvider } from '@app/store/schema';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { ClientsActiveListItem } from '@app/features/clients/ClientsActiveListItem';
import { providerFullName, userFullName } from '@app/utils/userFullName';
import { Comparator } from '@app/utils/comparator';
import { useSort } from '@app/layout/sort/useSort';
import { useMutation } from '@apollo/client';
import { RESEND_CLIENT_INVITE } from './ResendClientInviteMutation';
import { useToast } from '@chakra-ui/react';

export interface ClientsActiveListProps {
  clients: IClient[];
  provider?: IProvider;
}

type IClientOptional = IClient & { provider: IProvider };

export function ClientsActiveList(props: ClientsActiveListProps) {
  const { clients, provider } = props;
  const { getProviderById } = useContext(StoreContext);
  const { sortValue, applySort } = useSort({
    column: 'name',
    order: 'asc',
  });

  const extendedClients = useMemo(() => {
    return clients.map((client) => {
      const provider = getProviderById(client.providers[0].id)!;

      return {
        ...client,
        provider,
      };
    });
  }, [clients]);

  const sortedTable = useMemo(() => {
    return extendedClients.sort((a: IClientOptional, b: IClientOptional) => {
      const isAsc = sortValue.order === 'asc';

      if (sortValue.column === 'name') {
        return Comparator.orderWithDirection(
          isAsc,
          userFullName(a).toLowerCase(),
          userFullName(b).toLowerCase(),
        );
      }

      if (sortValue.column === 'latestStatus') {
        return Comparator.orderWithDirection(
          isAsc,
          a.latestAssessmentResult ? a.latestAssessmentResult.titleShort : '',
          b.latestAssessmentResult ? b.latestAssessmentResult.titleShort : '',
        );
      }

      if (sortValue.column === 'latestActivity') {
        return Comparator.orderDates(
          isAsc,
          a.user.activeAt ? a.user.activeAt : null,
          b.user.activeAt ? b.user.activeAt : null,
        );
      }

      if (sortValue.column === 'provider') {
        return Comparator.orderWithDirection(
          isAsc,
          providerFullName(a.provider).toLowerCase(),
          providerFullName(b.provider).toLowerCase(),
        );
      }

      return 0;
    });
  }, [extendedClients, sortValue]);

  const toast = useToast();

  const [mutationClientId, setMutationClientId] = useState<string | null>(null);

  const [resendClientInviteMutation, { data, loading }] =
    useMutation(RESEND_CLIENT_INVITE);

  useEffect(() => {
    if (!data?.resendClientInvite?.id) return;

    toast({
      title: `Invite for ${data?.resendClientInvite?.firstName} has been sucessfully re-send`,
      variant: 'success',
    });
  }, [data?.resendClientInvite?.id]);

  const onResendClientInvite = (clientId: string) => {
    setMutationClientId(clientId);
    resendClientInviteMutation({ variables: { clientId } });
  };

  return (
    <TabPanelContent>
      <Table>
        <TableContainer minWidth="870px">
          <TableHeader>
            <TableHeaderCell
              label="Client"
              width="100%"
              sortColumn="name"
              sortValue={sortValue}
              onSort={applySort}
            />
            <TableHeaderCell
              label="Latest status"
              width={provider ? '35%' : '25%'}
              flexShrink={0}
              sortColumn="latestStatus"
              sortValue={sortValue}
              onSort={applySort}
            />
            <TableHeaderCell
              label="Latest activity"
              width="15%"
              flexShrink={0}
              sortColumn="latestActivity"
              sortValue={sortValue}
              onSort={applySort}
            />
            {!provider && (
              <TableHeaderCell
                label="Provider"
                width="20%"
                flexShrink={0}
                sortColumn="provider"
                sortValue={sortValue}
                onSort={applySort}
              />
            )}
            <TableHeaderCell width="98px" flexShrink={0} />
            <TableHeaderCell width="24px" flexShrink={0} />
          </TableHeader>

          <TableBody>
            {sortedTable &&
              sortedTable.map((client) => (
                <ClientsActiveListItem
                  key={client.id}
                  client={client}
                  provider={provider ? undefined : client.provider}
                  onResendClientInvite={onResendClientInvite}
                  isLoading={client.id === mutationClientId && loading}
                />
              ))}
          </TableBody>
        </TableContainer>
      </Table>
    </TabPanelContent>
  );
}
