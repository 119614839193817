import React, { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiChevronRight } from 'react-icons/hi';
import { FiAlertCircle } from 'react-icons/fi';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { IProvider } from '@app/store/schema';
import { TableRow } from '@app/layout/table/TableRow';
import { TableCell } from '@app/layout/table/TableCell';
import { userRole } from '@app/utils/userRole';
import { providerFullName } from '@app/utils/userFullName';
import { StoreContext } from '@app/store/StoreContext';

export interface ProvidersActiveListItemProps {
  provider: IProvider;
  highRiskClients: number;
}

export function ProvidersActiveListItemContent(
  props: ProvidersActiveListItemProps,
) {
  const { provider, highRiskClients } = props;
  const navigate = useNavigate();

  const { getUser } = useContext(StoreContext);

  const currentUser = getUser();

  const userNameFormatted = useMemo(() => {
    const name = providerFullName(provider!);

    return name + (currentUser?.id === provider.id ? ' (You)' : '');
  }, [provider]);

  const userRoleFormatted = useMemo(
    () => userRole(provider.user.role),
    [provider.user.role],
  );

  const navigateToDetails = useCallback(() => {
    navigate('/providers/' + provider.id);
  }, []);

  return (
    <TableRow className="clickable" onClick={navigateToDetails}>
      <TableCell width="100%">
        <Text variant="medium" size="sm">
          {userNameFormatted}
        </Text>
      </TableCell>
      <TableCell width="20%" flexShrink={0}>
        <Text variant="medium" size="sm">
          {userRoleFormatted}
        </Text>
      </TableCell>
      <TableCell width="20%" flexShrink={0}>
        <HStack>
          <Text
            variant="medium"
            size="sm"
            color={highRiskClients > 0 ? 'red.500' : 'gray.900'}
          >
            {highRiskClients || '-'}
          </Text>

          {highRiskClients > 0 && <Icon as={FiAlertCircle} color="red.500" />}
        </HStack>
      </TableCell>
      <TableCell width="24px" flexShrink={0} justifyContent="end">
        <Icon as={HiChevronRight} width="24px" height="24px" color="gray.400" />
      </TableCell>
    </TableRow>
  );
}

export const ProvidersActiveListItem = React.memo(
  ProvidersActiveListItemContent,
);
