import React, { useContext, useMemo } from 'react';
import { StoreContext } from '@app/store/StoreContext';
import { IClient, IProvider } from '@app/store/schema';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { TabPanelContent } from '@app/layout/TabPanelContent';
import { ClientsDischargedListItem } from '@app/features/clients/ClientsDIschargedListItem';
import { providerFullName, userFullName } from '@app/utils/userFullName';
import { Comparator } from '@app/utils/comparator';
import { useSort } from '@app/layout/sort/useSort';

export interface ClientsDischargedListProps {
  clients: IClient[];
  provider?: IProvider;
}

type IClientOptional = IClient & {
  provider: IProvider;
  dischargedBy: IProvider;
};

export function ClientsDischargedListContent(
  props: ClientsDischargedListProps,
) {
  const { clients, provider } = props;
  const { getProviderById } = useContext(StoreContext);
  const { sortValue, applySort } = useSort({
    column: 'name',
    order: 'asc',
  });

  const extendedClients = useMemo(() => {
    return clients.map((client) => {
      const provider = getProviderById(client.providers[0].id)!;
      const dischargedBy = getProviderById(
        client.dischargedByProvider?.id as string,
      )!;

      return {
        ...client,
        provider,
        dischargedBy,
      };
    });
  }, [clients]);

  const sortedTable = useMemo(() => {
    return extendedClients.sort((a: IClientOptional, b: IClientOptional) => {
      const isAsc = sortValue.order === 'asc';

      if (sortValue.column === 'name') {
        return Comparator.orderWithDirection(
          isAsc,
          userFullName(a).toLowerCase(),
          userFullName(b).toLowerCase(),
        );
      }

      if (sortValue.column === 'dischargedAt') {
        return Comparator.orderDates(isAsc, a.dischargedAt!, b.dischargedAt!);
      }

      if (sortValue.column === 'dischargedBy') {
        return Comparator.orderWithDirection(
          isAsc,
          providerFullName(a.dischargedBy!).toLowerCase(),
          providerFullName(b.dischargedBy!).toLowerCase(),
        );
      }

      if (sortValue.column === 'provider') {
        return Comparator.orderWithDirection(
          isAsc,
          providerFullName(a.provider).toLowerCase(),
          providerFullName(b.provider).toLowerCase(),
        );
      }

      return 0;
    });
  }, [extendedClients, sortValue]);

  return (
    <TabPanelContent>
      <Table>
        <TableContainer minWidth="870px">
          <TableHeader>
            <TableHeaderCell
              label="Client"
              width="100%"
              sortColumn="name"
              sortValue={sortValue}
              onSort={applySort}
            />
            {!provider && (
              <TableHeaderCell
                label="Provider"
                width="20%"
                flexShrink={0}
                sortColumn="provider"
                sortValue={sortValue}
                onSort={applySort}
              />
            )}
            <TableHeaderCell
              label="Date discharged"
              width={provider ? '30%' : '20%'}
              flexShrink={0}
              sortColumn="dischargedAt"
              sortValue={sortValue}
              onSort={applySort}
            />
            <TableHeaderCell
              label="Discharged by"
              width={provider ? '30%' : '20%'}
              flexShrink={0}
              sortColumn="dischargedBy"
              sortValue={sortValue}
              onSort={applySort}
            />
            <TableHeaderCell width="24px" flexShrink={0} />
          </TableHeader>

          <TableBody>
            {sortedTable &&
              sortedTable.map((client) => (
                <ClientsDischargedListItem
                  key={client.id}
                  client={client}
                  provider={provider ? undefined : client.provider}
                  dischargedBy={client.dischargedBy}
                />
              ))}
          </TableBody>
        </TableContainer>
      </Table>
    </TabPanelContent>
  );
}

export const ClientsDischargedList = React.memo(ClientsDischargedListContent);
