import { gql } from 'graphql-request';

export const RemoveFlowScheduleMutation = gql`
  mutation ($clientId: String!, $scheduleIds: [String!]!) {
    removeFlowSchedule(
      data: { clientId: $clientId, scheduleIds: $scheduleIds }
    ) {
      id
      flowId
      type
      rrule
    }
  }
`;
