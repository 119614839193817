import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { WidgetHeader } from '@app/layout/widget/WidgetHeader';
import { WidgetPlaceholder } from '@app/layout/widget/WidgetPlaceholder';
import { ChartPeriod } from '@app/features/clients/details/widgets/ChartPeriod';
import { WidgetsContext } from '@app/features/clients/details/widgets/WidgetsContext';
import { MoodChart } from '@app/features/clients/details/widgets/mood/MoodChart';
import { IExtendedTrackerResultInput } from '@app/store/schema';

export function MoodWidget() {
  const { store } = useContext(WidgetsContext);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    if (!store.loading) {
      if (!selectedId.length && (store.assessments || []).length > 0) {
        const items = (store.trackerItems || []).map(
          (trackerItem) => trackerItem.id,
        );

        setSelectedId(items.length > 0 ? items[0] : '');
      }
    }
  }, [store.loading]);

  const trackerItems = store.trackerItems || [];
  const trackerResults = store.trackerResults || [];
  const availableMood = new Map();

  trackerResults.forEach((item: IExtendedTrackerResultInput) => {
    const { trackerItem } = item;

    availableMood.set(trackerItem.id, {
      id: trackerItem.id,
      name: trackerItem.label,
    });
  });

  return (
    <>
      <WidgetHeader
        title="Mood"
        subtitle="Based on the mood tracker results"
        variant="xl"
      />

      {store.loading && (
        <>
          <Skeleton height="46px" width="100%" />
          <Skeleton height="28px" width="100%" />
          <Skeleton height="305px" width="100%" />
        </>
      )}

      {!store.loading && (
        <>
          <ChartPeriod />

          {trackerItems.length > 0 && (
            <HStack alignItems="start" gap="15px">
              <RadioGroup
                onChange={setSelectedId}
                value={selectedId}
                variant="widget"
              >
                <Stack spacing="15px" direction="row">
                  {trackerItems &&
                    trackerItems.map((trackerItem) => (
                      <Radio
                        colorScheme="purple"
                        value={trackerItem.id}
                        key={trackerItem.id}
                      >
                        <Text
                          aria-label={trackerItem.label}
                          variant={
                            selectedId.indexOf(trackerItem.id) !== -1
                              ? 'semibold'
                              : 'mediumSemibold'
                          }
                          size="md"
                          color="gray.700"
                        >
                          {trackerItem.label}
                        </Text>
                      </Radio>
                    ))}
                </Stack>
              </RadioGroup>
            </HStack>
          )}

          {trackerItems.length > 0 && (
            <Box height="330px" width="100%">
              <MoodChart trackers={[selectedId]} />
            </Box>
          )}

          {!trackerItems.length && <WidgetPlaceholder />}
        </>
      )}
    </>
  );
}
