import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Frequency, RRule, rrulestr } from 'rrule';
import { Weekday, WeekdayStr } from 'rrule/dist/esm/weekday';
import {
  Box,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TScheduleType } from '@app/store/schema';
import { getWeekdays } from '@app/utils/date';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface FrequencyDropdownOptionsProps {
  value: TScheduleType;
  rrule?: string | null;
  onChange: (newValue?: TScheduleType, rrule?: string | null) => void;
  onClose: () => void;
}

export function FrequencyDropdownOptions(props: FrequencyDropdownOptionsProps) {
  const { value, rrule, onClose, onChange } = props;

  const frequencyMenuItems = [
    [Frequency.WEEKLY, 'week', 'weeks'],
    [Frequency.DAILY, 'day', 'days'],
  ] as [Frequency, string, string][];

  const weekdays = getWeekdays();

  const rruleOptions = rrule
    ? rrulestr(rrule)
    : new RRule({
        interval: 1,
        freq: Frequency.WEEKLY,
        wkst: Weekday.fromStr(weekdays[0].toUpperCase() as WeekdayStr),
        byweekday: [
          Weekday.fromStr(dayjs().format('dd').toUpperCase() as WeekdayStr),
        ],
      });

  const [selectedItem, setSelectedItem] = useState(value);
  const [rruleFreq, setRruleFreq] = useState(rruleOptions.origOptions.freq);
  const [selectedWeekday, setSelectedWeekday] = useState(
    (rruleOptions.origOptions.byweekday as Weekday[] | null)?.[0]?.toString() ||
      weekdays[0].toUpperCase(),
  );
  const [selectedInterval, setSelectedInterval] = useState(
    rruleOptions.origOptions.interval,
  );

  const applyValue = (type: TScheduleType) => {
    let rrule = null;

    if (type === 'CUSTOM') {
      rrule = new RRule({
        freq: rruleFreq,
        interval: selectedInterval,
        wkst: rruleOptions.origOptions.wkst,
        byweekday:
          rruleFreq === Frequency.WEEKLY && selectedWeekday
            ? [Weekday.fromStr(selectedWeekday.toUpperCase() as WeekdayStr)]
            : undefined,
      });
    }

    onChange(type, rrule ? rrule.toString() : null);
    onClose();
  };
  console.log(rruleFreq);
  return (
    <MenuList minWidth="160px">
      <HStack
        alignItems="start"
        gap="10px"
        minH={selectedItem === 'CUSTOM' ? '260px' : 'auto'}
      >
        <VStack width={selectedItem === 'CUSTOM' ? '140px' : '100%'}>
          <MenuItem
            className={selectedItem === 'FREE' ? 'active' : ''}
            onClick={() => applyValue('FREE')}
          >
            As needed
          </MenuItem>

          <MenuItem
            className={selectedItem === 'DAILY' ? 'active' : ''}
            onClick={() => applyValue('DAILY')}
          >
            Daily
          </MenuItem>

          <MenuItem
            className={selectedItem === 'CUSTOM' ? 'active' : ''}
            onClick={() => setSelectedItem('CUSTOM')}
          >
            Custom
          </MenuItem>
        </VStack>

        {selectedItem === 'CUSTOM' && (
          <>
            <Divider orientation="vertical" />

            <VStack padding="6px" alignItems="start" height="260px">
              <Box>
                <Text variant="semibold" marginBottom="18px">
                  Repeat
                </Text>

                <HStack gap="16px" alignItems="center" marginBottom="22px">
                  <Text>Every</Text>

                  <NumberInput
                    width="82px"
                    size="sm"
                    defaultValue={selectedInterval}
                    value={selectedInterval}
                    min={1}
                    max={50}
                    onChange={(valueAsString, valueAsNumber) =>
                      setSelectedInterval(valueAsNumber)
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <Menu>
                    <MenuButton
                      size="sm"
                      variant="select"
                      height="35px"
                      width="120px"
                      as={Button}
                      rightIcon={
                        <ChevronDownIcon
                          color="gray.800"
                          width="20px"
                          height="20px"
                        />
                      }
                    >
                      {
                        frequencyMenuItems.find((x) => x[0] === rruleFreq)?.[
                          selectedInterval && selectedInterval > 1 ? 2 : 1
                        ]
                      }
                    </MenuButton>
                    <MenuList>
                      {frequencyMenuItems.map(([freq, single, plural]) => (
                        <MenuItem
                          onClick={() => setRruleFreq(freq)}
                          value={freq}
                          className={rruleFreq === freq ? 'active' : ''}
                        >
                          {selectedInterval && selectedInterval > 1
                            ? plural
                            : single}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </HStack>
                {rruleFreq === Frequency.WEEKLY && (
                  <>
                    <Text variant="semibold" size="sm" marginBottom="14px">
                      On the following day
                    </Text>

                    <HStack gap={0} marginBottom="34px">
                      {weekdays &&
                        weekdays.map((weekday, index) => (
                          <Box
                            p="4px 8px"
                            borderRadius="10px"
                            key={index}
                            backgroundColor={
                              selectedWeekday === weekday
                                ? 'purple.900'
                                : 'transparent'
                            }
                            color={
                              selectedWeekday === weekday
                                ? 'gray.50'
                                : 'gray.900'
                            }
                            _hover={{
                              backgroundColor: 'purple.900',
                              color: 'gray.50',
                            }}
                            cursor="pointer"
                            onClick={() => setSelectedWeekday(weekday)}
                          >
                            <Text
                              size="sm"
                              aria-label={weekday.toLowerCase()}
                              textTransform="capitalize"
                              variant={
                                selectedWeekday === weekday
                                  ? 'semibold'
                                  : 'mediumSemibold'
                              }
                            >
                              {weekday.toLowerCase()}
                            </Text>
                          </Box>
                        ))}
                    </HStack>
                  </>
                )}
              </Box>
              <Spacer />
              <HStack width="100%" justifyContent="end">
                <Button
                  size="xs"
                  fontSize="14px"
                  lineHeight="20px"
                  height="32px"
                  padding="0 12px"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>

                <Button
                  size="xs"
                  fontSize="14px"
                  lineHeight="20px"
                  height="32px"
                  padding="0 12px"
                  variant="primary"
                  onClick={() => applyValue('CUSTOM')}
                >
                  Apply
                </Button>
              </HStack>
            </VStack>
          </>
        )}
      </HStack>
    </MenuList>
  );
}
