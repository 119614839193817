import React from 'react';
import dayjs from 'dayjs';
import { Center, HStack, Text, VStack } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { getWeekdays, isFirstDay, isLastDay, isSameDay } from '@app/utils/date';
import { Comparator } from '@app/utils/comparator';
import { CalendarPeriod } from '@app/layout/calendar/Calendar';

interface CalendarMonthProps {
  value?: CalendarPeriod;
  month: dayjs.Dayjs;
  onPrevious?: () => void;
  onNext?: () => void;
  onChange?: (value: CalendarPeriod) => void;
}

export function CalendarMonth(props: CalendarMonthProps) {
  const { value, month, onPrevious, onNext, onChange } = props;
  const weekdays = getWeekdays();

  const isDateSelected = (date: dayjs.Dayjs) => {
    const start = value?.from;
    const end = value?.to;

    if (!start || !dayjs(start).isValid()) {
      return false;
    }

    if (!end || !dayjs(end).isValid()) {
      return false;
    }

    return (
      (dayjs(date).isAfter(start) || isSameDay(date, start)) &&
      (dayjs(date).isBefore(end) || isSameDay(date, end))
    );
  };

  const dates = [];

  const periodStart = month.startOf('month').startOf('day');
  const periodEnd = month.endOf('month');

  let from = periodStart.startOf('week').startOf('day');
  const to = periodStart.add(5, 'week').endOf('week');

  while (from <= to) {
    dates.push({
      date: from,
      isSelected: isDateSelected(from),
      isWeekdayStart: isFirstDay(from),
      isWeekdayEnd: isLastDay(from),
      isFirstSelected:
        isDateSelected(from) && value && isSameDay(from, value.from),
      isLastSelected:
        isDateSelected(from) && value && isSameDay(from, value.to),
      isToday: isSameDay(from, dayjs()),
      otherMonth: from.isBefore(periodStart) || from.isAfter(periodEnd),
    });

    from = from.add(1, 'day');
  }

  const select = (date: dayjs.Dayjs) => {
    const selected: CalendarPeriod = {
      from: date,
      to: date,
    };

    if (value && isSameDay(value.from, value.to)) {
      const sorted = [value.from, date].sort((a, b) =>
        Comparator.orderDates(true, a, b),
      );

      selected.from = sorted[0];
      selected.to = sorted[1];
    }

    selected.from = dayjs(selected.from).startOf('day');
    selected.to = dayjs(selected.to).endOf('day');

    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <VStack gap="12px" width="244px">
      <HStack gap={0} justifyContent="space-between" width="100%">
        <Center
          width="24px"
          height="24px"
          cursor="pointer"
          visibility={onPrevious ? 'visible' : 'hidden'}
          onClick={() => (onPrevious ? onPrevious() : null)}
        >
          <ChevronLeftIcon />
        </Center>

        <Text variant="semibold">{dayjs(month).format('MMMM YYYY')}</Text>

        <Center
          width="24px"
          height="24px"
          cursor="pointer "
          visibility={onNext ? 'visible' : 'hidden'}
          onClick={() => (onNext ? onNext() : null)}
        >
          <ChevronRightIcon />
        </Center>
      </HStack>

      <VStack gap={0} alignItems="center">
        <HStack gap={0}>
          {weekdays &&
            weekdays.map((weekday, index) => (
              <Center height="32px" width="32px" key={index}>
                <Text size="sm" color="gray.500" textTransform="capitalize">
                  {weekday.toLowerCase()}
                </Text>
              </Center>
            ))}
        </HStack>

        <HStack gap={0} flexWrap="wrap" justifyContent="center">
          {dates &&
            dates.map((item, index) => {
              const isFirst =
                item.isSelected &&
                (item.isWeekdayStart || item.isFirstSelected);
              const isLast =
                item.isSelected && (item.isWeekdayEnd || item.isLastSelected);

              const isSelectedHighlight =
                item.isFirstSelected || item.isLastSelected;

              return (
                <Center
                  height="32px"
                  width="32px"
                  key={index}
                  visibility={item.otherMonth ? 'hidden' : 'visible'}
                  cursor="pointer"
                  onClick={() => select(item.date)}
                  backgroundColor={item.isSelected ? '#EDF2F7' : 'transparent'}
                  borderTopLeftRadius={isFirst ? '64px' : 0}
                  borderBottomLeftRadius={isFirst ? '64px' : 0}
                  borderBottomRightRadius={isLast ? '64px' : 0}
                  borderTopRightRadius={isLast ? '64px' : 0}
                >
                  <Center
                    height="28px"
                    width="28px"
                    backgroundColor={
                      isSelectedHighlight
                        ? 'purple.900'
                        : item.isToday
                          ? 'purple.200'
                          : 'transparent'
                    }
                    borderRadius="50%"
                    color={isSelectedHighlight ? 'gray.50' : 'gray.900'}
                    _hover={{
                      backgroundColor: 'purple.900',
                      color: 'gray.50',
                    }}
                  >
                    <Text size="sm">{dayjs(item.date).format('D')}</Text>
                  </Center>
                </Center>
              );
            })}
        </HStack>
      </VStack>
    </VStack>
  );
}
