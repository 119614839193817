import React from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { HStack, Link, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

interface Breadcrumb {
  url: string;
  title: string;
}

interface BreadcrumbsProps {
  items: Breadcrumb[];
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { items } = props;

  return (
    <HStack gap={0}>
      {items.map((item, index) => (
        <HStack gap={0} key={index}>
          {index !== 0 && <ChevronRightIcon m="4px" />}

          {index === items.length - 1 && <Text>{item.title}</Text>}

          {index < items.length - 1 && (
            <Link as={ReactRouterLink} to={item.url} variant="text">
              {item.title}
            </Link>
          )}
        </HStack>
      ))}
    </HStack>
  );
}
