import React, { useMemo } from 'react';
import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { IClient, IProvider } from '@app/store/schema';
import { ClientsActiveList } from '@app/features/clients/ClientsActiveList';
import { ClientsDischargedList } from '@app/features/clients/ClientsDischargedList';
import { ClientsActivePlaceholder } from '@app/features/clients/ClientsActivePlaceholder';
import { ClientsDischargedPlaceholder } from '@app/features/clients/ClientsDischargedPlaceholder';

interface ClientsBlockProps {
  title: string;
  clients: IClient[];
  provider?: IProvider;
}

export function ClientsBlockContent(props: ClientsBlockProps) {
  const { title, clients, provider } = props;

  const activeList = useMemo(() => {
    return clients.filter((client) => client.status === 'ACTIVE');
  }, [clients]);

  const deactivatedList = useMemo(() => {
    return clients.filter((client) => client.status === 'DEACTIVATED');
  }, [clients]);

  return (
    <VStack gap="30px" alignItems="start" width="100%">
      <Heading size="md">{title}</Heading>

      <Tabs variant="pill">
        <TabList>
          <Tab aria-label={'Active (' + activeList.length + ')'}>
            Active ({activeList.length})
          </Tab>
          <Tab aria-label={'Discharged (' + deactivatedList.length + ')'}>
            Discharged ({deactivatedList.length})
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {activeList.length > 0 && (
              <ClientsActiveList clients={activeList} provider={provider} />
            )}
            {!activeList.length && (
              <ClientsActivePlaceholder provider={provider} />
            )}
          </TabPanel>
          <TabPanel>
            {deactivatedList.length > 0 && (
              <ClientsDischargedList
                clients={deactivatedList}
                provider={provider}
              />
            )}
            {!deactivatedList.length && <ClientsDischargedPlaceholder />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}

export const ClientsBlock = React.memo(ClientsBlockContent);
