import React from 'react';
import {
  Box,
  defineStyleConfig,
  forwardRef,
  HStack,
  Icon,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';

export const widgetHeaderTheme = defineStyleConfig({
  baseStyle: {
    '.widget-title': {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '600',
    },
    '.widget-subtitle': {
      color: 'gray.500',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  variants: {
    xl: {
      '.widget-title': {
        fontSize: '24px',
        lineHeight: '32px',
      },
      '.widget-subtitle': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    lg: {
      '.widget-subtitle': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
});

export const WidgetHeader = forwardRef((props, ref) => {
  const { variant, title, subtitle, icon, iconColor, ...rest } = props;

  const styles = useStyleConfig('WidgetHeader', { variant });

  return (
    <HStack alignItems="center" gap={0} ref={ref} __css={styles} {...rest}>
      <HStack alignItems="center" gap="20px">
        {icon && <Icon as={icon} color={iconColor} boxSize="24px" />}

        <Box>
          <Text className="widget-title">{title}</Text>
          {subtitle && <Text className="widget-subtitle">{subtitle}</Text>}
        </Box>
      </HStack>

      {props.children}
    </HStack>
  );
});

WidgetHeader.displayName = 'WidgetHeader';
