import { Box } from '@chakra-ui/react';
import { FiPlusCircle } from 'react-icons/fi';

export default function ExpandableSpacer({
  onClick,
}: {
  onClick?: () => void;
}) {
  return (
    <Box
      as="button"
      display="flex"
      w="5px"
      height="100%"
      borderRadius="lg"
      backgroundColor="#b6b6b6"
      color="white"
      cursor="pointer"
      _hover={{ width: '50px' }}
      _active={{ filter: 'brightness(0.75) contrast(200%)' }}
      transition="all 0.2s ease-out"
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
    >
      <FiPlusCircle width="100%" />
    </Box>
  );
}
