import { gql } from 'graphql-request';

export const ProviderQuery = gql`
  fragment BadgeFragment on CardBadge {
    text
    status
  }

  fragment AssetFragment on Asset {
    type
    icon
    url
  }

  fragment AssessmentResultFragment on AssessmentResult {
    id
    title
    type
    date
    dateShort
    badge {
      ...BadgeFragment
    }
    dataPoint {
      label
      timestamp
      value
    }
    scoreMovement {
      text
      asset {
        ...AssetFragment
      }
    }
  }
  query {
    provider {
      id
      firstName
      lastName
      credentials
      status
      user {
        id
        email
        phone
        role
        activeAt
      }
      supervisors {
        id
      }
      subordinates {
        id
      }
      clients {
        id
        firstName
        lastName
        status
        isHighRisk
        latestAssessmentResult {
          ...AssessmentResultFragment
        }
      }
    }
  }
`;
