import { Box } from '@chakra-ui/react';
import PhoneTopBar from './PhoneTopBar';

interface PhoneFrameProps {
  appPadding?: boolean;
}

export default function PhoneFrame({
  children,
  appPadding,
}: PhoneFrameProps & React.PropsWithChildren) {
  return (
    <Box
      height="100%"
      overflow="hidden"
      backgroundColor="rgb(3, 1, 19)"
      color="white"
      display="flex"
      flexDirection="column"
    >
      <PhoneTopBar />
      <Box
        margin={appPadding ? '0px 25px 35px 25px' : undefined}
        flex={1}
        position="relative"
      >
        {children}
      </Box>
    </Box>
  );
}
