import { gql } from 'graphql-request';

export const TrackerQueryQuery = gql`
  fragment TrackerItemFragment on TrackerItem {
    id
    label
    min
    max
    defaultValue
    values {
      id
      text
      value
    }
    triggers {
      id
      text
    }
  }

  fragment AssetFragment on Asset {
    __typename
    type
    icon
    url
  }

  query (
    $clientId: String!
    $flowId: String!
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    trackerResults(
      query: {
        clientId: $clientId
        flowId: $flowId
        fromDate: $fromDate
        toDate: $toDate
      }
    ) {
      id
      createdAt
      flowId
      trackerResultInputs {
        id
        trackerItem {
          ...TrackerItemFragment
        }
        value
        scoreMovement {
          text
          asset {
            ...AssetFragment
          }
        }
        trackerTriggers {
          id
          text
        }
      }
    }
  }
`;
