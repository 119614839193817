import React from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import { Heading, Link, Text, VStack } from '@chakra-ui/react';
import { AuthForm } from '@app/auth/AuthForm';

export function ErrorBoundary() {
  return (
    <AuthForm>
      <VStack spacing="30px" alignItems="start">
        <Heading size="lg" as="h1">
          That page doesn’t exist
        </Heading>

        <Text>
          The page you were looking for appears to have been moved, deleted,
          does not exist or you don’t have access to it. You could go back to
          where you were or head straight to our home page
        </Text>

        <Text variant="medium">
          <Link as={ReactRouterLink} to="/">
            Home Page
          </Link>
        </Text>
      </VStack>
    </AuthForm>
  );
}
