import { gql } from 'graphql-request';

export const AllFlowsQuery = gql`
  query {
    allFlows {
      id
      name
      description
      type
      previewUrl
      assessment {
        name
        nameFull
        nameShort
      }
    }
  }
`;
