import React, { useContext, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { IClient } from '@app/store/schema';
import { getErrors, requiredValidator } from '@app/utils/validators';
import { FormSelect } from '@app/layout/form/FormSelect';
import { usersToList } from '@app/utils/usersList';
import { StoreContext } from '@app/store/StoreContext';

interface ReassignClientModalProps {
  client: IClient;
  isOpen: boolean;
  onClose(): void;
}

export function ReassignClientModal(props: ReassignClientModalProps) {
  const { client, isOpen, onClose } = props;
  const { getProviders, updateClient } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  if (!client) {
    return <></>;
  }

  const providers = getProviders();

  const formik = useFormik({
    enableReinitialize: true,
    validate: (values: any) =>
      getErrors(values, {
        providerId: requiredValidator(values.providerId),
      }),
    initialValues: {
      providerId: client?.providers[0].id || '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await updateClient(client.id, {
        providerId: value.providerId,
        disconnectProviderId: client?.providers[0].id,
      });

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        clearForm();

        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  const clearForm = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={clearForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reassign client</ModalHeader>
        <ModalCloseButton />

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <FormSelect
                label="Assign to"
                name="providerId"
                placeholder="Select clinician"
                items={usersToList(providers)}
              />
            </ModalBody>

            <ModalFooter>
              <Button size="sm" mr="12px" onClick={clearForm}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                isLoading={isLoading}
              >
                Save changes
              </Button>
            </ModalFooter>
          </form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
}
