import React from 'react';
import { useField } from 'formik';
import {
  Button,
  FormControl,
  FormLabel,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FormError } from '@app/layout/form/FormError';

export interface FormSelectListItem {
  title: string;
  description?: string;
  value: string | number;
}

export const FormSelect = forwardRef((props, ref) => {
  const { label, name, placeholder, items, variant, ...rest } = props;

  const styles = useStyleConfig('FormSelect', { variant });
  const [field, meta, helpers] = useField({
    name,
  });

  const selectedItem = (items || []).find(
    (item: FormSelectListItem) => item.value === field.value,
  );

  const isInvalid = meta.touched && !!meta.error;
  const setValue = (value: string | number) => helpers.setValue(value);

  const onLabelClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <FormControl ref={ref} __css={styles} {...rest} isInvalid={isInvalid}>
      {label && <FormLabel onClick={onLabelClick}>{label}</FormLabel>}
      <Menu isLazy>
        <MenuButton
          as={Button}
          textAlign="left"
          width="100%"
          type="button"
          variant="formSelect"
          size="sm"
          rightIcon={
            <ChevronDownIcon color="gray.800" width="20px" height="20px" />
          }
          paddingRight="10px"
          className={isInvalid ? 'invalid' : ''}
        >
          {field.value && <Text isTruncated>{selectedItem.title}</Text>}
          {!field.value && <Text color="gray.500">{placeholder}</Text>}
        </MenuButton>
        <MenuList rootProps={{ width: '100%', minWidth: '0 !important' }}>
          {items &&
            items.map((item: FormSelectListItem, index: number) => (
              <MenuItem
                key={index}
                className={field.value === item.value ? 'active' : ''}
                onClick={() => setValue(item.value)}
              >
                <VStack alignItems="start" gap={0}>
                  <Text>{item.title}</Text>
                  {item.description && (
                    <Text variant="medium" size="xs" color="gray.500">
                      {item.description}
                    </Text>
                  )}
                </VStack>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>

      {isInvalid && <FormError error={meta.error!} />}
    </FormControl>
  );
});

FormSelect.displayName = 'FormSelect';
