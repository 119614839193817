import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { StoreContext } from '@app/store/StoreContext';
import { Table } from '@app/layout/table/Table';
import { TableContainer } from '@app/layout/table/TableContainer';
import { TableHeader } from '@app/layout/table/TableHeader';
import { TableHeaderCell } from '@app/layout/table/TableHeaderCell';
import { TableBody } from '@app/layout/table/TableBody';
import { SettingsProvidersItem } from '@app/features/settings/SettingsProvidersItem';
import { ProviderModal } from '@app/features/providers/modals/ProviderModal';
import { DeactivateProviderModal } from '@app/features/providers/modals/DeactivateProviderModal';
import { SettingsLogo } from '@app/features/settings/SettingsLogo';
import { FormInput } from '@app/layout/form/FormInput';
import {
  compose,
  getErrors,
  minLength,
  requiredValidator,
} from '@app/utils/validators';
import { useSort } from '@app/layout/sort/useSort';
import { providerFullName } from '@app/utils/userFullName';
import { Comparator } from '@app/utils/comparator';
import { IProvider } from '@app/store/schema';

type IProviderOptional = IProvider & { clients: number };

export function SettingsProviders() {
  const {
    getUser,
    getOrganization,
    getProviders,
    getClientsByProviderId,
    isAdmin,
    updateOrganization,
  } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(undefined);
  const { sortValue, applySort } = useSort({
    column: 'name',
    order: 'asc',
  });

  const providerModal = useDisclosure();
  const deactivateProviderModal = useDisclosure();

  const editProvider = useCallback((item?: any) => {
    setCurrentProvider(item);
    providerModal.onOpen();
  }, []);

  const deactivateProvider = useCallback((item: any) => {
    setCurrentProvider(item);
    deactivateProviderModal.onOpen();
  }, []);

  const currentUser = getUser();
  const organization = getOrganization();
  const providers = getProviders();

  const extendedProviders = useMemo(() => {
    return providers.map((provider) => {
      const clients = getClientsByProviderId(provider.id).length;

      return {
        ...provider,
        clients,
      };
    });
  }, [providers]);

  const sortedTable = useMemo(() => {
    return extendedProviders.sort(
      (a: IProviderOptional, b: IProviderOptional) => {
        const isAsc = sortValue.order === 'asc';

        if (sortValue.column === 'name') {
          return Comparator.orderWithDirection(
            isAsc,
            providerFullName(a).toLowerCase(),
            providerFullName(b).toLowerCase(),
          );
        }

        if (sortValue.column === 'role') {
          return Comparator.orderWithDirection(isAsc, a.user.role, b.user.role);
        }

        if (sortValue.column === 'status') {
          return Comparator.orderWithDirection(isAsc, a.status, b.status);
        }

        if (sortValue.column === 'clients') {
          return Comparator.orderWithDirection(isAsc, a.clients, b.clients);
        }

        return 0;
      },
    );
  }, [extendedProviders, sortValue]);

  const toast = useToast();

  const formik = useFormik({
    validate: (values: any) =>
      getErrors(values, {
        name: compose([
          requiredValidator(values.name),
          minLength(values.name, 2),
        ]),
      }),
    initialValues: {
      name: organization?.name || '',
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      const { errors } = await updateOrganization({
        name: value.name,
      });

      setIsLoading(false);

      if (errors && errors.length) {
        errors.map((error) =>
          toast({
            title: error.message,
            variant: 'error',
          }),
        );
      } else {
        toast({
          title: 'Changes saved',
          variant: 'success',
        });
      }
    },
  });

  return (
    <>
      <VStack gap="30px" alignItems="start">
        <HStack
          pl="30px"
          pr="30px"
          width="100%"
          alignItems="start"
          justifyContent="space-between"
        >
          <Heading size="md">Team members</Heading>

          <Button
            size="sm"
            colorScheme="purple"
            variant="outline"
            leftIcon={<AddIcon />}
            onClick={() => editProvider()}
          >
            Invite team member
          </Button>
        </HStack>

        <Table>
          <TableContainer minWidth="870px">
            <TableHeader>
              <TableHeaderCell
                width="100%"
                variant="lg"
                label="Name"
                sortColumn="name"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell
                width="146px"
                variant="lg"
                flexShrink={0}
                label="Role"
                sortColumn="role"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell
                width="146px"
                variant="lg"
                flexShrink={0}
                label="Status"
                sortColumn="status"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell
                width="90px"
                variant="lg"
                flexShrink={0}
                label="Clients"
                sortColumn="clients"
                sortValue={sortValue}
                onSort={applySort}
              />
              <TableHeaderCell width="62px" variant="lg" flexShrink={0} />
            </TableHeader>

            <TableBody>
              {sortedTable &&
                sortedTable.map((provider, index) => (
                  <SettingsProvidersItem
                    key={index}
                    provider={provider}
                    clients={provider.clients}
                    onEdit={editProvider}
                    onDeactivate={deactivateProvider}
                  />
                ))}
            </TableBody>
          </TableContainer>
        </Table>

        {isAdmin(currentUser!) && (
          <>
            <VStack pl="30px" pr="30px" gap="20px" alignItems="start">
              <Heading size="md">Practice logo</Heading>

              <SettingsLogo />
            </VStack>

            <Divider />

            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <VStack gap="30px" alignItems="start">
                  <VStack pl="30px" pr="30px" gap="20px" alignItems="start">
                    <Heading size="md">Practice name</Heading>

                    <FormInput name="name" placeholder="Name" width="455px" />
                  </VStack>

                  <Box pl="30px" pr="30px">
                    <Button
                      type="submit"
                      variant="primary"
                      size="md"
                      isLoading={isLoading}
                    >
                      Save changes
                    </Button>
                  </Box>
                </VStack>
              </form>
            </FormikProvider>
          </>
        )}
      </VStack>

      <ProviderModal
        provider={currentProvider}
        isOpen={providerModal.isOpen}
        onClose={providerModal.onClose}
      />

      <DeactivateProviderModal
        provider={currentProvider}
        isOpen={deactivateProviderModal.isOpen}
        onClose={deactivateProviderModal.onClose}
      />
    </>
  );
}
