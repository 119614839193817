import {
  Box,
  Button,
  Checkbox,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Counter } from '@app/layout/filter/Counter';
import { Comparator } from '@app/utils/comparator';
import { FilterItem } from '@app/layout/filter/useFilter';

interface FilterProps {
  title: string;
  items: FilterItem[];
  value: string[];
  onChange: (id: string) => void;
  onReset: () => void;
}

export function Filter(props: FilterProps) {
  const { title, items, value, onChange, onReset } = props;

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        size="sm"
        variant="select"
        flexShrink={0}
        rightIcon={
          <ChevronDownIcon color="gray.600" width="20px" height="20px" />
        }
        color="gray.600"
        display="flex"
      >
        <Text alignItems="center" display="flex">
          {value.length > 0 && <Counter>{value.length}</Counter>}
          {title}
        </Text>
      </MenuButton>
      <MenuList>
        <Box padding="6px" width="226px">
          <VStack alignItems="start" gap="20px">
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text variant="semibold">{title}</Text>
              {value.length > 0 && (
                <Link variant="textUnderline" onClick={onReset}>
                  <Text size="sm" variant="medium">
                    Clear
                  </Text>
                </Link>
              )}
            </HStack>

            <VStack alignItems="start" gap="16px">
              {items &&
                items
                  .sort((a, b) => Comparator.order(a.name, b.name))
                  .map((item) => (
                    <Checkbox
                      colorScheme="purple"
                      key={item.id}
                      isChecked={value.indexOf(item.id) !== -1}
                      onChange={() => onChange(item.id)}
                    >
                      {item.name}
                    </Checkbox>
                  ))}
            </VStack>
          </VStack>
        </Box>
      </MenuList>
    </Menu>
  );
}
