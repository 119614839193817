import { gql } from '../../../../__generated__/gql';

export const CREATE_FLOW = gql(/* GraphQL */ `
  mutation CreateFlow($data: CreateFlowInput!) {
    createFlow(data: $data) {
      __typename
      id
      name
      description
      type
      asset {
        ...AssetFragment
      }
      screens {
        ...ScreenFragment
      }
    }
  }
`);
