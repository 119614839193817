import React from 'react';
import dayjs from 'dayjs';
import { Datum, Point } from '@nivo/line';
import { Card, CardBody, Divider, Text, VStack } from '@chakra-ui/react';
import { IAssessmentResult } from '@app/store/schema';
import { ScoreMovement } from '@app/layout/widget/ScoreMovement';
import { badgeColor } from '@app/utils/badgeColor';

interface AssessmentPointTooltipProps {
  points: readonly Point[];
}

export const AssessmentPointTooltip = (props: AssessmentPointTooltipProps) => {
  const { points } = props;
  const item = (points[0].data as Datum).item;

  const { titleFull, badge, dataPoint, scoreMovement } =
    item as IAssessmentResult;

  return (
    <Card width="260px">
      <CardBody padding="10px">
        <VStack gap={0} alignItems="start">
          <Text variant="bold" padding="6px 12px">
            {titleFull}
          </Text>

          <Text padding="6px 12px">
            Result:{' '}
            <Text variant="bold" color={badgeColor(badge) + '.600'} as="span">
              {badge.text}
            </Text>
          </Text>

          {scoreMovement.length > 0 &&
            scoreMovement.map((item, index) => (
              <ScoreMovement
                scoreMovement={item}
                key={index}
                padding="6px 12px"
              />
            ))}

          <Divider m="5px 0" />

          <Text variant="medium" color="gray.500" padding="6px 12px">
            {dayjs(dataPoint.timestamp).format('MMM D, YYYY, h:mm A')}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};
