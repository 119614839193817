import { gql } from 'graphql-request';

export const DeactivateProviderMutation = gql`
  mutation ($providerId: String!, $reassignToProviderId: String!) {
    deactivateProvider(
      data: {
        providerId: $providerId
        reassignToProviderId: $reassignToProviderId
      }
    ) {
      id
      firstName
      lastName
      credentials
      status
      invitedAt
      user {
        id
        email
        phone
        role
        activeAt
      }
      supervisors {
        id
      }
      subordinates {
        id
      }
    }
  }
`;
