import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface PreviewModalProps {
  title: string;
  previewUrl: string;
  isOpen: boolean;
  onClose(): void;
}

export default function PreviewModal({
  title,
  isOpen,
  onClose,
  previewUrl,
}: PreviewModalProps) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xs">
      <ModalOverlay />
      <ModalContent style={{ backgroundColor: 'black' }}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton stroke="white" />
        <ModalBody alignSelf="center">
          <video playsInline loop autoPlay style={{ height: 600 }} controls>
            <source src={previewUrl} type="video/mp4" />
            <p>
              Your browser doesn't support HTML video. Here is a
              <a href={previewUrl} download="myVideo.mp4">
                link to the video
              </a>
              instead.
            </p>
          </video>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
