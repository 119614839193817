import { gql } from 'graphql-request';

export const ExercisesQuery = gql`
  query ($clientId: String!, $fromDate: DateTime, $toDate: DateTime) {
    exercises(
      query: { clientId: $clientId, fromDate: $fromDate, toDate: $toDate }
    ) {
      id
      createdAt
      flow {
        id
        name
      }
      flowResultInputs {
        id
        inputId
        value
      }
    }
  }
`;
