import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  FLowEditorQueryQuery,
  FlowType,
  Provider,
} from '../../../__generated__/graphql';
import {
  Button,
  HStack,
  Box,
  Spacer,
  Text,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { FiSave, FiX, FiEdit, FiEye } from 'react-icons/fi';

type Flow = NonNullable<FLowEditorQueryQuery['allFlows']>[0];

interface EditorHeaderProps {
  currentFlowId: string;
  flows: Flow[];
  provider: Partial<Provider>;
  onTemplateChange: (flowId: string) => void;
  onSavePress: () => void;
  onEditPress: () => void;
  onRemovePress: () => void;
  canRemove: boolean;
  canEdit: boolean;
  savingInProgress: boolean;
  editingInProgress: boolean;
  removingInProgress: boolean;
}

export default function EditorHeader({
  flows,
  provider,
  onTemplateChange,
  currentFlowId,
  onSavePress,
  onEditPress,
  onRemovePress,
  canRemove,
  canEdit,
  savingInProgress,
  editingInProgress,
  removingInProgress,
}: EditorHeaderProps) {
  if (!flows) return null;

  const [customFlows, standardFlows] = flows
    .filter((x): x is NonNullable<Flow> => !!x)
    .filter((x) => [FlowType.Journal, FlowType.Checklist].includes(x!.type))
    .reduce<[Flow[], Flow[]]>(
      (acc, x) => (acc[!!x.organizationId ? 0 : 1].push(x), acc),
      [[], []],
    );

  const renderEditButton = () => (
    <Button
      size="sm"
      leftIcon={<FiEdit />}
      onClick={onEditPress}
      isLoading={editingInProgress}
      isDisabled={!canEdit}
    >
      Save
    </Button>
  );

  const renderDeleteButton = () => (
    <Button
      size="sm"
      color="red"
      leftIcon={<FiX />}
      onClick={onRemovePress}
      isLoading={removingInProgress}
      isDisabled={!canRemove}
    >
      Delete
    </Button>
  );

  return (
    <HStack>
      <Box>
        <Text>Choose a template</Text>
      </Box>
      <Box>
        <Menu>
          <MenuButton size="sm" as={Button} rightIcon={<ChevronDownIcon />}>
            {flows.find((x) => x?.id === currentFlowId)?.name}
          </MenuButton>
          <MenuList>
            {!!customFlows.length && (
              <>
                <MenuGroup title="Custom activities">
                  {customFlows?.map((x) => (
                    <MenuItem
                      key={`${x?.id}flowpicker`}
                      onClick={() => onTemplateChange(x!.id)}
                      fontWeight={x?.id === currentFlowId ? '700' : ''}
                      icon={
                        x?.organizationId !== provider.organizationId ? (
                          <FiEye />
                        ) : undefined
                      }
                    >
                      <HStack
                        w="100%"
                        gap="32px"
                        justifyContent="space-between"
                      >
                        <Text maxWidth="350px" size="sm">
                          {x?.name}
                        </Text>
                        <Text color="gray.500" size="xs">
                          {x?.type}
                        </Text>
                      </HStack>
                    </MenuItem>
                  ))}
                </MenuGroup>
                <MenuDivider />
              </>
            )}
            <MenuGroup title="Standard activities">
              {standardFlows?.map((x) => (
                <MenuItem
                  key={`${x?.id}flowpicker`}
                  onClick={() => onTemplateChange(x!.id)}
                  fontWeight={x?.id === currentFlowId ? '700' : ''}
                >
                  <HStack w="100%" gap="32px" justifyContent="space-between">
                    <Text maxWidth="350px" size="sm">
                      {x?.name}
                    </Text>
                    <Text color="gray.500" size="xs">
                      {x?.type}
                    </Text>
                  </HStack>
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <Spacer />
      <Box>
        <Button
          size="sm"
          leftIcon={<FiSave />}
          onClick={onSavePress}
          isLoading={savingInProgress}
        >
          Save as a copy
        </Button>
      </Box>
      <Box>
        {canEdit ? (
          renderEditButton()
        ) : (
          <Tooltip label="Standard activities cannot be customized, you can save a copy and customize it instead. It will only be visible inside your practice.">
            {renderEditButton()}
          </Tooltip>
        )}
      </Box>
      <Box>
        {canRemove ? (
          renderDeleteButton()
        ) : (
          <Tooltip label="Standard activities cannot be deleted.">
            {renderDeleteButton()}
          </Tooltip>
        )}
      </Box>
    </HStack>
  );
}
