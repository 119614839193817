import { gql } from '../../../../__generated__/gql';

export const FLOW_EDITOR = gql(/* GraphQL */ `
  query FLowEditorQuery($query: LoadFlowInput!) {
    provider {
      id
      organizationId
      firstName
      lastName
    }
    allFlows {
      id
      name
      description
      type
      organizationId
    }
    loadFlow(query: $query) {
      id
      name
      description
      type
      organizationId
      asset {
        ...AssetFragment
      }
      screens {
        ...ScreenFragment
      }
    }
  }
`);
