import { useState } from 'react';

export type SortOrder = 'asc' | 'desc';

export interface SortValue {
  column?: string;
  order?: SortOrder;
}

export const useSort = (defaultSort: SortValue) => {
  const [sortValue, setSortValue] = useState<SortValue>(defaultSort);

  const applySort = (column: string, order?: SortOrder) => {
    if (!order) {
      order = sortValue.order === 'asc' ? 'desc' : 'asc';

      if (sortValue.column !== column) {
        order = 'asc';
      }
    }

    setSortValue({
      column,
      order: order as SortOrder,
    });
  };

  return {
    sortValue,
    applySort,
  };
};
