import { ICardBadge, TBadgeType } from '@app/store/schema';

const badgeStatuses: Record<TBadgeType, string> = {
  ERROR: 'red',
  ALERT: 'red',
  WARNING: 'orange',
  INFO: 'green',
};

export const badgeColor = (badge: ICardBadge) => badgeStatuses[badge.status];
