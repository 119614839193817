import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ScreenFragmentFragment } from '../../../__generated__/graphql';
import { FiX } from 'react-icons/fi';
import { RxDragHandleDots2 } from 'react-icons/rx';

interface ScreenThumbnailProps {
  sortable?: boolean;
  removable?: boolean;
  screen: ScreenFragmentFragment;
  onClick: (screenId: string) => void;
  onRemove?: (screenId: string) => void;
  selected?: boolean;
}

const IPHONE_15_PRO_ASPECT_RATIO = 9 / 16;

export default function ScreenThumbnail({
  screen,
  sortable,
  removable,
  selected,
  onClick,
  onRemove,
}: ScreenThumbnailProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: screen.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const title = screen.title
    ?.replace('#client.firstName#', 'Victoria')
    .replace('#provider.firstName#', 'Patricia');

  return (
    <Box height="100%" position="relative" style={style} ref={setNodeRef}>
      {removable && (
        <Box
          position="absolute"
          right="-5px"
          top="-10px"
          w="20px"
          h="20px"
          zIndex={1000}
          onClick={onRemove ? () => onRemove(screen.id) : undefined}
        >
          <IconButton size="xs" icon={<FiX />} aria-label="remove screen" />
        </Box>
      )}
      {sortable && (
        <Box
          {...attributes}
          {...listeners}
          position="absolute"
          left="-5px"
          top="-10px"
          w="20px"
          h="20px"
          borderRadius={20}
          zIndex={1000}
        >
          <Tooltip label="Drag to reorder">
            <IconButton
              cursor="ew-resize"
              size="xs"
              icon={<RxDragHandleDots2 />}
              aria-label="re-order"
            />
          </Tooltip>
        </Box>
      )}
      <Box
        height="100%"
        aspectRatio={IPHONE_15_PRO_ASPECT_RATIO}
        borderRadius="lg"
        backgroundColor="rgb(3, 1, 19)"
        color="white"
        fontSize="8px"
        padding="8px"
        cursor="pointer"
        _hover={{ filter: 'brightness(2) invert(15%)' }}
        border={selected ? '3px dashed #f9e7d3' : undefined}
        transition="filter 0.3s ease-out"
        onClick={() => onClick(screen.id)}
      >
        <div>{title}</div>
      </Box>
    </Box>
  );
}
