import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tag,
  Textarea,
} from '@chakra-ui/react';
import { FlowEditorScreenPropertyActions } from './FlowEditorApp';
import {
  FlowType,
  ScreenFragmentFragment,
  ScreenType,
} from '../../../__generated__/graphql';
import { useFragment } from '../../../__generated__';
import { InputFragment, InputInterfaceFragment } from './fragments/common';

interface ScreenPropertiesSimpleProps {
  screen: ScreenFragmentFragment | null;
  onNextPress: () => void;
  type: FlowType;
}

export default function ScreenPropertiesSimple({
  onChangeScreenProperty,
  onNextPress,
  onChangeScreenInputProperty,
  screen,
  type,
}: FlowEditorScreenPropertyActions & ScreenPropertiesSimpleProps) {
  if (!screen) return null;
  if (
    [ScreenType.SurveyScreen, ScreenType.CongratulationsScreen].includes(
      screen.screenType,
    )
  )
    return <div>No editable properties</div>;

  const cta = useFragment(InputFragment, screen.cta);

  const inputs = useFragment(InputInterfaceFragment, screen.inputs);

  return (
    <Box p={2} maxWidth="1000px">
      <Tag marginBottom={5}>{type}</Tag>
      <FormControl isInvalid={false} marginBottom={5}>
        <FormLabel>Prompt</FormLabel>
        <Input
          onChange={(ev) => {
            onChangeScreenProperty(screen.id, 'title', ev.target.value);
          }}
          placeholder="name"
          value={screen.title ?? ''}
        />
        <FormErrorMessage></FormErrorMessage>
      </FormControl>

      {screen.screenType === ScreenType.PreviewScreen && (
        <FormControl isInvalid={false} marginBottom={5}>
          <FormLabel>Subtitle</FormLabel>
          <Textarea
            onChange={(ev) => {
              onChangeScreenProperty(screen.id, 'subtitle', ev.target.value);
            }}
            placeholder="You can leave this empty or add some additional text."
            value={screen.subtitle ?? ''}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
      )}

      {inputs?.map((input) => {
        return (
          <Box key={input.id}>
            {'label' in input && (
              <FormControl isInvalid={false} marginBottom={5}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  onChange={(ev) => {
                    onChangeScreenInputProperty(
                      screen.id,
                      input.id,
                      'label',
                      ev.target.value,
                    );
                  }}
                  placeholder="You can leave this empty or add some additional text. It will appear under the prompt."
                  value={input.label ?? ''}
                />
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            )}
          </Box>
        );
      })}
      <Box justifyContent="flex-end" display="flex">
        <Button onClick={onNextPress}>Next</Button>
      </Box>
    </Box>
  );
}
