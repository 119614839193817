import dayjs from 'dayjs';
import { CalendarPeriod } from '@app/layout/calendar/Calendar';

export const getPreviousMonth = (date: string | dayjs.Dayjs) => {
  return dayjs(date).subtract(1, 'month').startOf('month');
};

export const getCurrentMonth = () => {
  return dayjs().startOf('month');
};

export const getNextMonth = (date: string | dayjs.Dayjs) => {
  return dayjs(date).add(1, 'month').startOf('month');
};

export const getMonth = (date: string | dayjs.Dayjs) => {
  return dayjs(date).startOf('month');
};

export const isFirstDay = (date: string | dayjs.Dayjs) => {
  return dayjs(date).day() === 0 || dayjs(date).date() === 1;
};

export const isLastDay = (date: string | dayjs.Dayjs) => {
  return (
    dayjs(date).day() === 6 ||
    dayjs(date).date() === dayjs(date).endOf('month').date()
  );
};

export const isSameDay = (
  dateA: string | dayjs.Dayjs,
  dateB: string | dayjs.Dayjs,
) => {
  return dayjs(dateA).startOf('day').isSame(dayjs(dateB).startOf('day'));
};

export const periodTitle = (
  period: CalendarPeriod,
  template: string = 'MMMM D, YYYY',
) => {
  const from = dayjs(period.from).startOf('day');
  const to = dayjs(period.to).startOf('day');

  const formattedFrom = formatDate(from, template, false);
  const formattedTo = formatDate(to, template, false);

  if (isSameDay(from, to)) {
    return formattedFrom;
  }

  return `${formattedFrom} - ${formattedTo}`;
};

export const getWeekdays = () => {
  const weekdays = [];

  const labels = dayjs().localeData().weekdaysMin();
  let index = dayjs.localeData().firstDayOfWeek();

  for (let i = 0; i < 7; i++) {
    weekdays.push(labels[index].toUpperCase());
    index = index === 6 ? 0 : ++index;
  }

  return weekdays;
};

export function isPeriod(
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  unit: dayjs.UnitType,
) {
  return (
    isSameDay(from, from.clone().startOf(unit)) &&
    isSameDay(to, to.clone().endOf(unit))
  );
}

export const formatDate = (
  date: string | dayjs.Dayjs,
  template: string = 'MMM D, YYYY',
  useRelative: boolean = true,
) => {
  if (useRelative) {
    const isToday = dayjs(date).isSame(dayjs(), 'day');
    const isYesterday = dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day');
    const isSameYear = dayjs(date).isSame(dayjs(), 'year');

    if (isToday) {
      return `Today`;
    }

    if (isYesterday) {
      return `Yesterday`;
    }

    template = isSameYear ? 'MMM D' : 'MMM D, YYYY';
  }

  return dayjs(date).format(template);
};

export const formatTime = (
  date: string | dayjs.Dayjs,
  template: string = 'h:mm A',
) => {
  return dayjs(date).format(template);
};

export const formatDatetime = (
  date: string | dayjs.Dayjs,
  templateDate: string = 'MMM D, YYYY',
  templateTime: string = 'h:mm A',
  useRelative: boolean = true,
) => {
  const formattedDate = formatDate(date, templateDate, useRelative);
  const formattedTime = formatTime(date, templateTime);

  return `${formattedDate} at ${formattedTime}`;
};
