import React, { useCallback, useContext, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IoClose } from 'react-icons/io5';
import { FiUploadCloud } from 'react-icons/fi';
import {
  Center,
  Icon,
  Image,
  Input,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DropFiles } from '@app/layout/DropFiles';
import { StoreContext } from '@app/store/StoreContext';
import { CropImageModal } from '@app/layout/CropImageModal';

export function SettingsLogo() {
  const { getOrganization, updateOrganizationLogo, removeOrganizationLogo } =
    useContext(StoreContext);
  const [image, setImage] = useState('');
  const [isOver, setIsOver] = useState(false);

  const organization = getOrganization();
  const cropImageModal = useDisclosure();
  const fileInput = useRef<HTMLInputElement>(null);

  const validateFiles = useCallback((files: File[]) => {
    if (files.length > 1) {
      return false;
    }

    for (const file of files) {
      if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) === -1) {
        return false;
      }
    }

    return (
      files.filter((file: File) => file.size / 1024 / 1024 < 2).length ===
      files.length
    );
  }, []);

  const readFile = useCallback((file: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      setImage(reader.result?.toString() || '');
      cropImageModal.onOpen();
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  }, []);

  const onFileSelect = useCallback((files: File[]) => {
    if (validateFiles(files)) {
      readFile(files[0]);
    }
  }, []);

  const updateLogo = async (data: string) => {
    await updateOrganizationLogo(data);

    return {
      errors: undefined,
    };
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DropFiles
        validate={validateFiles}
        onDrop={onFileSelect}
        onOver={setIsOver}
      >
        <Center
          backgroundColor={organization?.logo || isOver ? 'gray.100' : 'white'}
          border="1px solid"
          borderColor="gray.200"
          cursor={organization?.logo ? 'default' : 'pointer'}
          width="448px"
          height="128px"
          borderRadius="8px"
          position="relative"
          onClick={() =>
            fileInput.current ? fileInput.current?.click() : null
          }
        >
          {organization?.logo && (
            <>
              <Image
                src={organization?.logo}
                maxHeight="60%"
                pointerEvents="none"
              />

              <Icon
                as={IoClose}
                boxSize="24px"
                position="absolute"
                top="10px"
                right="10px"
                cursor="pointer"
                onClick={removeOrganizationLogo}
              />
            </>
          )}

          {!organization?.logo && (
            <VStack gap="10px">
              <Center
                width="40px"
                height="40px"
                borderRadius="8px"
                backgroundColor="gray.200"
              >
                <Icon as={FiUploadCloud} boxSize="24px" />
              </Center>

              <Text size="sm" variant="medium" color="gray.600" width="230px">
                <Text
                  as="span"
                  color="gray.800"
                  textDecoration="underline"
                  cursor="pointer"
                >
                  Click to upload
                </Text>{' '}
                or drag and drop PNG, JPG or GIF up to 2MB
              </Text>

              <Input
                ref={fileInput}
                type="file"
                display="none"
                accept="image/jpeg,image/png,image/gif"
                onChange={(event) =>
                  onFileSelect(Array.from(event?.target?.files || []))
                }
              />
            </VStack>
          )}
        </Center>
      </DropFiles>

      <CropImageModal
        image={image}
        isOpen={cropImageModal.isOpen}
        onClose={cropImageModal.onClose}
        onSave={updateLogo}
      />
    </DndProvider>
  );
}
