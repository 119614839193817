import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export function Counter(props: TextProps) {
  return (
    <Text
      size="xs"
      as="span"
      alignItems="center"
      backgroundColor="gray.900"
      borderRadius="50%"
      color="white"
      display="flex"
      justifyContent="center"
      marginRight="8px"
      minWidth="16px"
      height="16px"
    >
      {props.children}
    </Text>
  );
}
